import React from "react";

// experts
import chestina from "../../../images/project-screens/podcast/experts/chestina.png";
import golovin from "../../../images/project-screens/podcast/experts/golovin.png";
import koloskov from "../../../images/project-screens/podcast/experts/koloskov.png";
import kovaleva from "../../../images/project-screens/podcast/experts/kovaleva.png";
import likhacheva from "../../../images/project-screens/podcast/experts/likhacheva.png";
import mikhaylov from "../../../images/project-screens/podcast/experts/mikhaylov.png";
import stepanov from "../../../images/project-screens/podcast/experts/stepanov.png";
import sudakov from "../../../images/project-screens/podcast/experts/sudakov.png";
import volkova from "../../../images/project-screens/podcast/experts/volkova.png";
import zeynalova from "../../../images/project-screens/podcast/experts/zeynalova.png";

// partners
import partner_1 from "../../../images/project-screens/podcast/partners/yandex.png";
import partner_2 from "../../../images/project-screens/podcast/partners/business-fm.png";
import partner_3 from "../../../images/project-screens/podcast/partners/litres.png";

// images
import titleScreenImg from "../../../images/project-screens/podcast/bg/title-screen.png";
import bulletVerticalScreenHeaderImg from "../../../images/project-screens/podcast/bg/bullet-vertical-screen-header.svg";
import bulletVerticalScreenImg from "../../../images/project-screens/podcast/bg/bullet-vertical-screen.png";

const podcastData = {
  titleScreen: {
    header: "Голос поколения. Версия Z",
    title: "Как сделать подкаст?",
    content: "Хочешь, чтобы тебя услышали? Запиши подкаст.",
    bg: titleScreenImg,
  },
  bulletVerticalScreen: {
    title: (
      <span>
        Мы тебе поможем <br />
        ответить на вопросы!
      </span>
    ),
    header_img: bulletVerticalScreenHeaderImg,
    bg: bulletVerticalScreenImg,
    info_items: [
      <span>
        Как сформулировать идею и <br />
        сформировать концепцию?
      </span>,
      <span>
        Как определиться с форматом и <br />
        выбрать правильную подачу?
      </span>,
      <span>
        Как выстроить контент и <br />
        спроектировать твой подкаст?
      </span>,
      <span>
        Как сделать твой подкаст <br />
        интересным и востребованным?
      </span>,
      <span>
        Как продюсировать и продвигать <br />
        подкаст в сети?
      </span>,
    ],
  },
  bulletScreen: {
    title: "Что будем изучать?",
    info_items: [
      <span>
        Определение целевой аудитории, конечной цели, формата, траектории
        развития проекта
      </span>,
      <span>Особенности аудиовосприятия</span>,
      <span>
        Определение форматов: от коротких новостей до аудиоспектаклей
      </span>,
      <span>Отбор контента</span>,
      <span>Подготовка текста и выбор подачи материалов</span>,
      <span>Работа с голосом и интонацией</span>,
      <span>Работа с музыкой</span>,
      <span>Искусство интервью</span>,
    ],
  },
  expertsScreen: {
    title: "Эксперты курса",
    experts: [
      {
        name: "Федор Степанов",
        job: (
          <span>
            режиссер, исполнитель,
            <br />
            сценарист
          </span>
        ),
        img: stepanov,
      },
      {
        name: "Аня Ковалева",
        job: (
          <span>
            сооснователь
            <br />
            Brainstorm.fm
          </span>
        ),
        img: kovaleva,
      },
      {
        name: "Костя Колосков",
        job: (
          <span>
            сооснователь
            <br />
            Brainstorm.fm
          </span>
        ),
        img: koloskov,
      },
      {
        name: "Светлана Зейналова",
        job: (
          <span>
            телеведущая
            <br />
            Первого Канала
          </span>
        ),
        img: zeynalova,
      },
      {
        name: "Александр Головин",
        job: (
          <span>
            автор и ведущий подкаста
            <br />
            «КритМышь»
          </span>
        ),
        img: golovin,
      },
      {
        name: "Костя Михайлов ",
        job: "культовый радиоведущий",
        img: mikhaylov,
      },
      {
        name: "Саша Волкова",
        job: (
          <span>
            Автор подкаста
            <br />
            «Заварили бизнес»
          </span>
        ),
        img: volkova,
      },
      {
        name: "Елена Лихачева",
        job: (
          <span>
            ди-джей, радиоведущая,
            <br />
            независимый продюсер
          </span>
        ),
        img: likhacheva,
      },
      {
        name: "Лиза Сурганова",
        job: (
          <span>
            Главный редактор
            <br />
            «КиноПоиска»
          </span>
        ),
        img: chestina,
      },
      {
        name: "Игорь Ломакин",
        job: (
          <span>
            Ведущий Business FM,
            <br />
            подкастер
          </span>
        ),
        img: sudakov,
      },
    ],
  },
  partnersScreen: {
    title: "Партнеры",
    logos: [{ img: partner_1 }, { img: partner_2 }, { img: partner_3 }],
  },
};

export default podcastData;
