import React from 'react'
import MainPage from "../Page/Main";
import ForumDayEventsPage from "../Page/forumDayEvents";
import PartnersPage from "../Page/partners";
import ProjectPage from "../Page/project";
import ForumsPage from "../Page/forums";
import ProfilePage from "../Page/profile";
import {Switch, Route, Redirect} from 'react-router-dom'
import TrainingPage from '../Page/training'
import LessonPage from "Page/lesson";
import TestPage from "Page/test";
import TestOnePage from "Page/testOne";
import GoalSetting from "../Page/projectsMain/goalSetting/GoalSetting";
import DigitalMuseum from "../Page/projectsMain/digitalMuseum/DigitalMuseum";
import JumpStart from "../Page/projectsMain/jumpStart/JumpStart";
import StudentTicket from "../Page/projectsMain/studentTicket/StudentTicket";
import Podcast from "../Page/projectsMain/podcast/Podcast";
import SoftSkills from "../Page/projectsMain/softSkills/SoftSkills";
import CreativeThinking from "../Page/projectsMain/creativeThinking/CreativeThinking";
import EducationSystem from "../Page/projectsMain/educationSystem/EducationSystem";
import Login from "../Page/Login";
import Lessons from "../Page/lessons";
import Achievements from "../Page/achievements";
import Team from "../Page/team";
import Events from "../Page/events";
import Error from "../Page/Error";
// import ErrorPage from 'Page/ErrorPage';

export default class Main extends React.Component{
    render() {
        return (
            <Switch>
                {/* <Route path='*' component={ErrorPage}/> */}
                <Route exact path='/' component={ProfilePage}/>
                <Route path='/forum-day-events/:id' component={ForumDayEventsPage}/>
                <Route path='/partners/:id' component={PartnersPage}/>
                <Route path='/project/:id' component={ProjectPage}/>
                <Route path='/forums' component={ForumsPage}/>
                <Route path='/profile' component={ProfilePage}/>
                <Route path='/training' component={TrainingPage}/>
                <Route path='/lessons/:id' component={Lessons}/>
                <Route path='/lesson/:id' component={LessonPage}/>
                <Route path='/test' component={TestPage}/>
                <Route path='/testOne/:id' component={TestOnePage}/>
                <Route path='/goal-setting' component={GoalSetting}/>
                <Route path='/digital-museum' component={DigitalMuseum}/>
                <Route path='/jump-start' component={JumpStart}/>
                <Route path='/student-ticket' component={StudentTicket}/>
                <Route path='/podcast' component={Podcast}/>
                <Route path='/soft-skills' component={SoftSkills}/>
                <Route path='/creative-thinking' component={CreativeThinking}/>
                <Route path='/education-system' component={EducationSystem}/>
                <Route path='/my-achievements' component={Achievements}/>
                <Route path='/my-team' component={Team}/>
                <Route path='/events' component={Events}/>
                <Route path='/e/:code' component={Error}/>
                <Route component={()=><Redirect to={'/e/404'}/>}/>
            </Switch>
        )
    }
}
