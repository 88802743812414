import React from "react";

// experts
import pevtsovImg from "../../../images/project-screens/soft-skills/experts/pevtsov.png";
import artemievImg from "../../../images/project-screens/soft-skills/experts/artemiev.png";

// images
import titleScreenImg from "../../../images/project-screens/soft-skills/bg/title-screen.png";

const softSkillsData = {
  titleScreen: {
    header: "Мирись, мирись, мирись!",
    title: "Общение - это просто",
    content:
      "Каждый день ты общаешься с разными людьми. К каждому человеку необходимо выбирать правильный подход в общении, чтобы создать благоприятное впечатление о себе и не провоцировать конфликты.",
    bg: titleScreenImg,
  },
  bulletScreen: {
    title: "В ходе изучения данного курса ты:",
    info_items: [
      <span>
        Узнаешь, как произвести первое впечатление на парня, девушку или
        профессора при поступлении в институт.
      </span>,
      <span>
        Научишься слушать, слышать и задавать правильные вопросы, чтобы всегда
        быть приятным собеседником
      </span>,
      <span>
        Поймешь, что читать человека можно как книгу, просто наблюдая за его
        невербальными сигналами в виде жестов, мимики, позы
      </span>,
      <span>
        Познакомишься с техниками «подстройки» под собеседника, чтобы создавать
        доверительное общения с интересными для тебя людьми
      </span>,
      <span>
        Узнаешь о фразах-провокаторах в конфликте и научишься от них защищаться
        с помощью техник НЛП
      </span>,
      <span>
        Проанализируешь свое поведение в конфликте и поймешь, что можно
        по-другому вести себя во время ссоры, чтобы сохранить дружеские
        отношения
      </span>,
      <span>Узнаешь, как правильно отвечать, когда тебя критикуют</span>,
      <span>Познакомишься с техниками убеждения</span>,
      <span>
        Научишься эффективным техникам ответа на агрессивное поведение со
        стороны провокаторов и манипуляторо
      </span>,
      <span>
        Поймешь, как приручить «троллей» в социальных сетях, если правильно на
        них реагировать
      </span>,
    ],
  },
  expertsScreen: {
    title: "Эксперты курса",
    experts: [
      {
        name: "Дмитрий Певцов",
        job: "народный артист РФ",
        img: pevtsovImg,
      },
      {
        name: "Олег Артемьев",
        job: "космонавт, герой России",
        img: artemievImg,
      },
    ],
  },
};

export default softSkillsData;
