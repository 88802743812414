import React from 'react'
import Inputs from '../../pageComponents/Inputs'
import {Link} from "react-router-dom";
import Api from '../../Service/Api'
import {withRouter} from 'react-router-dom';

class auth extends React.Component {

    state = {
        email: '',
        emailError: false,
        emailErrorText: false,
        emailSend: false,
        password: '',
        passwordError: false,
        passwordErrorText: false,
    }

    Api = new Api()

    updateState = (type, value, index) => {
        // eslint-disable-next-line
        switch (type) {
            case 'email' : {
                this.setState({
                    email: value,
                    emailError: false,
                    emailErrorText: false
                });
                break;
            }
            case 'password' : {
                this.setState({
                    password: value,
                    passwordError: false,
                    passwordErrorText: false
                })
                break;
            }
        }
    }

    sendDate = () => {
        const {
            email,
            password,
        } = this.state
        const {showLoginClick} = this.props
        if (!email.length && !this.valid_email(email)) {
                this.setState({emailError: true})
            } else if (!password.length) {
                this.setState({passwordError: true})
            } else {
                this.Api.login(email, password)
                    .then((res) => {
                        if (res.status === 200) {
                            this.props.showLoginClick(false)
                            this.props.getUserData()
                        } else {
                            res.error && Object.keys(res.error).map(key => {
                                // eslint-disable-next-line
                                this.state[key + 'Error'] = true;
                                // eslint-disable-next-line
                                this.state[key + 'ErrorText'] = res.error[key];
                                this.setState({errors: new Date()})
                                return false
                            })
                        }
                    })
            }

    }

    valid_email = (el) => {
        let reg = /^[a-z-._0-9]+@[a-z-_0-9]+\.[a-z0-9]{2,10}$/i;
        return reg.test(el)
    }


    render() {
        const {
            email,
            emailError,
            emailErrorText,
            emailSend,
            passwordError,
            passwordErrorText,
        } = this.state
        const {showLoginClick} = this.props
        return (
            <div className="auth">
                <div className="login__head">Войдите в аккаунт, чтобы продолжить</div>
                <Inputs
                    name={'email'}
                    placeholder={'E-mail'}
                    onChange={this.updateState}
                    type={'email'}
                    className={emailError && ' error'}
                    errorText={emailErrorText}
                    required={'required'}
                />
                <Inputs
                    name={'password'}
                    placeholder={'Пароль'}
                    onChange={this.updateState}
                    className={passwordError && ' error'}
                    type={'password'}
                    errorText={passwordErrorText}
                />
                <div className="reLink">
                    <div className="reLink__el" onClick={()=>showLoginClick('registration')}>
                        Регистрация
                    </div>
                    <div className="reLink__el" onClick={()=>showLoginClick('forgot')}>
                        Забыли пароль
                    </div>
                </div>
                <div className="buttons">
                    <button
                        type='button'
                        className="button button__yellow"
                        onClick={() => {
                            this.sendDate()
                        }}
                    >
                        Войти
                    </button>
                </div>
            </div>
        )
    }

}

export default withRouter(auth);
