import React from "react";

const Dots = ({ color }) => (
  <>
    <div style={{ backgroundColor: color }} className="dot"></div>
    <div style={{ backgroundColor: color }} className="dot"></div>
    <div style={{ backgroundColor: color }} className="dot"></div>
    <div style={{ backgroundColor: color }} className="dot"></div>
  </>
);

export default Dots;
