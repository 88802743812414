import React from "react";

export default ({text, showPopapChange}) => {
    return (
        <div className="popap" onClick={showPopapChange}>
            <div className="popap__wrp">
                <div className="popap__close" onClick={showPopapChange}/>
                <div className="popap__text" dangerouslySetInnerHTML={{__html: text}}/>
            </div>
        </div>
    )
}