import React, { ReactNode } from "react";
import Dots from "../dotsMain/Dots";

const ProjectScreenHeader = ({ title, img, bg }) => (
  <div className="project-screen-header">
    <div className="project-screen-header__text-block">
      <Dots color="#5afff5" />
      <h2 className="project-screen-header__title">{title}</h2>
    </div>
    <div className="project-screen-header__img-block">
      <Dots color="#5afff5" />
      <img className="project-screen-header__img" src={img} alt="" />
      <img className="project-screen-header__bg" src={bg} alt="" />
    </div>
  </div>
);

export default ProjectScreenHeader;
