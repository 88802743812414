import React from "react";
import ExpertsSlider from "../../expertsSliderMain/ExpertsSlider";
import ProjectScreenTitle from "../../projectScreenTitleMain/ProjectScreenTitle";

const ExpertsScreen = ({ data }) => {
  return (
    <section className="experts-screen">
      <div className="experts-screen__content">
        <ProjectScreenTitle
          isMobileBorder
          color="#5278FF"
          content={data.title}
        />
        <ExpertsSlider type="persons" data={data.experts} />
      </div>
    </section>
  );
};

export default ExpertsScreen;
