import React from 'react'
import './style.sass'
import star from '../../assets/image/star.png'
import status from '../../assets/image/status.png'
import progressIcon from '../../assets/image/progress.png'

export default class CourseStat extends React.Component{

    _renderStatus = () =>{
        const {courses} = this.props
        let rank = courses.rankName
        return rank
    }

    render() {
        const {courses} = this.props
        let progress = courses.progress
        return (
            <div className={'CourseStat ' + (courses.progressTest !== undefined && 'CourseStatFull')}>
                <div className="head">
                    Статистика курса
                </div>
                <div className="balls">
                    <div className="icon">
                        <img src={star} alt=""/>
                    </div>
                    <div className="balls__box">
                        Набрано баллов{courses.progressTest !== undefined && ' по урокам'}:&#8194;<b>{courses.userPoints} из {this.props.pointTotal}</b>
                    </div>
                </div>
                {courses.pointsTest !== undefined ?
                    <div className="status">
                        <div className="icon">
                            <img src={star} alt=""/>
                        </div>
                        <div className="balls__box">
                            Набрано баллов{courses.progressTest !== undefined && ' по тестам'}:&#8194;<b>{courses.pointsTest} из {this.props.pointTotalTest}</b>
                        </div>
                    </div>
                    :
                    <div className="status">
                        <div className="icon">
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="20" r="20" fill="white"></circle><path d="M28 27V25C28 22.7909 26.2091 21 24 21H16C13.7909 21 12 22.7909 12 25V27" stroke="#FF6700" strokeWidth="2"></path><circle cx="20" cy="13" r="4" stroke="#FF6700" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></circle></svg>
                        </div>
                        <div className="balls__box">
                            Статус: &nbsp; <b>{this._renderStatus()}</b>
                        </div>
                    </div>
                }
                <div className={'progress ' + (courses.progressTest !== undefined && 'progressHulf')}>
                    <div className="icon">
                        <img src={progressIcon} alt=""/>
                    </div>
                    <div className="desc">
                        Общий прогресс{courses.progressTest !== undefined && ' по уроку'}:&#8194;<div className="textProgress textProgress--mobile" style={{left: progress + '%'}}>{Math.round(progress)}%</div>
                        <div className="line">
                            <div className="lineProgress" style={{width: Math.round(progress) + '%'}}/>
                        </div>
                        <div className="textProgress" style={{left: progress + '%'}}>{Math.round(progress)}%</div>
                    </div>
                </div>
                {courses.progressTest !== undefined &&
                    <div className="progress progressHulf">
                        <div className="icon">
                            <img src={progressIcon} alt=""/>
                        </div>
                        <div className="desc">
                            Общий прогресс по тесту:&#8194;<div className="textProgress textProgress--mobile" style={{left: courses.progressTest + '%'}}>{Math.round(courses.progressTest)}%</div>
                            <div className="line">
                                <div className="lineProgress" style={{width: Math.round(courses.progressTest) + '%'}}/>
                            </div>
                            <div className="textProgress" style={{left: courses.progressTest + '%'}}>{Math.round(courses.progressTest)}%</div>
                        </div>
                    </div>
                }
            </div>
        )
    }

}
