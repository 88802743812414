import React from "react";
// import ScrollableAnchor from "react-scrollable-anchor";
import Slider from "../../pageComponents/sliderSecond/Slider";
import SliderScreenHeader from "../../pageComponents/sliderScreenHeaderMain/SliderScreenHeader";
import bgImg from "../../images/screen-three/bg.png";
import {
    ScreenThreeHeaderData,
    ScreenThreeSliderData,
} from "./ScreenSevenData";

const ScreenSeven = () => {
    return (
        // <ScrollableAnchor id="marathons">
        <section className="screen-three" id="marathons">
            <div className="screen-three__content">
                <img src={bgImg} alt="" className="screen-three__bg-img"/>
                <div className="screen-seven__title">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <h2 className="screen-seven__title-text">Направления движения</h2>
                </div>
                {/*<SliderScreenHeader type="violet" data={ScreenThreeHeaderData} />*/}
                {/*<Slider*/}
                {/*  data={ScreenThreeSliderData}*/}
                {/*  type="violet"*/}
                {/*  header_text="марафон"*/}
                {/*  button_text="подробнее"*/}
                {/*/>*/}
                <div className="slider__content2">
                    <div className="slider-block ">
                        <div className="slider-block__header">
                            <div className="slider-block__icon2">01</div>
                            <img src="/static/media/slider_1.634dabea.png" alt="" className="slider-block__img"/>
                        </div>
                        <div className="slider-block__content">
                            <div className="slider-block__info">
                                <p className="slider-block__info-title2">Образование и
                                знания</p>
                            </div>
                            <a className="slider-block__title2"
                               href="https://xn--90acagbhgpca7c8c7f.xn--p1ai/projects/18"
                               target="_blank"><span>«УЧИСЬ И ПОЗНАВАЙ!»</span></a>
                        </div>
                    </div>
                    <div className="slider-block ">
                        <div className="slider-block__header">
                            <div className="slider-block__icon2">02</div>
                            <img src="/static/media/slider_2.f74c6ad8.png" alt="" className="slider-block__img"/></div>
                        <div className="slider-block__content">
                            <div className="slider-block__info"><p className="slider-block__info-title2">Наука и
                                технологии</p>
                            </div>
                            <a className="slider-block__title2"
                               href="https://xn--90acagbhgpca7c8c7f.xn--p1ai/projects/17"
                               target="_blank"><span>«ДЕРЗАЙ И ОТКРЫВАЙ!»</span></a></div>
                    </div>
                    <div className="slider-block ">
                        <div className="slider-block__header">
                            <div className="slider-block__icon2">03</div>
                            <img src="/static/media/slider_3.b7bc833b.png" alt="" className="slider-block__img"/></div>
                        <div className="slider-block__content">
                            <div className="slider-block__info"><p className="slider-block__info-title2">Труд, профессия
                                и своё
                                дело</p></div>
                            <a className="slider-block__title2"
                               href="https://xn--90acagbhgpca7c8c7f.xn--p1ai/projects/19"
                               target="_blank"><span>«НАЙДИ ПРИЗВАНИЕ!»</span></a></div>
                    </div>
                    <div className="slider-block ">
                        <div className="slider-block__header">
                            <div className="slider-block__icon2">04</div>
                            <img src="/static/media/slider_1.634dabea.png" alt="" className="slider-block__img"/></div>
                        <div className="slider-block__content">
                            <div className="slider-block__info"><p className="slider-block__info-title2">Культура и
                                искусство</p>
                            </div>
                            <a className="slider-block__title2"
                               href="https://xn--90acagbhgpca7c8c7f.xn--p1ai/projects/20"
                               target="_blank"><span>«СОЗДАВАЙ И ВДОХНОВЛЯЙ!»</span></a></div>
                    </div>
                    <div className="slider-block ">
                        <div className="slider-block__header">
                            <div className="slider-block__icon2">05</div>
                            <img src="/static/media/slider_2.f74c6ad8.png" alt="" className="slider-block__img"/></div>
                        <div className="slider-block__content">
                            <div className="slider-block__info"><p className="slider-block__info-title2">Волонтёрство и
                                добровольчество</p></div>
                            <a className="slider-block__title2"
                               href="https://xn--90acagbhgpca7c8c7f.xn--p1ai/projects/27"
                               target="_blank"><span>«БЛАГО ТВОРИ!»</span></a></div>
                    </div>
                    <div className="slider-block ">
                        <div className="slider-block__header">
                            <div className="slider-block__icon2">06</div>
                            <img src="/static/media/slider_3.b7bc833b.png" alt="" className="slider-block__img"/></div>
                        <div className="slider-block__content">
                            <div className="slider-block__info"><p className="slider-block__info-title2">Патриотизм и
                                историческая
                                память</p></div>
                            <a className="slider-block__title2"
                               href="https://xn--90acagbhgpca7c8c7f.xn--p1ai/projects/21"
                               target="_blank"><span>«СЛУЖИ ОТЕЧЕСТВУ!»</span></a></div>
                    </div>
                    <div className="slider-block ">
                        <div className="slider-block__header">
                            <div className="slider-block__icon2">07</div>
                            <img src="/static/media/slider_1.634dabea.png" alt="" className="slider-block__img"/></div>
                        <div className="slider-block__content">
                            <div className="slider-block__info"><p className="slider-block__info-title2">Спорт</p></div>
                            <a className="slider-block__title2"
                               href="https://xn--90acagbhgpca7c8c7f.xn--p1ai/projects/23"
                               target="_blank"><span>«ДОСТИГАЙ И ПОБЕЖДАЙ!»</span></a></div>
                    </div>
                    <div className="slider-block ">
                        <div className="slider-block__header">
                            <div className="slider-block__icon2">08</div>
                            <img src="/static/media/slider_2.f74c6ad8.png" alt="" className="slider-block__img"/></div>
                        <div className="slider-block__content">
                            <div className="slider-block__info"><p className="slider-block__info-title2">Здоровый образ
                                жизни</p>
                            </div>
                            <a className="slider-block__title2"
                               href="https://xn--90acagbhgpca7c8c7f.xn--p1ai/projects/24"
                               target="_blank"><span>«БУДЬ ЗДОРОВ!»</span></a></div>
                    </div>
                    <div className="slider-block ">
                        <div className="slider-block__header">
                            <div className="slider-block__icon2">09</div>
                            <img src="/static/media/slider_3.b7bc833b.png" alt="" className="slider-block__img"/></div>
                        <div className="slider-block__content">
                            <div className="slider-block__info"><p className="slider-block__info-title2">Медиа и
                                коммуникации</p>
                            </div>
                            <a className="slider-block__title2"
                               href="https://xn--90acagbhgpca7c8c7f.xn--p1ai/projects/22"
                               target="_blank"><span>«РАССКАЖИ О ГЛАВНОМ!»</span></a></div>
                    </div>
                    <div className="slider-block ">
                        <div className="slider-block__header">
                            <div className="slider-block__icon2">10</div>
                            <img src="/static/media/slider_1.634dabea.png" alt="" className="slider-block__img"/></div>
                        <div className="slider-block__content">
                            <div className="slider-block__info"><p className="slider-block__info-title2">Дипломатия и
                                международные
                                отношения</p></div>
                            <a className="slider-block__title2" href="#"
                               target="_blank"><span>«УМЕЙ ДРУЖИТЬ!»</span></a></div>
                    </div>
                    <div className="slider-block ">
                        <div className="slider-block__header">
                            <div className="slider-block__icon2">11</div>
                            <img src="/static/media/slider_2.f74c6ad8.png" alt="" className="slider-block__img"/></div>
                        <div className="slider-block__content">
                            <div className="slider-block__info"><p className="slider-block__info-title2">Экология и
                                охрана
                                природы</p></div>
                            <a className="slider-block__title2"
                               href="https://xn--90acagbhgpca7c8c7f.xn--p1ai/projects/26"
                               target="_blank"><span>«БЕРЕГИ ПЛАНЕТУ!»</span></a></div>
                    </div>
                    <div className="slider-block ">
                        <div className="slider-block__header">
                            <div className="slider-block__icon2">12</div>
                            <img src="/static/media/slider_3.b7bc833b.png" alt="" className="slider-block__img"/></div>
                        <div className="slider-block__content">
                            <div className="slider-block__info"><p className="slider-block__info-title2">Туризм и
                                путешествия</p>
                            </div>
                            <a className="slider-block__title2"
                               href="https://xn--90acagbhgpca7c8c7f.xn--p1ai/projects/25"
                               target="_blank"><span>«ОТКРЫВАЙ СТРАНУ!»</span></a></div>
                    </div>
                </div>
            </div>
        </section>
        // </ScrollableAnchor>
    );
};

export default ScreenSeven;
