import React from 'react'

export default class Info extends React.Component {
    render() {
        const {showLoginClick, text, btn_auth} = this.props
        return (
            <div className="Auth">
                <div className="login__info" dangerouslySetInnerHTML={{__html: text}}/>
                {btn_auth &&
                <div className="reLink">
                    <div className="reLink__el"/>
                    <div className="reLink__el" onClick={() => showLoginClick('auth')}>
                        Авторизация
                    </div>
                </div>
                }
            </div>
        )
    }

}

