import React, { useState } from "react";
import { isMobileOnly } from "react-device-detect";
import Button from "../../buttonMain/Button";
import Dots from "../../dotsMain/Dots";
import Modal from "../../modalMain/Modal";
import ProjectScreenHeader from "../../projectScreenHeaderMain/ProjectScreenHeader";

const TextScreen = ({ data, user, showLoginClick }) => {
  const [modal, setModal] = useState(false);

  return (
    <section className="text-screen">
      <div className="text-screen__content">
        <ProjectScreenHeader
          title={data.title}
          img={data.header_img}
          bg={data.bg}
        />
        {isMobileOnly && (
          <img src={data.bg} alt="" className="text-screen__mobile-bg" />
        )}
        <div className="text-screen__info">
          {data.info_items.map((item, index) => (
            <div
              className="text-screen__info-text"
              key={`text-screen-item-${index}`}
            >
              {item}
            </div>
          ))}
        </div>
        {isMobileOnly && (
          <div className="text-screen__mobile-icon">
            <Dots color="#5afff5" />
            <img
              src={data.header_img}
              alt=""
              className="text-screen__mobile-icon-img"
            />
          </div>
        )}
        {!user &&
        <Button
            type="red"
            content="хочу участвовать!"
            onClick={() => showLoginClick('registration')}
        />
        }
      </div>
      {modal && <Modal onClose={() => setModal(false)} />}
    </section>
  );
};

export default TextScreen;
