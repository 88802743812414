import React from "react";

// experts
import kartashevaImg from "../../../images/screen-six/Kartasheva.png";
import lukjanenkoImg from "../../../images/screen-six/Lukjanenko.png";
import mashkovImg from "../../../images/screen-six/Mashkov.png";

// images
import titleScreenImg from "../../../images/project-screens/goal-setting/bg/title-screen.png";

const goalSettingData = {
  titleScreen: {
    header: "Бери максимум!",
    title: "Как стать успешным?",
    content:
      "Чтобы быть успешным, нужно много учиться. Постоянно. Всегда по-разному. Не только в школе, колледже, вузе, но и в обычной и профессиональной жизни.",
    bg: titleScreenImg,
  },
  bulletScreen: {
    title: "Чему ты научишься на этом марафоне?",
    info_items: [
      <span>Как управлять своим временем</span>,
      <span>Как концентрировать свое внимание</span>,
      <span>Как быстро читать и схватывать основную мысль</span>,
      <span>Как легко запоминать большое количество информации</span>,
    ],
  },
  expertsScreen: {
    title: "Эксперты курса",
    experts: [
      {
        name: "Алла Карташева",
        job: (
          <span>
            доктор медицинских
            <br />
            наук
          </span>
        ),
        img: kartashevaImg,
      },
      {
        name: "Сергей Лукьяненко",
        job: "писатель-фантаст",
        img: lukjanenkoImg,
      },
      {
        name: "Владимир Машков",
        job: "актер и режиссер",
        img: mashkovImg,
      },
    ],
  },
};

export default goalSettingData;
