import React from "react";
import ProjectScreenTitle from "../../projectScreenTitleMain/ProjectScreenTitle";

const FeaturesScreen = ({ data }) => {
  return (
    <section className="features-screen">
      <div className="features-screen__content">
        <ProjectScreenTitle color="#5AFFF5" content={data.title} />
        <div className="features-screen__info">
          {data.info_items.map((item, index) => (
            <div
              key={`features-screen-item-${index}`}
              className="features-screen__info-item"
            >
              <p className="features-screen__info-item-title">{item.title}</p>
              <p className="features-screen__info-item-text">{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesScreen;
