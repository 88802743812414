import React from "react";

// experts
import borodulinaImg from "../../../images/project-screens/digital-museum/experts/borodulina.png";
import dainekoImg from "../../../images/project-screens/digital-museum/experts/daineko.png";
import dvernikovaImg from "../../../images/project-screens/digital-museum/experts/dvernikova.png";
import kunitskayaImg from "../../../images/project-screens/digital-museum/experts/kunitskaya.png";
import kuzinaImg from "../../../images/project-screens/digital-museum/experts/kuzina.png";
import trofimovaImg from "../../../images/project-screens/digital-museum/experts/trofimova.png";

// coorgs
import coorgLogo_1 from "../../../images/project-screens/digital-museum/coorgs/coorg_1.png";
import coorgLogo_2 from "../../../images/project-screens/digital-museum/coorgs/coorg_2.png";

// partners
import partnerLogo_1 from "../../../images/project-screens/digital-museum/partners/partner_1.png";
import partnerLogo_2 from "../../../images/project-screens/digital-museum/partners/partner_2.png";

// images
import titleScreenImg from "../../../images/project-screens/digital-museum/bg/title-screen.png";
import textScreenHeaderImg from "../../../images/brain.png";
import textScreenImg from "../../../images/project-screens/digital-museum/bg/text-screen.png";
import bulletVerticalScreenHeaderImg from "../../../images/strategy.svg";
import bulletVerticalScreenImg from "../../../images/project-screens/digital-museum/bg/bullet-vertical-screen.png";
import bulletExtendedScreenHeaderImg from "../../../images/project-screens/digital-museum/bg/bullet-extended-header.svg";
import bulletExtendedScreenImg from "../../../images/project-screens/digital-museum/bg/bullet-extended.png";

const digitalMuseumData = {
  titleScreen: {
    header: "новый цифровой музей",
    title: "Музеи — это скучно?",
    content:
      "Думаешь, музеи - это скучно, неинтересно и несовременно? Пришло время больших перемен!",
    bg: titleScreenImg,
  },
  bulletScreen: {
    title: "Сегодня музеи — это:",
    info_items: [
      <span>
        Открытое пространство
        <br />
        для самовыражения
      </span>,
      <span>
        Современные
        <br />
        интерактивные технологии
      </span>,
      <span>
        Крутые образовательные
        <br />
        программы
      </span>,
      <span>
        Модные площадки
        <br />
        для общения
      </span>,
    ],
  },
  textScreen: {
    title: (
      <span>
        Прокачай свой
        <br />
        школьный музей!
      </span>
    ),
    header_img: textScreenHeaderImg,
    bg: textScreenImg,
    info_items: [
      <p>
        <span className="text-screen__info-text--accent">
          Музеи. Практики будущего
        </span>{" "}
        - это онлайн-школа, с участием самых востребованных кураторов и
        специалистов музейного дела.
      </p>,
      <p>
        С 1 октября по 30 ноября тебя ждет полное погружение в музейные
        практики, целью которых станет создание ПРОЕКТА ШКОЛЬНОГО МУЗЕЯ.
      </p>,
      <p>
        Наши специалисты помогут тебе по-новому взглянуть на свою школу,
        учителей и одноклассников и расскажут, как спроектировать классное
        музейное пространство, внедрить в него современные технологические
        решения, наполнить нестандартными мероприятиями, интересно рассказать о
        своем замысле и получить возможность его реализовать.
      </p>,
      <p className="text-screen__info-text--accent">
        Авторы лучших проектов получат поддержку и ресурсы на воплощение идеи!
      </p>,
      <p>
        Подробно об этом ты можешь узнать в{" "}
        <span className="text-screen__info-text--accent">
          Положении о конкурсе.
        </span>
      </p>,
    ],
  },
  featuresScreen: {
    title: "На проекте тебя ждут",
    info_items: [
      {
        title: "8 недель",
        text: (
          <span>
            общения, творчества
            <br />и исследования
          </span>
        ),
      },
      {
        title: "16 кураторов",
        text: (
          <span>
            которые помогут тебе
            <br />
            воплотить твои идеи
          </span>
        ),
      },
      {
        title: "40 команд",
        text: (
          <span>
            которые также, как и ты,
            <br />
            будут работать над созданием
            <br />
            нового музейного пространства
          </span>
        ),
      },
      {
        title: "1 большой проект",
        text: (
          <span>
            нового школьного музея,
            <br />
            которым ты будешь
            <br />
            гордиться
          </span>
        ),
      },
    ],
  },
  bulletScreenTwo: {
    title: "А ещё",
    info_items: [
      <span>
        Знакомство с лучшими
        <br />
        музейными примерами,
        <br />
        технологиями и практиками
      </span>,
      <span>
        Прямая связь с модераторами,
        <br />
        кураторами и общение с<br />
        единомышленниками
      </span>,
      <span>
        Новый опыт и навыки в музейных
        <br />
        профессиях, которые могут стать
        <br />
        делом твоей жизни
      </span>,
      <span>
        Возможность стать победителем!
        <br />
        Но даже если твой проект не попадет в<br />
        финал, ты получишь бесценный опыт,
        <br />
        новые и полезные контакты и<br />
        значительную порцию вдохновения и<br />
        креатива!
      </span>,
    ],
  },
  bulletVerticalScreen: {
    title: (
      <span>
        Как принять <br />
        участие?
      </span>
    ),
    header_img: bulletVerticalScreenHeaderImg,
    bg: bulletVerticalScreenImg,
    info_items: [
      <span>
        Собрать свою музейную школьную команду <br />
        единомышленников, которым уже исполнилось 13 лет. <br />
        Как? <span>Читай наши советы.</span>
      </span>,
      <span>
        Заручиться поддержкой <br />
        школьной администрации
      </span>,
      <span>
        Изучить существующий <br />
        школьный музей
      </span>,
      <span>
        Подумать, что бы хотелось <br />в нем изменить и почему
      </span>,
      <span>
        Заполнить заявку <br />
        на участие в конкурсе
      </span>,
    ],
    description: (
      <span>
        Отбор победителей будет осуществлять <br />
        команда наших кураторов. Кто они? Читай, <br />
        смотри, вдохновляйся!
      </span>
    ),
  },
  expertsScreen: {
    title: "Наши кураторы",
    experts: [
      {
        name: "Анна Бородулина",
        job: (
          <span>
            музей Бориса
            <br />
            Ельцина
          </span>
        ),
        img: borodulinaImg,
      },
      {
        name: "Вера Дверникова",
        job: (
          <span>
            Исторический парк
            <br />
            “Россия Моя история”
          </span>
        ),
        img: dvernikovaImg,
      },
      {
        name: "Ирина Куницкая",
        job: (
          <span>
            музей Бориса
            <br />
            Ельцина
          </span>
        ),
        img: kunitskayaImg,
      },
      {
        name: "Ирина Трофимова",
        job: (
          <span>
            Креативное агентство
            <br />
            «Азбука Морзе»
          </span>
        ),
        img: trofimovaImg,
      },
      {
        name: "Ольга Кузина",
        job: 'ГМЗ "Царицыно"',
        img: kuzinaImg,
      },
      {
        name: "Мария Дайнеко",
        job: (
          <span>
            Государственный
            <br />
            Эрмитаж
          </span>
        ),
        img: dainekoImg,
      },
    ],
  },
  bulletExtendedScreen: {
    title: "Твоя музейная команда",
    subtitle: "Мы рекомендуем позвать в команду людей, которые",
    info_items: [
      "знают, что такое современный музей или выставка",
      "имеют хорошее воображение и умеют рассказывать истории",
      "слышали о том, что такое причинно-следственные связи",
      "не боятся программировать или 3D-моделировать",
      "любят фотографировать, снимать и монтировать видео",
      "интересуются созданием сайтов и приложений",
    ],
    description:
      "А самое главное: имеют достаточно времени и сил для участия в школе, ведь на общение, встречи, мозговые штурмы и написание проекта будет уходить не менее 4 часов в неделю.",
  },
  textBigScreen: {
    title: (
      <span>
        Что ждет
        <br />
        победителей?
      </span>
    ),
    header_img: bulletExtendedScreenHeaderImg,
    bg: bulletExtendedScreenImg,
    info:
      "Авторы лучших проектов школы выступят на выставке “Интермузей-2021” и получат ресурсы на воплощение своих идей.",
  },
  coorgScreen: {
    title: "Cоорганизаторы",
    logos: [{ img: coorgLogo_1 }, { img: coorgLogo_2 }],
  },
  partnersScreen: {
    title: "Партнеры",
    logos: [{ img: partnerLogo_1 }, { img: partnerLogo_2 }],
  },
};

export default digitalMuseumData;
