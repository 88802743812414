import React from "react";
import {Link} from "react-router-dom";

export default ({navListShow}) => {
    return (
            <div className={"navigation__list " + (navListShow ? 'show' : '')}>
                <Link to="/forums" className="navigation__list-el">Мероприятия</Link>
                {/*<Link to="/training" className="navigation__list-el">Обучение</Link>*/}
                {/*<Link to="/test" className="navigation__list-el">Тестирование</Link>*/}
                {/*<Link to="#" className="navigation__list-el">Обучение</Link>*/}
            </div>

    )
}
