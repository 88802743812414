import React from "react";
import { ReactComponent as ArrowIcon } from "../../images/arrow.svg";

const SliderHandlers = ({
  type,
  first_count,
  second_count,
  slideLeft,
  slideRight,
}) => {
  return (
    <div className={`slider-handlers slider-handlers--${type}`}>
      <div className="slider-handlers__count">
        0{first_count} / 0{second_count}
      </div>
      <button onClick={slideLeft} className="slider-handlers__nav-btn">
        <ArrowIcon />
      </button>
      <button
        onClick={slideRight}
        className="slider-handlers__nav-btn slider-handlers__nav-btn--right"
      >
        <ArrowIcon />
      </button>
    </div>
  );
};

export default SliderHandlers;
