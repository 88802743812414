import React from "react";
import CountBlock from "../../countBlock/CountBlock";
import ProjectScreenTitle from "../../projectScreenTitleMain/ProjectScreenTitle";
import { isMobileOnly } from "react-device-detect";

const BulletScreen = ({ data, info_margin }) => {
  return (
    <section className="bullet-screen">
      <div className="bullet-screen__content">
        <ProjectScreenTitle color="#ff5252" content={data.title} />
        <div
          style={isMobileOnly ? {} : { margin: info_margin }}
          className="bullet-screen__info"
        >
          {data.info_items.map((item, index) => (
            <div
              key={`bullet-screen-item-${index}`}
              className="bullet-screen__info-item"
            >
              <CountBlock color="#FF5252" count={index + 1} />
              <p className="bullet-screen__info-item-text">{item}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BulletScreen;
