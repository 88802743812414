import React, {useState, useEffect} from 'react'
import Api from "../../Service/Api";
import Header from "../../pageComponents/headerMain/Header";

const Team = () => {
    const api = new Api()
    const [user, setUser] = useState(undefined)

    const getUserData = () => {
        api.getUserData()
            .then(user => setUser(user))
            .catch(e => console.log('error', e))
    }

    useEffect(() => {
        getUserData()
    }, [])

    return(
        <div className="team page">
            <Header user={user}/>
            <div className="cnt">
                <h2 className="team__header">Мои команды</h2>
                <p className="team__text">Вы не состоити не в одной команде</p>
            </div>
        </div>
    )
}

export default Team