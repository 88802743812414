import React, { useState } from "react";
// import { ExpertSliderBlock } from "../../types";
import SliderHandlers from "../sliderHandlersMain/SliderHandlers";

const MAX_ITEMS_PER_PAGE = 6;
const CONTAINER_WIDTH = 1280;

const ExpertsSlider = ({ data, type }) => {
  const [leftPosition, setLeftPosition] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / MAX_ITEMS_PER_PAGE);

  const slideLeft = () => {
    if (currentPage === 1) {
      return;
    }
    setCurrentPage(currentPage - 1);
    setLeftPosition(leftPosition + CONTAINER_WIDTH);
  };

  const slideRight = () => {
    if (currentPage === totalPages) {
      return;
    }
    setCurrentPage(currentPage + 1);
    setLeftPosition(leftPosition - CONTAINER_WIDTH);
  };

  const renderPersonBlock = (data, index) => (
    <div key={`expert_${index}`} className="experts-slider__block">
      <img src={data.img} alt="" className="experts-slider__block-img" />
      <p className="experts-slider__block-name">{data.name}</p>
      <p className="experts-slider__block-job">{data.job}</p>
    </div>
  );

  const renderLogoBlock = (data, index) => (
    <div key={`logo_${index}`} className="experts-slider__block-logo">
      <img src={data.img} alt="" className="experts-slider__block-logo-img" />
    </div>
  );

  return (
    <div className="experts-slider">
      <div className="experts-slider__wrapper">
        <div
          style={{ left: leftPosition }}
          className={`experts-slider__content ${
            totalPages === 1 ? "experts-slider__content--centered" : ""
          }`}
        >
          {type === "persons" &&
            data.map((sliderItem, index) =>
              renderPersonBlock(sliderItem, index)
            )}
          {type === "logos" &&
            data.map((sliderItem, index) => renderLogoBlock(sliderItem, index))}
        </div>
      </div>
      <SliderHandlers
        type="violet"
        first_count={currentPage}
        second_count={totalPages}
        slideLeft={slideLeft}
        slideRight={slideRight}
      />
    </div>
  );
};

export default ExpertsSlider;
