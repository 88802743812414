import React, {useEffect, useState} from "react";
import Footer from "../../../pageComponents/footerMain/Footer";
import Header from "../../../pageComponents/headerMain/Header";
import {
  ExpertsScreen,
  TextScreen,
  TitleScreen,
} from "../../../pageComponents/projectScreens/projectScreens";
import educationSystemData from "./educationSystemData";
import Api from "../../../Service/Api";

const EducationSystem = () => {

    const [user, setUser] = useState()
    const [showLogin, setShowLogin] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  });

    useEffect(() => {
        new Api().getUserData()
            .then(user => setUser(user))
            .catch(e => console.log('error', e))
    }, []);

    const showLoginClick = (showLogin) => {
        setShowLogin(showLogin)
    }

    const getUserData = () => {
        new Api().getUserData()
            .then(user => setUser(user))
            .catch(e => console.log('error', e))
    }

  return (
    <main className="project-page">
        <Header
            user={user}
            showLogin={showLogin}
            showLoginClick={showLoginClick}
            getUserData={getUserData}
        />
        <TitleScreen data={educationSystemData.titleScreen} user={user} showLoginClick={showLoginClick} />
      <TextScreen data={educationSystemData.textScreen} user={user} showLoginClick={showLoginClick}/>
      <ExpertsScreen data={educationSystemData.expertsScreen} />
      <Footer user={user}/>
    </main>
  );
};

export default EducationSystem;
