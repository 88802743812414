import React, {useState, useEffect} from 'react'
import Api from "../../Service/Api";
import Header from "../../pageComponents/headerMain/Header";

const Achievements = () => {
    const api = new Api()
    const [user, setUser] = useState(undefined)

    const getUserData = () => {
        api.getUserData()
            .then(user => setUser(user))
            .catch(e => console.log('error', e))
    }

    useEffect(() => {
        getUserData()
    }, [])

    return(
        <div className="my-achievements page">
            <Header user={user}/>
            <div className="cnt">
                <h2 className="my-achievements__header">Мои достижения</h2>
                <p className="my-achievements__text">У вас нет достижений</p>
            </div>
        </div>
    )
}

export default Achievements