import React from 'react'
import Api from '../../Service/Api'

import Header from '../../pageComponents/headerMain/Header'
import Banner from '../../pageComponents/banner'
import Speaker from '../../pageComponents/speaker'
import Programs from '../../pageComponents/programs'
import Organizers from '../../pageComponents/organizers'

import Footer from '../../pageComponents/footerMain/Footer'
import bg from "../../assets/image/bannerBg.png";
import bgPeople from "../../assets/image/bannerPeople.png";


export default class ForumDayEvents extends React.Component {
    Api = new Api()

    state = {
        user: undefined,
        forumData: undefined,
        showAllProjects: false,
        showLogin: false,
        showAllPartners: false
    }
    showLoginClick = (showLogin) => {
        this.setState({showLogin})
    }

    componentDidMount() {
        this.getUserData();
        this.getForumData();
    }

    getUserData = () => {
        this.Api.getUserData()
            .then(user => this.setState({user}))
            .catch(e => console.log('error', e))
    }

    getForumData = () => {
        this.Api.getForum(this.props.match.params.id)
            .then(forumData => {
                this.setState({forumData}, () => {

                })
            })
            .catch(e => console.log('error', e))
    }




    render() {
        const {user, forumData, showAllProjects, showLogin, showAllPartners} = this.state;
        return (
            <div className="forumDatEvents__page page">
                <Header
                    user={user}
                    getUserData={this.getUserData}
                    showLogin={showLogin}
                    showLoginClick={this.showLoginClick}
                />
                {forumData ?
                    <Banner
                        // bg={bg}
                        bgPeople={bgPeople}
                        banner__date_start={forumData.forum.date_start}
                        banner__date_end={forumData.forum.date_end}
                        banner__head={forumData.forum.name}
                        banner__text={forumData.forum.description}
                        // banner__btn={user ? `Добавить в мои мероприятия` : 'Регистрация'}
                    />
                    :
                    <Banner
                        bg={bg}
                        bgPeople={bgPeople}
                    />
                }
                {forumData && forumData.forumSpeaker && <Speaker speakers={forumData.forumSpeaker }/>}
                {forumData && forumData.forumDays &&
                    <Programs
                        forum_id={this.props.match.params.id}
                        user={user}
                        serverTime={forumData.server.date}
                        timeZone={forumData.forum.timeZone}
                        forumDays={forumData.forumDays}
                        forumDaysEvent={forumData.forumDaysEvent}
                        forumDaysEventSpeakers={forumData.forumDaysEventSpeakers}
                        userEvents={forumData.userEvents}
                        getForumData={this.getForumData}
                        showLoginClick={this.showLoginClick}
                    />
                }
                <Organizers forum_id={this.props.match.params.id} className={'partners__flex--center'}/>
                <Footer user={user}/>
            </div>
        )
    }
}
