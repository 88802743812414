import React from "react";
import Api from "../../Service/Api";
import Header from "../../pageComponents/headerMain/Header";
import Banner from "../../pageComponents/banner";
import bg from "../../assets/image/bannerBg.png";
import Footer from "../../pageComponents/footerMain/Footer";
import ForumsEl from "../../Components/forumsEl";
import Projects from "../../pageComponents/projects";
import Partners from "../../pageComponents/partners";
import Organizer from "../../pageComponents/organizers";
import Button from '../../Components/button';
import bgPeople from "../../assets/image/bannerPeople.png";

export default class Forums extends React.Component {
    Api = new Api()

    state = {
        user: undefined,
        forums: undefined,
        showAllPartners: false,
        showAllProjects: false,
        showLogin: false,
        // listHead: 'По итогам программы Вы:',
        // listData: [
        //     'Познакомитесь с современными форматами и инструментами работы',
        //     'Научитесь применять полученный опыт в своей профессиональной деятельности',
        //     'Сможете принять участие в образовательных мероприятиях программы',
        //     'Сможете поддержать своего конкурсанта на итоговой защите проектов.',
        // ],
    }
    showLoginClick = (showLogin) => {
        this.setState({showLogin})
    }
    componentDidMount() {
        this.getUserData();
        this.getAllForum();

    }

    getUserData = () => {
        this.Api.getUserData()
            .then(user => this.setState({user}))
            .catch(e => console.log('error', e))
    }

    getAllForum = () => {
        this.Api.getAllForums()
            .then(forums => this.setState({forums}))
            .catch(e => console.log('error', e))
    }


    render() {
        const {user, forums, showAllPartners, showAllProjects, showLogin, listData, listHead} = this.state
        return (
            <div className="forums__page page">
                <Header
                    user={user}
                    getUserData={this.getUserData}
                    showLogin={showLogin}
                    showLoginClick={this.showLoginClick}
                />
                <Banner
                    // bg={bg}
                    bgPeople={bgPeople}
                    banner__head={`Голос поколения`}
                    banner__text={`Эта программа поможет вам разработать и внедрить решения, повышающие эффективность реализации молодёжной политики. Вы узнаете, как эффективно трансформировать вашу деятельность в связи с пандемией коронавирусной инфекции COVID-19 и как интегрировать мероприятия и проекты в интернет-пространство.

На платформе вас ждёт полезный контент, лекции и мастер-классы от лидеров мнений и признанных профессионалов. По итогам программы вы познакомитесь с современными форматами и инструментами работы и научитесь применять полученный опыт в своей профессиональной деятельности.`}
                    // listHead={listHead}
                    // ListData={listData}
                    banner__btn={!user ? `Регистрация` : undefined}
                    showLoginClick={this.showLoginClick}
                />
                <div className="cnt">
                    <div className="forums__all">

                        {forums && Object.values(forums).reverse().map((forum, index) => {
                            return (
                                <div
                                    key={forum.id + +new Date() + index}
                                    className={'forums__el'}
                                >
                                    <ForumsEl
                                        img={'https://xn--c1aejgebpdbabjz8r.xn--p1ai/uploads' + forum.image}
                                        forum={forum}
                                        name={forum.name}
                                        dateStart={forum.date_start}
                                        dateEnd={forum.date_end}
                                        showBtn={user === false}
                                        showLoginClick={this.showLoginClick}
                                    />
                                </div>
                            )
                        })}
                    </div>
                    <div className="organizer_all">
                        <Organizer showDesc={'true'}/>
                    </div>
                </div>

                <Footer user={user}/>

            </div>
        )
    }
}
