import React from "react";
import Inputs from "../../pageComponents/Inputs";
import InputMask from 'react-input-mask';
import Api from "../../Service/Api";

export default class Registration extends React.Component{
    
    state = {
        errorGlobal: false,
        inputs: {
            name: {
                errorText: false,
                readOnly: true,
                value: '',
                error: false,
                type: 'text',
                label: 'Имя',
                name: 'name',
            },
            surname: {
                errorText: false,
                readOnly: true,
                value: '',
                error: false,
                type: 'text',
                label: 'Фамилия',
                name: 'surname',
            },
            middle_name: {
                errorText: false,
                readOnly: true,
                value: '',
                error: false,
                type: 'text',
                label: 'Отчество',
                name: 'middle_name',
            },
            email: {
                errorText: false,
                readOnly: true,
                value: '',
                error: false,
                type: 'text',
                label: 'Емейл ',
                name: 'email',
                disabled: false
            },
            phone: {
                errorText: false,
                readOnly: true,
                value: '',
                error: false,
                type: 'text',
                label: 'Телефон',
                name: 'phone',
                mask: '+7(999)999-9999'
            },
            password: {
                errorText: false,
                readOnly: true,
                value: '',
                error: false,
                type: 'password',
                label: 'Придумайте пароль',
                name: 'password',
            },
            agree: {
                errorText: false,
                readOnly: true,
                value: '',
                error: false,
                type: 'checkbox',
                label: 'Согласен на обработку персональных данных',
                name: 'agree',
            },
        }
    }

    Api = new Api()

    componentDidMount() {
        const {user} = this.props;
        Object.keys(user).map(el => {
            if(this.state.inputs[el] && user[el] !== null && el !== 'gender'){
                this.state.inputs[el].value = user[el]
                this.state.inputs.email.disabled = true
            }
        })

        this.setState({date: new Date()}, () => {
            console.log(this.state)
        })
    }

    updateState = (type, value, index) => {
        this.state.inputs[type].value = value
        this.state.inputs[type].error = false
        this.state.inputs[type].errorText = false
        this.state.errorGlobal = false
        this.setState({date: new Date()})
    }

    sendDate = () => {
        const {inputs} = this.state;
        const {showLoginClick, user, token} = this.props;
        let errors = 0;
        Object.values(inputs).map(input => {
            if(!input.value.length && input.required !== false){
                this.state.inputs[input.name].error = true
                errors++;
            }else if(input.name == 'email' && !this.valid_email(input.value)) {
                this.state.inputs[input.name].error = true
                errors++;
            }else if(input.name == 'phone' && !this.valid_phone(input.value)) {
                this.state.inputs[input.name].error = true
                errors++;
            }
        })
        this.setState({date: new Date()})
        if(!errors){
            this.Api.registrationUser(inputs, user, token)
                .then(res => {
                    if(res.status == 200){
                        showLoginClick('infoRegistration')
                        this.props.getUserData()
                    }else{
                        res.errorsUser && Object.keys(res.errorsUser).map(key => {
                            // eslint-disable-next-line
                            this.state.inputs[key].error = true;
                            // eslint-disable-next-line
                            this.state.inputs[key].errorText = res.errorsUser[key];
                            this.setState({errors: new Date()})
                            return false
                        })
                    }
                })
        }else{
            this.setState({errorGlobal: true})
        }
    }

    valid_email = (el) => {
        let reg = /^[a-z-._0-9]+@[a-z-_0-9]+\.[a-z0-9]{2,10}$/i;
        return reg.test(el)
    }
    valid_phone = (el) => {
        var reg = /^\+\d[\d\(\)\ -]{4,14}\d$/;
        return reg.test(el)
    }

    render() {
        const {
            inputs,
            errorGlobal
        } = this.state
        const {showLoginClick, user} = this.props
        return (
            <div className="auth">
                <div className="login__head">Регистрация</div>
                <div className="login__flex">
                    {Object.values(inputs).map(input => {
                        if(input.type == 'checkbox'){
                            return (
                                <div className={'inputBlock radio w100p '}>
                                    <div className={'radio__el ' + (input.error && 'error')}>
                                        <input
                                            id={input.name + 'for'}
                                            type={input.type}
                                            className={'input'}
                                            name={input.name}
                                            onChange={() => {this.updateState(input.name, 'male')}}
                                        />
                                        <label htmlFor={input.name + 'for'}>{input.label}</label>
                                    </div>
                                </div>
                            )
                        }
                        if(input.type == 'select'){
                            return (
                                <div className={'inputBlock'}>
                                    <label htmlFor={input.name + 'for'}>{input.label}</label>
                                    <div
                                        id={input.name + 'for'}
                                        className={'input ' + (input.error && 'error')}
                                        onClick={()=>{
                                            this.state.inputs.district.showOptions = true
                                            this.setState({date: new Date()})
                                        }}
                                    >{input.value}</div>
                                    {input.showOptions &&
                                        <div className="options">
                                            {Object.keys(input.options).map(id => {
                                                return (
                                                    <div className="option__el" onClick={() => {
                                                        this.state.inputs.district.value = input.options[id]
                                                        this.state.inputs.district.showOptions = false
                                                        this.state.inputs.district_id.value = id
                                                        this.setState({date: new Date()})
                                                    }}>
                                                        {input.options[id]}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                    <div className="hidden"></div>
                                </div>
                            )
                        }else if(input.type !== 'hidden') {
                            return (
                                <div className={'inputBlock'}>
                                    <label htmlFor={input.name + 'for'}>{input.label}</label>
                                    <InputMask
                                        autocomplete={'new-' + input.name}
                                        readonly={input.readOnly}
                                        onfocus={() => {
                                            this.state.inputs[input.name].readOnly = false;
                                            this.setState({data: new Date()})
                                        }}
                                        id={input.name + 'for'}
                                        type={input.type}
                                        className={'input ' + (input.error && 'error')}
                                        name={input.name}
                                        value={input.value}
                                        onChange={(e) => {
                                            if(input.name == 'region'){
                                                this.getRegions(e.target.value)
                                            }
                                            if(input.name == 'city'){
                                                this.getCity(e.target.value)
                                            }
                                            this.updateState(input.name, e.target.value)
                                        }}
                                        disabled={input.disabled}
                                        mask={input.mask}
                                    />
                                    {input.name == "region" && this.state.region !== undefined &&
                                        <div className="options">
                                        {this.state.region.map(region => {
                                            if(region['name'] != 'Бесплатная версия kladr-api.ru') {
                                                return (
                                                    <div className="option__el"
                                                         onClick={()=> {
                                                             this.state.inputs.region.value = region['name'];
                                                             this.setState({region: undefined})
                                                         }}
                                                    >
                                                        {region['name']}
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                    }
                                    {input.name == "city" && this.state.city !== undefined &&
                                    <div className="options">
                                        {this.state.city.map(city => {
                                            if(city['name'] != 'Бесплатная версия kladr-api.ru') {
                                                return (
                                                    <div className="option__el"
                                                         onClick={()=> {
                                                             this.state.inputs.city.value = city['name'];
                                                             this.setState({city: undefined})
                                                         }}
                                                    >
                                                        {city['name']}
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                    }

                                    {input.errorText &&
                                        <div className="errorText">
                                            {input.errorText}
                                        </div>
                                    }
                                    <div className="hidden"></div>
                                </div>
                            )
                        }
                    })}
                </div>
                <div className="reLink">
                    <div className="reLink__el">

                    </div>
                    <div className="reLink__el" onClick={()=>showLoginClick('auth')}>
                        Авторизация
                    </div>
                </div>
                <div className="buttons">
                    {errorGlobal &&
                    <div className="errorTextGlobal">
                        Не все поля заполнены правильно, вернитесь к форме
                    </div>
                    }
                    <button
                        type='button'
                        className="button button__yellow"
                        onClick={() => {
                            this.sendDate()
                        }}
                    >
                        Зарегистрироваться
                    </button>
                </div>
            </div>
        )
    }


}