import React from "react";
import { isMobileOnly } from "react-device-detect";
import Dots from "../dotsMain/Dots";

const ProjectScreenTitle = ({ color, content, isMobileBorder }) => (
  <div
    style={
      isMobileOnly && !isMobileBorder
        ? { borderBottom: `1px solid ${color}` }
        : { border: `1px solid ${color}` }
    }
    className={`project-screen-title ${
      isMobileOnly && !isMobileBorder ? "project-screen-title--no_border" : ""
    }`}
  >
    <Dots color={color} />
    <h2 style={{ color }} className="project-screen-title__text">
      {content}
    </h2>
  </div>
);

export default ProjectScreenTitle;
