import React from "react";
import slide01 from "./images/slide-01.bde990d.png";
import slide02 from "./images/slide-02.48e245d.png";
import slide03 from "./images/slide-03.db13935.png";
import slide04 from "./images/slide-04.1b650f8.png";
import slide05 from "./images/slide-05.0b0d49c.png";
import slide06 from "./images/slide-06.c7853c6.png";
import slide07 from "./images/slide-07.7c1c273.png";
import slide08 from "./images/slide-08.3bbd701.png";
import slide09 from "./images/slide-09.1d3766f.png";
import slide10 from "./images/slide-10.b398852.png";
import slide11 from "./images/slide-11.fda8650.png";
import slide12 from "./images/slide-12.8fb4c79.png";
import slide13 from "./images/slide-13.0682cc8.png";
import slide14 from "./images/slide-14.99ce3be.png";
import slide15 from "./images/slide-15.7bbf5be.png";
import slide16 from "./images/slide-16.c65106d.png";
import slide17 from "./images/slide-17.d84d04c.png";
import slide18 from "./images/slide-18.a181eae.png";
import slide19 from "./images/slide-19.be3083a.png";
import slide20 from "./images/slide-20.3110294.png";
import slide21 from "./images/slide-21.b7bc4e4.png";
import slide22 from "./images/slide-22.4ceb85c.png";
import slide23 from "./images/slide-23.94b4f30.png";
import slide24 from "./images/slide-24.dd3bcd2.png";
import slide25 from "./images/slide-25.4b864ca.png";
import slide26 from "./images/slide-26.d7b1629.png";


const FoundersData = [
  {
    img: slide01,
  },
  {
    img: slide02,
  },
  {
    img: slide03,
  },
  {
    img: slide04,
  },
  {
    img: slide05,
  },
  {
    img: slide06,
  },
  {
    img: slide07,
  },
  {
    img: slide08,
  },
  {
    img: slide09,
  },
  {
    img: slide10,
  },
  {
    img: slide11,
  },
  {
    img: slide12,
  },
  {
    img: slide13,
  },
  {
    img: slide14,
  },
  {
    img: slide15,
  },
  {
    img: slide16,
  },
  {
    img: slide17,
  },
  {
    img: slide18,
  },
  {
    img: slide19,
  },
  {
    img: slide20,
  },
  {
    img: slide21,
  },
  {
    img: slide22,
  },
  {
    img: slide23,
  },
  {
    img: slide24,
  },
  {
    img: slide25,
  },
  {
    img: slide26,
  },
];

export default FoundersData;
