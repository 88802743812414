import React from 'react';
import { withRouter } from 'react-router-dom';
import Main from 'Navigation/main';
import 'assets/sass/main.sass'
import Api from 'Service/Api'

import ChatBot from "Components/ChatBot";
import TechWorks from 'images/tech-works.png'
class App extends React.Component {
    state = {
        isGuest: undefined,
        showGuru: true,
        user: undefined,
        updateChat: false,
    };
    Api = new Api()

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        // return (
        //     <div style={{
        //         display: 'flex',
        //         justifyContent: 'center',
        //         alignItems: 'center',
        //         height: '100vh',
        //         flexDirection: 'column'
        //     }}>
        //         <div style={{fontWeight: 700, fontSize: '1rem'}}>Ведутся технические работы</div>
        //         <img style={{width: 266}} src={TechWorks} alt=""/>
        //     </div>
        // )
        return (
            <div className="wrp">
                <Main/>
                {<ChatBot/>}
            </div>
        )
    }
}

export default withRouter(App);

