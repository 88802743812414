import React, {useState, useEffect} from 'react';
import Header from "../../pageComponents/headerMain/Header";
import Footer from "../../pageComponents/footerMain/Footer";
import Api from "../../Service/NewApi";
import OldApi from '../../Service/Api'
import {Link} from "react-router-dom";

const Lessons = () => {
    const api = new Api();
    const oldApi = new OldApi()
    const courseId = 'c' + window.location.pathname.slice(9,)
    const [lessonsList, setLessonsList] = useState({})
    // const [testList, setTestList] = useState({})
    const [lessonsListIncompleted, setLessonsListIncompleted] = useState({})
    const [lessonsListCompleted, setLessonsListCompleted] = useState({})
    // const [testListIncompleted, setTestListIncompleted] = useState({})
    // const [testListCompleted, setTestListCompleted] = useState({})
    const [tabs, setTabs] = useState([true, false, false])
    const [user, setUser] = useState(undefined)
    const [inComplete, setInComplete] = useState(false)
    let completedLessonIndex = null;
    let lessonsListInComplete = false
    let lessonsListIncompletedInComplete = false
    let lessonsListCompletedInComplete = false
    const lessons = Object.values(lessonsList.lessons || []);

    const getLessons = () => {
        api.getCoursesLesson()
            .then(res => {
                res.coursesLessons[courseId].lesson_by_lesson === 1 ? setInComplete(true) : setInComplete(false)
                setLessonsList(res.coursesLessons[courseId])
            })
            .catch(e => console.log('error', e))
    }

    // const getTests = () => {
    //     api.getCoursesTests()
    //         .then(res => {
    //             setTestList(res.coursesTests[courseId])
    //         })
    //         .catch(res => console.log(res))
    // }

    const tabsHandler = (e) => {
        let type = e.target.getAttribute('data-type')
        changeLessonsList(type)
        let tabsCollection = [...document.querySelectorAll('.lessons__tab')]
        tabsCollection.map((tab, index) => tab.classList.remove('active'))
        e.target.classList.add('active')
    }

    const changeLessonsList = (type) => {
        switch (type) {
            case 'all':
                getLessons()
                setTabs([true, false, false])
                break;
            case 'next':
                let lessonsNotCompleted = lessons.filter(lesson => lesson.progress !== 100)
                // let testNotCompleted = Object.values(testList.tests).filter(test => test.progress !== 100)
                setLessonsListIncompleted(lessonsNotCompleted)
                // setTestListIncompleted(testNotCompleted)
                setTabs([false, true, false])
                break;
            case 'complete':
                let lessonsCompleted = lessons.filter(lesson => lesson.progress === 100)
                // let testCompleted = Object.values(testList.tests).filter(test => test.progress === 100)
                setLessonsListCompleted(lessonsCompleted)
                // setTestListCompleted(testCompleted)
                setTabs([false, false, true])
                break;
            default:
                console.log('Data attribute no found!')
        }
    }

    const getUserData = () => {
        oldApi.getUserData()
            .then(user => {
                if(!user) this.props.history.push('/');
                setUser(user);
            })
            .catch(e => console.log('error', e))
    }

    useEffect(() => {
        getUserData()
        getLessons()
    },[])

    console.log(inComplete)

    return (
        <div className="lessons page">
            <Header user={user}/>
            <div className="cnt">
                <Link className="lessons__back" to={'/training'}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 15 15" fill="none">
                            <rect width="15" height="15" rx="7.5" fill="#DE3A34"/>
                            <path d="M10 7.29167H5M5 7.29167C5 7 6.93477 6.17689 7.22222 5M5 7.29167C5 7.5 6.881 8.24896 7.22222 9.51389" stroke="white" strokeLinecap="round"/>
                        </svg>
                        <p>Назад к списку курсов</p>
                    </div>
                </Link>
                <h2 className="lessons__header">{lessonsList.name}</h2>
                <p className="lessons__description">{lessonsList.description}</p>
                <div className="lessons__tabs">
                    <div className="lessons__tab" onClick={(e) => tabsHandler(e)} data-type="all">Все уроки</div>
                    <div className="lessons__tab" onClick={(e) => tabsHandler(e)} data-type="next">На очереди</div>
                    <div className="lessons__tab" onClick={(e) => tabsHandler(e)} data-type="complete">Завершенные</div>
                </div>
                <div className="lessons__container">
                    {tabs[0] &&
                        <div className="lessons__tab-container">
                            {Object.keys(lessonsList).length > 0 ?
                                lessons.map((lesson, index) => {
                                    if (lesson.progress === 100) {
                                        completedLessonIndex = index
                                    }
                                    if (completedLessonIndex !== null) {
                                        index > (completedLessonIndex + 1) && index !== 0 && (lessonsListInComplete = true)
                                    }

                                    if (lesson.available) {
                                        return(
                                            <div className={"lessonsCard " + (((lessonsListInComplete && inComplete) || inComplete &&
                                                (index === 0 ? false : lessons[index - 1].progress < 100)) && 'no-active')} key={index}>
                                                <h2 className="lessonsCard__title">{lesson.name}</h2>
                                                <p className="lessonsCard__description">{lesson.info_text}</p>
                                                <Link to={'/lesson/' + lesson.id} className={"lessonsCard__status " + (lesson.progress === 100 ? 'grey' : 'red')}>
                                                    {lesson.progress === 100 ? 'Пройти заново' : lesson.button_name ? lesson.button_name : 'Начать урок'}
                                                </Link>
                                            </div>
                                        )
                                    }
                                }) :
                                <div></div>
                            }
                        </div>
                    }
                    {tabs[1] &&
                        <div className="lessons__tab-container">
                            {Object.keys(lessonsListIncompleted).length > 0 ?
                                Object.values(lessonsListIncompleted).map((lesson, index) => {
                                    if (lesson.progress === 100) {
                                        completedLessonIndex = index
                                    }
                                    if (completedLessonIndex !== null) {
                                        index > (completedLessonIndex + 1) && index !== 0 && (lessonsListIncompletedInComplete = true)
                                    }
                                    if (lesson.available) {
                                        return(
                                            <div className={"lessonsCard " + ((lessonsListIncompletedInComplete && inComplete) || (index === 0 ? false : Object.values(lessonsListIncompleted || [])[index - 1].progress !== 100) && 'no-active')} key={index}>
                                                <h2 className="lessonsCard__title">{lesson.name}</h2>
                                                <p className="lessonsCard__description">{lesson.info_text}</p>
                                                <Link to={'/lesson/' + lesson.id} className={"lessonsCard__status " + (lesson.progress === 100 ? 'grey' : 'red')}>
                                                    {lesson.progress === 100 ? 'Пройти заново' : lesson.button_name ? lesson.button_name : 'Начать урок'}
                                                </Link>
                                            </div>
                                        )
                                    }
                                }) :
                                <div></div>
                            }
                        </div>
                    }
                    {tabs[2] &&
                        <div className="lessons__tab-container">
                            {Object.keys(lessonsListCompleted).length > 0 ?
                                Object.values(lessonsListCompleted).map((lesson, index) => {
                                    if (lesson.progress === 100) {
                                        completedLessonIndex = index
                                    }
                                    if (completedLessonIndex !== null) {
                                        index > (completedLessonIndex + 1) && index !== 0 && (lessonsListCompletedInComplete = true)
                                    }
                                    if (lesson.available) {
                                        return(
                                            <div className={"lessonsCard " + (lessonsListCompletedInComplete && inComplete && 'no-active')} key={index}>
                                                <h2 className="lessonsCard__title">{lesson.name}</h2>
                                                <p className="lessonsCard__description">{lesson.info_text}</p>
                                                <Link to={'/lesson/' + lesson.id} className={"lessonsCard__status " + (lesson.progress === 100 ? 'grey' : 'red')}>
                                                    {lesson.progress === 100 ? 'Пройти заново' : lesson.button_name ? lesson.button_name : 'Начать урок'}
                                                </Link>
                                            </div>
                                        )
                                    }
                                }) :
                                <div></div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default Lessons
