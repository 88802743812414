import React from "react";

// images
import titleScreenImg from "../../../images/project-screens/student-ticket/bg/title-screen.png";
import textScreenHeaderImg from "../../../images/lesson.svg";
import textScreenImg from "../../../images/project-screens/student-ticket/bg/text-screen.png";

// partners
import partner_1 from "../../../images/project-screens/student-ticket/partners/MISIS.png";
import partner_2 from "../../../images/project-screens/student-ticket/partners/RANKHIGS.png";
import partner_3 from "../../../images/project-screens/student-ticket/partners/RNIMU.png";
import partner_6 from "../../../images/project-screens/student-ticket/partners/FEFU.png";
import partner_7 from "../../../images/project-screens/student-ticket/partners/ITMO.png";
import partner_4 from "../../../images/project-screens/student-ticket/partners/MGTU.png";
import partner_5 from "../../../images/project-screens/student-ticket/partners/MIFI.png";

const studentTicketData = {
  titleScreen: {
    header: "Студенческий билет",
    title: "Куда поступать?",
    content: "Как выбрать «свой» вуз",
    bg: titleScreenImg,
  },
  bulletScreen: {
    title: "О чём этот курс?",
    info_items: [
      <span>8 вузов / лучшие образовательные учреждения страны</span>,
      <span>24 личные истории о вузе, работе и нетворкинге</span>,
      <span>500+ специальностей и профессий</span>,
      <span>1 вуз, который подходит именно тебе</span>,
    ],
  },
  textScreen: {
    title: (
      <span>
        Приходи на «дистанционный
        <br />
        день открытых дверей»
      </span>
    ),
    header_img: textScreenHeaderImg,
    bg: textScreenImg,
    info_items: [
      <p>
        Приходи ни «дистанционный день открытых дверей» в ведущий вуз страны,
        где студенты и выпускники разных специальностей расскажут о своей
        альма-матер, инновационному подходу к образованию, особенностях научной
        деятельности и студенческой жизни.
      </p>,
    ],
  },
  partnersScreen: {
    title: "Партнеры",
    logos: [
      { img: partner_1 },
      { img: partner_2 },
      { img: partner_3 },
      { img: partner_4 },
      { img: partner_5 },
      { img: partner_6 },
      { img: partner_7 },
    ],
  },
};

export default studentTicketData;
