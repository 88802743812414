import React from "react";
// import ScrollableAnchor from "react-scrollable-anchor";
import Dots from "../dotsMain/Dots";
import FoundersSlider from "../foundersSliderMain/FoundersSlider";
import FoundersData from "./FoundersData";

const ScreenEight = () => {
  return (
    // <ScrollableAnchor id="experts">
      <section className="screen-six" id="experts">
        <div className="screen-six__content">
          <div className="screen-six__title">
            <Dots color="#5278ff" />
            <h2 className="screen-six__title-text">Учредители</h2>
          </div>
          <FoundersSlider type="persons" data={FoundersData} />
        </div>
      </section>
    // </ScrollableAnchor>
  );
};

export default ScreenEight;
