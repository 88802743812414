import React, {useEffect, useState} from "react";
import Footer from "../../../pageComponents/footerMain/Footer";
import Header from "../../../pageComponents/headerMain/Header";
import {
  BulletScreen,
  BulletVerticalScreen,
  ExpertsScreen,
  PartnersScreen,
  TitleScreen,
} from "../../../pageComponents/projectScreens/projectScreens";
import podcastData from "./podcastData";
import Api from "../../../Service/Api";

const Podcast = () => {
    const [user, setUser] = useState()
    const [showLogin, setShowLogin] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        new Api().getUserData()
            .then(user => setUser(user))
            .catch(e => console.log('error', e))
    }, []);

    const showLoginClick = (showLogin) => {
        setShowLogin(showLogin)
    }

    const getUserData = () => {
        new Api().getUserData()
            .then(user => setUser(user))
            .catch(e => console.log('error', e))
    }

  return (
    <main className="project-page">
        <Header
            user={user}
            showLogin={showLogin}
            showLoginClick={showLoginClick}
            getUserData={getUserData}
        />
        <TitleScreen data={podcastData.titleScreen} user={user} showLoginClick={showLoginClick} />
      <BulletVerticalScreen data={podcastData.bulletVerticalScreen} />
      <BulletScreen info_margin="80px 85px 0" data={podcastData.bulletScreen} />
      <ExpertsScreen data={podcastData.expertsScreen} />
      <PartnersScreen color="#5278FF" data={podcastData.partnersScreen} />
      <Footer user={user}/>
    </main>
  );
};

export default Podcast;
