import React from "react";
import Dots from "../dotsMain/Dots";


const CountBlock = ({ color, count }) => (
  <div style={{ color, border: `1px solid ${color}` }} className="count-block">
    <Dots color={color} />
    {count}
  </div>
);

export default CountBlock;
