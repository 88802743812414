import React from "react";
import './preloader.scss';

const Preloader = ({isActive}) => {
    return (
        <div className={'preloader ' + (!isActive && 'loaded')}>
            <div className="preloader__view">Загрузка...</div>
        </div>
    )
}

export default Preloader