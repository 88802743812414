import React from 'react'
import Api from '../../Service/Api'
import Header from '../../pageComponents/headerMain/Header'
import Screen_One from '../../pageComponents/screenOneMain/ScreenOne'
import Screen_Two from '../../pageComponents/screenTwoMain/ScreenTwo'
import Screen_Three from '../../pageComponents/screenThreeMain/ScreenThree'
import Screen_Four from '../../pageComponents/screenFourMain/ScreenFour'
import Screen_Five from '../../pageComponents/screenFiveMain/ScreenFive'
import Screen_Six from '../../pageComponents/screenSixMain/ScreenSix'
import Screen_Seven from '../../pageComponents/screenSevenMain/ScreenSeven'
import Screen_Eight from '../../pageComponents/screenEightMain/ScreenEight'
// import { configureAnchors } from "react-scrollable-anchor";
import Footer from '../../pageComponents/footerMain/Footer'

// configureAnchors({offset: -60, scrollDuration: 1000})

export default class Main extends React.Component {
    Api = new Api()

    state = {
        user: undefined,
        showLogin: false
    }

    componentDidMount() {
        this.getUserData();
    }

    getUserData = () => {
        this.Api.getUserData()
            .then(user => this.setState({user}))
            .catch(e => console.log('error', e))
    }

    showLoginClick = (showLogin) => {
        this.setState({showLogin})
    }

    render() {
        const {user, showLogin} = this.state;
        return (
            <div className="main__page page">
                <Header
                    user={user}
                    getUserData={this.getUserData}
                    showLogin={showLogin}
                    showLoginClick={this.showLoginClick}
                />
                {/*<Screen_One*/}
                {/*    showLoginClick={this.showLoginClick}*/}
                {/*    training__btn={!user}*/}
                {/*/>*/}
                {/*<Screen_Two*/}
                {/*    showLoginClick={this.showLoginClick}*/}
                {/*    training__btn={!user}*/}
                {/*/>*/}
                {/*<Screen_Three/>*/}
                <Screen_Seven/>
                {/*<Screen_Four/>*/}
                {/*<Screen_Five/>*/}
                {/*<Screen_Six/>*/}
                <Screen_Eight/>
                <Footer user={user}/>
            </div>
        )
    }
}
