import React, {useEffect, useState} from "react";
import Footer from "../../../pageComponents/footerMain/Footer";
import Header from "../../../pageComponents/headerMain/Header";
import {
  BulletExtendedScreen,
  BulletScreen,
  BulletVerticalScreen,
  ExpertsScreen,
  FeaturesScreen,
  PartnersScreen,
  TextBigScreen,
  TextScreen,
  TitleScreen,
} from "../../../pageComponents/projectScreens/projectScreens";
import digitalMuseumData from "./digitalMuseumData";
import { isMobileOnly } from "react-device-detect";
import Dots from "../../../pageComponents/dotsMain/Dots";
import Api from "../../../Service/Api";

const DigitalMuseum = () => {

    const [user, setUser] = useState()
    const [showLogin, setShowLogin] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  });

    useEffect(() => {
        new Api().getUserData()
            .then(user => setUser(user))
            .catch(e => console.log('error', e))
    }, []);

    const showLoginClick = (showLogin) => {
        setShowLogin(showLogin)
    }

    const getUserData = () => {
        new Api().getUserData()
            .then(user => setUser(user))
            .catch(e => console.log('error', e))
    }

  return (
    <main className="project-page">
        <Header
            user={user}
            showLogin={showLogin}
            showLoginClick={showLoginClick}
            getUserData={getUserData}
        />
        <TitleScreen data={digitalMuseumData.titleScreen} user={user} showLoginClick={showLoginClick} />
      <BulletScreen
        data={digitalMuseumData.bulletScreen}
        info_margin="80px 150px 0"
      />
      <TextScreen data={digitalMuseumData.textScreen} user={user} showLoginClick={showLoginClick}/>
      <FeaturesScreen data={digitalMuseumData.featuresScreen} />
      <BulletScreen
        data={digitalMuseumData.bulletScreenTwo}
        info_margin="80px 85px 0"
      />
      <BulletVerticalScreen data={digitalMuseumData.bulletVerticalScreen} />
      <ExpertsScreen data={digitalMuseumData.expertsScreen} />
      <BulletExtendedScreen data={digitalMuseumData.bulletExtendedScreen} />
      <TextBigScreen data={digitalMuseumData.textBigScreen} />
      <div className="project-info-block">
        <div className="project-info-block__title">
          {isMobileOnly && <Dots color="#5278FF" />}Остались вопросы?
        </div>
        <p className="project-info-block__subtitle">
          Напишите на{" "}
          <a href="mailto:pf@kruzhok.org" className="project-info-block__link">
            pf@kruzhok.org
          </a>
        </p>
      </div>
      <PartnersScreen color="#5278FF" data={digitalMuseumData.coorgScreen} />
      <PartnersScreen color="#FF5252" data={digitalMuseumData.partnersScreen} />
      <Footer user={user}/>
    </main>
  );
};

export default DigitalMuseum;
