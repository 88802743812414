import React from "react";
import CountBlock from "../../countBlock/CountBlock";
import ProjectScreenHeader from "../../projectScreenHeaderMain/ProjectScreenHeader";

const BulletVerticalScreen = ({ data }) => {
  return (
    <section className="bullet-vertical-screen">
      <div className="bullet-vertical-screen__content">
        <ProjectScreenHeader
          title={data.title}
          img={data.header_img}
          bg={data.bg}
        />
        <div className="bullet-vertical-screen__info">
          {data.info_items.map((item, index) => (
            <div
              key={`bullet-vertical-screen-item-${index}`}
              className="bullet-vertical-screen__info-item"
            >
              <CountBlock color="#5AFFF5" count={index + 1} />
              <p className="bullet-vertical-screen__info-item-text">{item}</p>
            </div>
          ))}
        </div>
        {data.description && (
          <p className="bullet-vertical-screen__description">
            {data.description}
          </p>
        )}
      </div>
    </section>
  );
};

export default BulletVerticalScreen;
