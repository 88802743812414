import React, {useEffect, useState} from "react";
import Footer from "../../../pageComponents/footerMain/Footer";
import Header from "../../../pageComponents/headerMain/Header";
import {
  BulletScreen,
  PartnersScreen,
  TextScreen,
  TitleScreen,
} from "../../../pageComponents/projectScreens/projectScreens";
import studentTicketData from "./studentTicketData";
import Api from "../../../Service/Api";

const StudentTicket = () => {
    const [user, setUser] = useState()
    const [showLogin, setShowLogin] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        new Api().getUserData()
            .then(user => setUser(user))
            .catch(e => console.log('error', e))
    }, []);

    const showLoginClick = (showLogin) => {
        setShowLogin(showLogin)
    }

    const getUserData = () => {
        new Api().getUserData()
            .then(user => setUser(user))
            .catch(e => console.log('error', e))
    }

  return (
    <main className="project-page">
        <Header
            user={user}
            showLogin={showLogin}
            showLoginClick={showLoginClick}
            getUserData={getUserData}
        />
        <TitleScreen data={studentTicketData.titleScreen} user={user} showLoginClick={showLoginClick} />
      <BulletScreen
        info_margin="80px 85px 0"
        data={studentTicketData.bulletScreen}
      />
      <TextScreen data={studentTicketData.textScreen} user={user} showLoginClick={showLoginClick} />
      <PartnersScreen color="#5278FF" data={studentTicketData.partnersScreen} />
      <Footer user={user}/>
    </main>
  );
};

export default StudentTicket;
