import React from "react";
import { Link } from "react-router-dom";

export default ({showLoginClick}) => {
    return (
        <div className="registration">
            <div className="cnt">
                <div onClick={() => showLoginClick('registration')}
                    className={'button button__yellow button__big button__registration'}
                >Регистрация</div>
                {/*<div className="registration__text">*/}
                {/*    Нажимая на кнопку, вы даете согласие на обработку персональных<br/>*/}
                {/*    данных и соглашаетесь c политикой конфиденциальности*/}
                {/*</div>*/}
            </div>
        </div>
    )
}
