import React from "react";
import Api from "../../Service/Api";
import NewApi from "../../Service/NewApi";
import Header from "../../pageComponents/headerMain/Header";
import Footer from "../../pageComponents/footerMain/Footer";
import LessonTypeText from "../../Components/lessonSteps/typeText";
import LessonTypeVideo from "../../Components/lessonSteps/typeVideo";
import LessonInteractiveVideo from "../../Components/lessonSteps/typeInteractiveVideo";
import LessonInteractive from "../../Components/lessonSteps/typeInteractive";
import _, { map } from 'underscore';
import QuestionCorrect from "../../Components/LessonAndTestComponents/QuestionCorrect";
import TrainingMenu from "../../pageComponents/trainingMenu";
import AnswersBlock from "../../Components/LessonAndTestComponents/AnswersBlock";
import TextBlock from "../../Components/LessonAndTestComponents/TextBlock";
import MainButton from "../../Components/LessonAndTestComponents/MainButton";
import InfoBlock from "../../Components/LessonAndTestComponents/InfoBlock";
import MainHeader from "../../Components/LessonAndTestComponents/MainHeader";
import PopupInStep from "../../Components/LessonAndTestComponents/PopupInStep"

export default class Lesson extends React.Component {

    Api = new Api()
    NewApi = new NewApi()

    state = {
        user: undefined,
        lesson_step: undefined,
        answer: '',
        ext: undefined,
        equal: undefined,
        showCorrect: false,
        showErrorBlock: false,
        progress: 0,

        //Выведение тестов между шагами
        typeMainBtn: 'check answer',
        showStep: true,
        showNextTestStep: false,
        questionsId: [],
        activeQuestion: {},
        compareAnswer: {},
        answerActive: [],
        questionCorrect: '',
        headCorrect: 'Ваш ответ принят',
        textCorrect: '',
        disabled: false,
        showQuestionCorrect: false,
        indexActiveQuestion: 0,
        count: 1,
        tests: {},
        checkTest: false,

        //Таймер
        seconds: new Date().getTime(),
        popup: false,
    }

    componentDidMount() {
        this.getUserData();
        this.getLessonData(this.props.match.params.id, undefined);
        this.getTests();
    }

    getTests = () => {
        this.NewApi.getCoursesTests()
            .then(res => {
                this.setState({tests: res})
            })
            .catch(res => console.log(res))
    }

    getUserData = () => {
        this.Api.getUserData()
            .then(user => this.setState({user}))
            .catch(e => console.log('error', e))
    }

    setAnswerProps = (answer, ext, checked, matchesOut, showErrorBlock) => {
        this.setState({answer, showErrorBlock: false})
        this.state.ext = ext;
        this.state.equal = _.isEqual(checked, matchesOut);
    }

    _renderStep = (type) => {
        const {lesson_step, user, answer} = this.state;
        switch (type) {
            case 1: {
                return <LessonTypeText content={lesson_step.content}/>
            }
            case 2: {
                return <LessonTypeVideo content={lesson_step.content}/>
            }
            case 3: {
                if(user) {
                    return <LessonInteractiveVideo
                        content={lesson_step.content}
                        attributes={lesson_step}
                        user={this.state.user}
                    />
                }
                return <div/>
            }
            case 4: {
                return <LessonInteractive
                    answer={answer}
                    content={lesson_step.content}
                    matches={lesson_step.matches}
                    stepId={lesson_step.id}
                    setAnswerProps={this.setAnswerProps}
                />
            }
        }
    }

    //Работа тестов между шагами
    collectingQuestion = (questions) => {
        const questionsId = Object.keys(questions)
        const questionsData = Object.values(questions)

        this.setState({questionsId: questionsId, activeQuestion: questionsData[0]})
    }

    setAnswer = id => {
        const {answerActive} = this.state;
        answerActive.indexOf(id) > -1 ?
            delete answerActive[answerActive.indexOf(id)]
            :
            answerActive.push(id)
        this.setState({answerActive})
    }

    checkAnswer = () => {
        const {activeQuestion, answerActive, indexActiveQuestion} = this.state
        // const {lesson} = this.props
        this.setState({disabled: true})
        let answers = [];
        // eslint-disable-next-line
        answerActive.map(item => {
            answers.push(item)
        })

        if(answers.length) {
            this.NewApi.setLessonTestAnswer(activeQuestion.id, answers)
                .then((res) => {
                    if (res.status === 200) {
                        this.setState({
                            disabled: false,
                            compareAnswer: res.correctAnswers,
                            questionCorrect: res.userResult !== 'error' ? 'correct' : 'inCorrect',
                            typeMainBtn: 'next question',
                            showQuestionCorrect: true,
                            headCorrect: res.userResult !== 'error' ? 'все верно!' : 'К сожалению, ваш ответ неверен.',
                            indexActiveQuestion: indexActiveQuestion + 1
                        })
                    }
                })
        }else{
            this.setState({disabled: false})
        }

    }

    getLessonData = (lesson_id) => {
        const {progress} = this.state

        if (progress !== 100) {
            this.NewApi.getNextLessonStep(lesson_id)
                .then((lesson_step) => {
                    console.log()
                    if (lesson_step !== null) {
                        if (lesson_step.test !== undefined) {
                            this.setState({showNextTestStep: true})
                        }
                        this.setState({
                            lesson_step,
                            answer: '',
                            ext: undefined,
                            equal: undefined,
                            free: undefined,
                            showCorrect: false,
                            showStep: true,
                        })
                    } else {
                        this.props.history.goBack()
                    }
                })
        } else {
            this.props.history.goBack()
        }

    }

    checkTestIs = () => {
        let lessonId = Number(window.location.pathname.slice(8, ))
        Object.values(this.state.tests.coursesTests).map(test => {
            return(
                Object.values(test.tests).map(elem => {
                    console.log(elem.lesson_id, elem.progress)
                    if(elem.lesson_id === lessonId && elem.progress !== 100){
                        this.setState({checkTest: true})
                    }
                })
            )
        })
    }

    setLessonData = (lesson_step) => {
        const {answer, ext, equal, showCorrect, showNextTestStep, showErrorBlock} = this.state
        if (lesson_step.type !== 4) {
            this.NewApi.setLessonStep(lesson_step.id, undefined, undefined, undefined, this.timer())
                .then(res => {
                    if (res.status === 200  && res.progress === 100 && lesson_step.test === undefined) {
                        if(this.state.checkTest === true){
                            let lessonId = window.location.pathname.slice(8, )
                            this.props.history.push(`/testOne/${lessonId}`)
                        }
                        else {
                            this.props.history.goBack()
                        }
                    } else if(res.status === 200 && res.not_finished === true) {
                        this.setState({popup: true})
                    } else {
                        this.setState({
                            answer: '',
                            ext: undefined,
                            equal: undefined,
                            free: undefined,
                            showCorrect: false,
                            progress: res.progress
                        })
                        if (showNextTestStep) {
                            this.setState({showStep: false})
                            this.collectingQuestion(lesson_step.test.questions)
                        } else {
                            this.setState({showStep: true})
                            this.getLessonData(lesson_step.lesson_id)
                        }
                    }
                    /*if(res.status === 200 && res.progress !== 100){
                        this.setState({
                            answer: '',
                            ext: undefined,
                            equal: undefined,
                            free: undefined,
                            showCorrect: false
                        })
                        if (showNextTestStep) {
                            this.setState({showStep: false})
                            this.collectingQuestion(lesson_step.test.questions)
                        } else {
                            this.setState({showStep: true})
                            this.getLessonData(lesson_step.lesson_id)
                        }
                    } else {
                        this.props.history.push('/training')
                    }*/
                })
        } else {
            if (answer.length === 0) { /*Проверяем заполненность ответа для интератктивного шага с типом свободный ответ и файл*/
                this.setState({showErrorBlock: true})
            } else if(equal !== undefined && showCorrect === false){
                this.setState({
                    showCorrect: true,
                })
                if(lesson_step.content.answer_type == 'free' || lesson_step.content.answer_type == 'upload'){
                    this.setState({free: true})
                }
            } else {
                this.NewApi.setLessonStep(lesson_step.id, lesson_step.content.answer_type, answer, ext)
                    .then(res => {
                        if (res.status === 200  && res.progress === 100 && lesson_step.test === undefined) {
                            if(this.state.checkTest === true){
                                let lessonId = window.location.pathname.slice(8, )
                                this.props.history.push(`/testOne/${lessonId}`)
                            }
                            else {
                                this.props.history.goBack()
                            }
                        } else {
                            this.setState({
                                answer: '',
                                ext: undefined,
                                equal: undefined,
                                free: undefined,
                                showCorrect: false
                            })
                            if (showNextTestStep) {
                                this.setState({showStep: false})
                                this.collectingQuestion(lesson_step.test.questions)
                            } else {
                                this.setState({showStep: true})
                                this.getLessonData(lesson_step.lesson_id)
                            }
                            // this.getLessonData(lesson_step.lesson_id)
                        }
                        /*if(res.status === 200 && res.progress !== 100){
                            this.setState({
                                answer: '',
                                ext: undefined,
                                equal: undefined,
                                free: undefined,
                                showCorrect: false
                            })
                            this.getLessonData(lesson_step.lesson_id)
                        } else {
                            this.props.history.push('/training')
                        }*/
                    })
            }
        }
    }

    nextQuestion = () => {
        const {lesson_step, indexActiveQuestion, questionsId, count} = this.state
        if (indexActiveQuestion === questionsId.length) {
            this.setState({
                showQuestionCorrect: false,
                typeMainBtn: 'check answer',
                answerActive: [],
                compareAnswer: {},
                showNextTestStep: false,
                indexActiveQuestion: 0,
                count: 1,
            })
            this.getLessonData(lesson_step.lesson_id)
        } else {
            this.setState({
                showQuestionCorrect: false,
                typeMainBtn: 'check answer',
                answerActive: [],
                compareAnswer: {},
                count: count + 1,
                activeQuestion: lesson_step.test.questions[questionsId[indexActiveQuestion]]
            })
        }
    }

    _renderTest = (question) => {
        console.log(question)
        const {compareAnswer, questionCorrect, headCorrect, textCorrect, showQuestionCorrect, typeMainBtn, disabled, count} = this.state;
        return (
            <div className="inner-test">
                <MainHeader
                    header=''
                    subheader=''
                    headerClass="bigHeader noBB"
                    questionName="question"
                    count={count}
                    answerCorrect={0}
                    questionCount={10}

                />

                <InfoBlock
                    text={question.multi_answer === 0 ? `Выберите вариант ответа и нажмите продолжить` : `Выберите несколько вариантов ответов и нажмите продолжить`}
                    hidden={showQuestionCorrect ? ' hidden' : ''}
                />

                <MainHeader
                    headerClass="MainHeader_mobile"
                    questionName="question"
                    count={count}
                    answerCorrect={0}
                    questionCount={10}
                    testHeadClass="testHeadMobile"

                />

                {/* Текст вопроса */}
                <TextBlock text={question.description}/>

                {/* Варианты ответов */}
                <AnswersBlock answers={question.answers} compareAnswers={compareAnswer} setAnswer={this.setAnswer}/>

                {showQuestionCorrect &&
                <QuestionCorrect
                    type={questionCorrect} // correct - верный, inCorrect - не выерный
                    head={headCorrect} // Заголовок блока
                    text={question.caption} // Текст блока
                />
                }

                <MainButton
                    type={typeMainBtn}
                    disabled={disabled}
                    checkAnswer={this.checkAnswer}
                    nextQuestion={this.nextQuestion}
                />
            </div>
        )
    }

    timer = () => {
        if(this.state.lesson_step && this.state.lesson_step.type === 2){
            let now = new Date().getTime()
            let duration = Math.round((now - this.state.seconds) / 1000)
            return duration
        }
        else {
            return null
        }
    }

    render() {
        const {user, lesson_step, showCorrect, equal, free, textCorrect, showStep, activeQuestion, showErrorBlock, popup} = this.state
        return (
            <div className="training__page page">
                {popup && <PopupInStep
                    changePoint = {() => {this.setState({popup: false})}}
                    closePopup = {() => {this.setState({popup: false})}}
                    popupText = 'Вы не просмотрели видео от начала до конца. Урок не пройден'
                    class='button button__orange'
                />}
                <Header className={'header__orange'} user={user} getUserData={this.getUserData}/>
                {lesson_step &&
                    <div className={'cnt'}>
                        <TrainingMenu/>
                        {showStep &&
                        <div className="step">
                            <div className="step__head">
                                {lesson_step.name}
                            </div>
                            {this._renderStep(lesson_step.type)}
                            {showCorrect &&
                            <QuestionCorrect
                                type={free ? 'neutral' : (equal ? 'correct': 'inCorrect')} // correct - верный, inCorrect - не выерный
                                head={free ? 'Спасибо за ответ' : (equal ? 'Все верно': 'К сожалению вы ошиблись')} // Заголовок блока
                                text={textCorrect} // Текст блока
                            />
                            }
                            {showErrorBlock &&
                            <div className={'training__error'}>Заполните ответ</div>
                            }
                            <div className="step__btn">
                                <button
                                    type={'button'}
                                    className="button button__orange"
                                    onClick={() => {
                                        this.setLessonData(lesson_step)
                                    }}
                                >
                                    Далее
                                </button>
                            </div>
                        </div>
                        }
                        {!showStep &&
                        <div>
                            {this._renderTest(activeQuestion)}
                        </div>
                        }
                    </div>
                }
                <Footer user={user}/>

            </div>
        )
    }
}
