import React from "react";

// images
import titleScreenImg from "../../../images/project-screens/education-system/bg/title-screen.png";
import textScreenHeaderImg from "../../../images/brain.png";
import textScreenImg from "../../../images/project-screens/education-system/bg/text-screen.png";

// experts
import asadova from "../../../images/project-screens/education-system/experts/asadova.png";
import chestina from "../../../images/project-screens/education-system/experts/chestina.png";
import danilyants from "../../../images/project-screens/education-system/experts/danilyants.png";
import korigodsky from "../../../images/project-screens/education-system/experts/korigodsky.png";
import prosokova from "../../../images/project-screens/education-system/experts/prosokova.png";
import sozontov from "../../../images/project-screens/education-system/experts/sozontov.png";
import sudakov from "../../../images/project-screens/education-system/experts/sudakov.png";
import zhabbarov from "../../../images/project-screens/education-system/experts/zhabbarov.png";

const educationSystemData = {
  titleScreen: {
    header: "Зачем мне это надо?",
    title: "О скучном нескучно",
    content:
      "Почему предмет, на который ты «забил», может тебе пригодиться в будущем",
    bg: titleScreenImg,
  },
  textScreen: {
    title: (
      <span>
        Почему мы изучаем алгебру,
        <br />
        математику и историю?
      </span>
    ),
    header_img: textScreenHeaderImg,
    bg: textScreenImg,
    info_items: [
      <p>
        За каждым школьным предметом стоит реальная область знаний и
        деятельности людей. Ничего из этого не придумано просто так, чтобы тебя
        помучить.
      </p>,
      <p>
        Так, за математикой стоят ответы на вопросы «почему» и «зачем» в мире
        алгоритмов и логики, за историей – мир глобальных процессов, которые
        генерируют люди, взаимодействуя между собой, за биологией – огромный
        спектр природных явлений. Чем больше таких принципов ты знаешь, тем
        более сложные вещи сам можешь создавать.
      </p>,
      <p className="text-screen__info-text--accent">
        Просто перестань зубрить и начни врубаться.
      </p>,
    ],
  },
  expertsScreen: {
    title: "Наши кураторы",
    experts: [
      {
        name: "Илья Созонтов",
        job: (
          <span>
            Основатель и директор
            <br />
            по развитию LUDI
          </span>
        ),
        img: sozontov,
      },
      {
        name: "Наргиз Асадова",
        job: (
          <span>
            политический обозреватель
            <br />
            журнала «Коммерсантъ
          </span>
        ),
        img: asadova,
      },
      {
        name: "Татьяна Честина",
        job: (
          <span>
            Руководитель
            <br />
            движения ЭКА
          </span>
        ),
        img: chestina,
      },
      {
        name: "Дмитрий Судаков",
        job: (
          <span>
            Руководитель проекта
            <br />
            "Атлас новых профессий"
          </span>
        ),
        img: sudakov,
      },
      {
        name: "Андрей Коригодский",
        job: (
          <span>
            Директор по технологиям
            <br />
            ООО «Коптер Экспресс
            <br />
            Технологии»,
          </span>
        ),
        img: korigodsky,
      },
      {
        name: "Татьяна Данильянц",
        job: (
          <span>
            кинорежиссёр,
            <br />
            фотограф и поэт
          </span>
        ),
        img: danilyants,
      },
      {
        name: "Тимур Жаббаров",
        job: (
          <span>
            Соучредитель
            <br />
            SmartCourse
          </span>
        ),
        img: zhabbarov,
      },
      {
        name: "Светлана Пороскова",
        job: (
          <span>
            Сертифицированный
            <br />
            карьерный консультант
          </span>
        ),
        img: prosokova,
      },
    ],
  },
};

export default educationSystemData;
