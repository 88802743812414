import React from "react";
import Auth from "./_auth";
import Forgot from "./_forgot";
import Registration from "./_registration";
import Info from "./_info";
import Api from "../../Service/Api";

export default class Login extends React.Component{
    
    Api = new Api();
    
    state = {
        user: false,
        token: false,
    }
    
    componentDidMount() {
        const {showLoginClick} = this.props
        let search = window.location.search.split('=')
        if(search[0] == '?token' ){
            this.Api.getUserDataToken(search[1])
                .then(user => {
                    if (user !== null) {
                        this.setState({
                            user,
                            token: search[1]
                        }, () => {
                            showLoginClick('registration')
                        })
                    }
                })
        }
    }

    _renderType = (type) => {
        const {showLoginClick, getUserData} = this.props
        const {user, token} = this.state
        switch (type) {
            case 'auth':
                return <Auth getUserData={getUserData} showLoginClick={showLoginClick}/>;
            case 'forgot':
                return <Forgot getUserData={getUserData} showLoginClick={showLoginClick}/>;
            case 'registration':
                return <Registration getUserData={getUserData} showLoginClick={showLoginClick} user={user} token={token}/>;
            case 'infoForgot':
                return <Info showLoginClick={showLoginClick} text={'На вашу электронную почту отправлено письмо с инструкцией по смене пароля'} btn_auth={true}/>;
            case 'infoRegistration':
                return <Info showLoginClick={showLoginClick} text={`Спасибо. <br/> Регистрация успешно завершена`} btn_auth={false}/>;

        }
    }

    render() {
        const {getUserData, showLogin, showLoginClick, user} = this.props
        if(showLogin) {
            return (
                <div className={'login'}>
                    <div className={"login__wrp " + showLogin}>
                        <div className="login__close" onClick={() => showLoginClick(false)}/>
                        {this._renderType(showLogin)}
                    </div>
                </div>
            )
        }
        return null
    }

}