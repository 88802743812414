import React from 'react'

import MainHeader from "../../Components/LessonAndTestComponents/MainHeader";
import TextBlock from "../../Components/LessonAndTestComponents/TextBlock";
import InfoBlock from "../../Components/LessonAndTestComponents/InfoBlock";
import FreeAnswersBlock from "../../Components/LessonAndTestComponents/FreeAnswersBlock";
import AnswersBlock from "../../Components/LessonAndTestComponents/AnswersBlock";
import AnswersDnd from "../../Components/LessonAndTestComponents/AnswersDnd";
import QuestionCorrect from "../../Components/LessonAndTestComponents/QuestionCorrect";
import MainButton from "../../Components/LessonAndTestComponents/MainButton";
import PopapAfterTest from './popapAfterTest'


import Api from '../../Service/Api'
import NewApi from '../../Service/NewApi'


export default class TestComponent extends React.Component {
    Api = new Api();
    NewApi = new NewApi();

    state = {
        MainHeader_header: '', // Первая линия заголовка шага
        MainHeader_subheader: '', // Вторая линия заголовка шага
        MainHeader_class: 'bigHeader noBB', // Большой или маленький Заголовок
        typeMainBtn: 'check answer', // тип кнопок (take a lesson - Пройти урок, '' - стандартно медлу щагами)
        questionName: '', // Название вопроса
        questionCount: '', // Количество вопросов
        answerCorrect: 0, // Ответов верных
        count: 1, //Номер вопроса
        countNextQuestion: 1, //Номер следующего вопроса для тестов внутри шагов
        qId: '',
        question: '',
        answers: {},
        compareAnswer: {},
        showQuestionCorrect: false,
        questionCorrect: '',
        headCorrect: '',
        textCorrect: '',
        answerActive: [],
        nextQuestionName: '',
        nextQuestion: '',
        nextAnswers: '',
        nextTextCorrect: '',
        nextqId: '',
        showPopap: false,
        disabled: false,
        Multi_answer: '',
        questionsData: {},
    }

    componentDidMount() {
        // const {lesson} = this.props
        const lessonId = this.props.history.location.pathname.split('/').pop()
        // this.setState({
        //     MainHeader_header: lesson.MainHeader_header,
        //     MainHeader_subheader: lesson.MainHeader_subheader
        // })
        this.NewApi.getNextLessonTestQuestion(lessonId)
            .then((res) => {
                if (res) {
                    const questionInfo = Object.values(res.test.questions)[0]
                    this.setState({
                        questionsData: res.test.questions,
                        qId: questionInfo.id,
                        questionName: questionInfo.title,
                        question: questionInfo.description,
                        questionCount: res.test.maxQuestions,
                        answers: questionInfo.answers,
                        textCorrect: questionInfo.caption,
                        Multi_answer: questionInfo.multi_answer,
                        count: res.test.userQuestions + 1,
                        answerCorrect: res.test.userCorrectQuestions,
                    })
                    console.log(555, questionInfo.multi_answer);
                }
            })
    }

    checkAnswer = () => {
        const {qId, answerActive} = this.state
        // const {lesson} = this.props
        this.setState({disabled: true})
        let answers = [];
        // eslint-disable-next-line
        answerActive.map(item => {
            answers.push(item)
        })

        if(answers.length) {
            this.NewApi.setLessonTestAnswer(qId, answers)
                .then((res) => {
                    if (res.status === 200) {
                        this.setState({disabled: false})
                        if (res.progress !== 100) {
                            this.setState({
                                compareAnswer: res.correctAnswers,
                                questionCorrect: res.userResult !== 'error' ? 'correct' : 'inCorrect',
                                typeMainBtn: 'next question',
                                showQuestionCorrect: true,
                                headCorrect: res.userResult !== 'error' ? 'все верно!' : 'К сожалению, ваш ответ неверен.',
                            })
                        } else {
                            this.setState({
                                compareAnswer: res.correctAnswers,
                                questionCorrect: res.userResult !== 'error' ? 'correct' : 'inCorrect',
                                typeMainBtn: 'end test',
                                showQuestionCorrect: true,
                                headCorrect: res.userResult !== 'error' ? 'все верно!' : 'К сожалению, ваш ответ неверен.',
                                // textCorrect: res.question.attributes.caption,

                            })
                        }

                    }
                })
        }else{
            this.setState({disabled: false})
        }

    }

    nextQuestion = () => {
        this.setState({showQuestionCorrect: false})
        const lessonId = this.props.history.location.pathname.split('/').pop()
        this.NewApi.getNextLessonTestQuestion(lessonId)
            .then((res) => {
                if (res) {
                    const questionInfo = Object.values(res.test.questions)[0]
                    this.setState({
                        questionsData: res.test.questions,
                        qId: questionInfo.id,
                        questionName: questionInfo.title,
                        question: questionInfo.description,
                        questionCount: res.test.maxQuestions,
                        answers: questionInfo.answers,
                        textCorrect: questionInfo.caption,
                        Multi_answer: questionInfo.multi_answer,
                        compareAnswer: {},
                        typeMainBtn: 'check answer',
                        count: res.test.userQuestions + 1,
                        answerCorrect: res.test.userCorrectQuestions,
                        answerActive: [],
                    })
                }
            })
    }

    endTest = () => {
        this.props.history.push('/test')
    }

    setAnswer = id => {
        const {answerActive} = this.state;
        
        if (this.state.Multi_answer === 0) {
            let oneAnswerActive = []
            oneAnswerActive.push(id)
            this.setState({answerActive: oneAnswerActive})
        } else {
            answerActive.indexOf(id) > -1
                ? delete answerActive[answerActive.indexOf(id)]
                : answerActive.push(id)

            this.setState({answerActive})
        }
    }
    setTextAnswer = text => {
        this.setState({answerActive: text})

    }

    sendAnswer = answerActive => {
        this.setState({answerActive})
    }

    render() {
        const {
            MainHeader_header,
            MainHeader_subheader,
            MainHeader_class,
            answerCorrect,
            questionCount,
            questionName,
            count,
            question,
            answers,
            compareAnswer,
            typeMainBtn,
            questionCorrect,
            headCorrect,
            textCorrect,
            showQuestionCorrect,
            showPopap,
            disabled,
            Multi_answer
        } = this.state;
        // const {lesson} = this.props
        return (
            <div>
                <div className="content_test ">
                    <div className="test">
                         {/*Основной заголовок шага*/}
                        <MainHeader
                            header={MainHeader_header}
                            subheader={MainHeader_subheader}
                            headerClass={MainHeader_class}
                            questionName={questionName}
                            count={count}
                            answerCorrect={answerCorrect}
                            questionCount={questionCount}

                        />
                        <InfoBlock
                            text={Multi_answer === 0 ? `Выберите вариант ответа и нажмите продолжить` : `Выберите несколько вариантов ответов и нажмите продолжить`}
                            hidden={showQuestionCorrect ? ' hidden' : ''}
                        />
                        <MainHeader
                            headerClass="MainHeader_mobile"
                            questionName={questionName}
                            count={count}
                            answerCorrect={answerCorrect}
                            questionCount={questionCount}
                            testHeadClass="testHeadMobile"

                        />
                        {/* Текст вопроса */}
                        <TextBlock text={question}/>
                        {/* Варианты ответов */}
                        {Multi_answer === 2
                            ?
                            <AnswersDnd answers={answers} compareAnswers={compareAnswer} setAnswer={this.setAnswer} sendAnswer={this.sendAnswer}/>
                            :
                            Multi_answer === 3 ?
                                <FreeAnswersBlock compareAnswers={compareAnswer} setAnswer={this.setTextAnswer}/>
                                :
                                <AnswersBlock answers={answers} compareAnswers={compareAnswer} setAnswer={this.setAnswer} answerType={Multi_answer}/>
                        }
                        {/*<AnswersBlock answers={answers} compareAnswers={compareAnswer} setAnswer={this.setAnswer}/>*/}
                        {/* Верный или нет ответ */}
                        {showQuestionCorrect &&
                        <QuestionCorrect
                            type={Multi_answer !== 3 ? questionCorrect : 'neutral'} // correct - верный, inCorrect - не выерный
                            head={Multi_answer !== 3 ? headCorrect : 'Ваш ответ принят'} // Заголовок блока
                            text={textCorrect} // Текст блока
                        />
                        }

                        <MainButton
                            type={typeMainBtn}
                            disabled={disabled}
                            checkAnswer={this.checkAnswer}
                            nextQuestion={this.nextQuestion}
                            endTest={this.endTest}
                            showPrevBtn={this.props.stepId !== undefined}
                            showNextStep={this.props.showNextStep}
                            showPrevStep={this.props.showPrevStep}

                        />
                    </div>
                </div>
                {showPopap &&
                <PopapAfterTest
                    history={this.props.history}
                />
                }
            </div>


        )
    }
}
