import React from 'react'
import './style.sass'
import Api from "../../Service/Api";

export default class ChatBot extends React.Component {
    Api = new Api();

    state = {
        showChatBot: false,
        questions: undefined,
        info: {},
        chat_block_questionArea_info: '',
        chat_block_answerArea_question: [],
    }

    componentDidMount() {
        this.Api.getChatBot()
            .then((questions)=>{this.setState({questions}, () => {
                this.appendQuestionBlockRow('answ', 'Здравствуйте! Я виртуальный помощник Большая перемена')
                this.appendQuestionBlockRow('answ', 'Здесь вы можете задать свой вопрос, я на него отвечу сам или передам Ваш вопрос сотруднику компании для консультации.')
                this.appendQuestionBlockRow('answ', 'Давайте начнем? Напишите ваш вопрос, либо выберите один из популярных вопросов')
                // eslint-disable-next-line
                questions.map((question, index) => {
                    if(question.top === 1){
                        this.appendInfo(index, question.question)
                    }
                })
            })})
    }

    showChatBot = () => {
        this.setState({
            showChatBot: !this.state.showChatBot
        })
    }

    changeQuestion = (chat_block_questionArea_info) => {
        const {questions} = this.state
        this.setState({
            info: {},
            chat_block_questionArea_info
        }, () => {
            let equally = [];
            // eslint-disable-next-line
            questions.map((value, index) => {
                    for (let i = 0; i < value['questionSplit'].length; i++) {
                        if (value['questionSplit'][i].length > 3) {
                            let re = new RegExp('(^|\\s)' + value['questionSplit'][i] + '(?=\\s|$)', 'gi');
                            if (re.test(chat_block_questionArea_info)) {
                                equally[index] = equally[index] ? parseInt(equally[index]) + 1 : 1
                            } else {
                                equally[index] = equally[index] ? parseInt(equally[index]) : 0
                            }
                        }
                    }
            })
            if(equally.length){
                // eslint-disable-next-line
                equally.map((value, index)=>{
                    if(value > 0){
                        this.appendInfo(index, questions[index]['question'] )
                    }
                })
            }
        })
    }

    appendInfo = (index, text) => {
        const {info} = this.state;
        info[index] = text;
        this.setState({info})
    }
    appendQuestionBlockRow = (type, text) => {
        const {chat_block_answerArea_question} = this.state;
        let temp = [];
            temp[type] = text
        chat_block_answerArea_question.push(temp)
        this.setState({
            chat_block_answerArea_question,
            info: {},
            chat_block_questionArea_info: '',
        }, () => {
            if(type === 'quest') {
                this.findAnswer(text)
            }
        })
    }
    findAnswer = (question) => {
        const {questions} = this.state
        let equally = [];
        // eslint-disable-next-line
        questions.map((value, index)=> {
            for (let i = 0; i < value['questionSplit'].length; i++) {
                if (value['questionSplit'][i].length > 3) {
                    let re = new RegExp('(^|\\s)' + value['questionSplit'][i] + '(?=\\s|$)', 'gi');
                    if (re.test(question)) {
                        equally[index] = equally[index] ? parseInt(equally[index]) + 1 : 1
                    } else {
                        equally[index] = equally[index] ? parseInt(equally[index]) : 0
                    }
                }
            }
        })
        let max = 0;
        if(equally.length){
            // eslint-disable-next-line
            equally.map((value, index)=>{
                if (value > 0 && value > max) {
                    max = index
                }
            })
        }
        this.appendQuestionBlockRow('answ' ,questions[max]['answer'])
    }

    _handleKeyDown = (e) => {
        const {chat_block_questionArea_info} = this.state;
        if (e.key === 'Enter') {
            this.appendQuestionBlockRow('quest', chat_block_questionArea_info)
        }
    }


    render() {
        const {showChatBot, chat_block_questionArea_info, info, chat_block_answerArea_question} = this.state
        return (
            <div className="chatBot">
                {!showChatBot ?
                    <div
                        className="chatBot_btn"
                        onClick={this.showChatBot}
                    >
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="comments"
                             className="svg-inline--fa fa-comments fa-w-18" role="img"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 576 512">
                            <path fill="#fff"
                                  d="M532 386.2c27.5-27.1 44-61.1 44-98.2 0-80-76.5-146.1-176.2-157.9C368.3 72.5 294.3 32 208 32 93.1 32 0 103.6 0 192c0 37 16.5 71 44 98.2-15.3 30.7-37.3 54.5-37.7 54.9-6.3 6.7-8.1 16.5-4.4 25 3.6 8.5 12 14 21.2 14 53.5 0 96.7-20.2 125.2-38.8 9.2 2.1 18.7 3.7 28.4 4.9C208.1 407.6 281.8 448 368 448c20.8 0 40.8-2.4 59.8-6.8C456.3 459.7 499.4 480 553 480c9.2 0 17.5-5.5 21.2-14 3.6-8.5 1.9-18.3-4.4-25-.4-.3-22.5-24.1-37.8-54.8zm-392.8-92.3L122.1 305c-14.1 9.1-28.5 16.3-43.1 21.4 2.7-4.7 5.4-9.7 8-14.8l15.5-31.1L77.7 256C64.2 242.6 48 220.7 48 192c0-60.7 73.3-112 160-112s160 51.3 160 112-73.3 112-160 112c-16.5 0-33-1.9-49-5.6l-19.8-4.5zM498.3 352l-24.7 24.4 15.5 31.1c2.6 5.1 5.3 10.1 8 14.8-14.6-5.1-29-12.3-43.1-21.4l-17.1-11.1-19.9 4.6c-16 3.7-32.5 5.6-49 5.6-54 0-102.2-20.1-131.3-49.7C338 339.5 416 272.9 416 192c0-3.4-.4-6.7-.7-10C479.7 196.5 528 238.8 528 288c0 28.7-16.2 50.6-29.7 64z"></path>
                        </svg>
                    </div>
                    :
                    <div className="chatBot_block">
                        <div className="chatBot_close" onClick={this.showChatBot}></div>
                        <div className="chatBot_block_answerArea">
                            {chat_block_answerArea_question.map((el, key) => {
                                if(el['quest']){
                                    return (
                                        <div
                                            key={el['quest'] + key}
                                            className={'chatBot_block_answerArea_question'}
                                        >
                                            {el['quest']}
                                        </div>
                                    )
                                }else{
                                    return (
                                        <div
                                            key={el['answ'] + key}
                                            className={'chatBot_block_answerArea_answer'}
                                            dangerouslySetInnerHTML={{__html: el['answ']}}
                                        />
                                    )
                                }

                            })}
                        </div>

                        <div className="chatBot_block_questionArea">
                            {Object.keys(info).length > 0
                                ?
                                <div className="chatBot_block_questionArea_info">
                                    {Object.keys(info).map((index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={'info_el'}
                                                onClick={() => {
                                                    this.appendQuestionBlockRow('quest', info[index])
                                                }}
                                            >
                                                {info[index]}
                                            </div>
                                        )
                                    })}
                                </div>
                                :
                                undefined
                            }

                            <input
                                type="text"
                                name="question"
                                className="chatBot_block_questionArea_input"
                                placeholder="Ваш вопрос"
                                value={chat_block_questionArea_info}
                                onChange={e => this.changeQuestion(e.target.value)}
                                onKeyDown={this._handleKeyDown}

                            />
                            <button
                                className="chatBot_block_questionArea_btn"
                                onClick={()=>this.appendQuestionBlockRow('quest', chat_block_questionArea_info)}
                            >
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right"
                                     className="svg-inline--fa fa-arrow-right fa-w-14" role="img"
                                     xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 448 512">
                                    <path fill="#fff"
                                          d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                                </svg>
                            </button>
                        </div>

                    </div>
                }


            </div>
        )
    }
}
