import React from "react";

// images
import titleScreenImg from "../../../images/project-screens/jump-start/bg/title-screen.png";
import textScreenHeaderImg from "../../../images/project-screens/jump-start/bg/text-screen-header.svg";
import textScreenImg from "../../../images/project-screens/jump-start/bg/text-screen.png";

const jumpStartData = {
  titleScreen: {
    header: "Высокий старт",
    title: "Время подвигов",
    content:
      "Чтобы добиваться успеха и совершать поступки, не нужно ждать. Нужно действовать!",
    bg: titleScreenImg,
  },
  textScreen: {
    title: (
      <span>
        Как стать молодым и успешным
        <br />
        до окончания школы или вуза?
      </span>
    ),
    header_img: textScreenHeaderImg,
    bg: textScreenImg,
    info_items: [
      <p className="text-screen__info-text--accent">
        Победители конкурсов «Россия – страна возможностей», выпускники
        арт-кластера «Тавриды», молодые предприниматели и ученые расскажут тебе
        о том, как и благодаря чему они пришли к успеху.
      </p>,
      <p>
        Перед тобой тоже открыты все двери! Нужно не бояться идти вперед,
        учиться новому и действовать.
      </p>,
    ],
  },
  bulletScreen: {
    title: "О чем этот курс?",
    info_items: [
      <span>9 героев</span>,
      <span>9 сфер жизни, в которых они добились успеха</span>,
      <span>Все моложе 30 лет, но уже добились значительного успеха!</span>,
      <span>100+ идей для твоего развития</span>,
    ],
  },
};

export default jumpStartData;
