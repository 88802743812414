import React from "react";
import img from 'assets/image/speaker_el.png'

export default ({speakers}) => {
    return (
        <div className="speaker">
            <div className="cnt">
                <div className="speaker__head">
                    Спикеры
                </div>
                <div className="speaker__flex">
                    {Object.values(speakers).map(speaker => {
                        if(speaker.photo) {
                            return (
                                <div key={speaker.id + +new Date()}
                                     className="speaker__el">
                                    <div className="img">
                                        <img src={'https://xn--c1aejgebpdbabjz8r.xn--p1ai/uploads' + speaker.photo} alt=""/>
                                    </div>
                                    <div className="name">{speaker.surname} {speaker.name} {speaker.patronymic}</div>
                                    <div className="text">
                                        {speaker.description}
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        </div>
    )
}
