import React from "react";
import ExpertsSlider from "../../expertsSliderMain/ExpertsSlider";
import ProjectScreenTitle from "../../projectScreenTitleMain/ProjectScreenTitle";

const PartnersScreen = ({ color, data }) => {
  return (
    <section className="partners-screen">
      <div className="partners-screen__content">
        <ProjectScreenTitle isMobileBorder color={color} content={data.title} />
        <ExpertsSlider type="logos" data={data.logos} />
      </div>
    </section>
  );
};

export default PartnersScreen;
