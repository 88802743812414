import React from "react";
import Api from "../../Service/Api";
import Header from 'pageComponents/header'
import Footer from 'pageComponents/footer'
import Slider from "react-slick";


export default class Project extends React.Component{
    Api = new Api()

    state = {
        user: undefined,
        project: undefined,
        showLogin: false
    }
    showLoginClick = (showLogin) => {
        this.setState({showLogin})
    }
    componentDidMount() {
        this.getUserData();
        this.getProjectData();
    }

    getUserData = () => {
        this.Api.getUserData()
            .then(user => this.setState({user}))
            .catch(e => console.log('error', e))
    }

    getProjectData = () => {

        this.Api.getProject(this.props.match.params.id, this.props.forum_id)
            .then(project => {
                if(!project){
                    this.props.history.push('/');
                }else{
                    this.setState({project})
                }
            })
    }

    galleryImgShow = (id) => {
        const {galleryImgShow} = this.state
        if(galleryImgShow === id){
            this.setState({galleryImgShow: undefined})
        }else {
            this.setState({galleryImgShow: id})
        }
    }



    render() {
        const {user, project, showLogin} = this.state
        if(!project){
            return <div/>
        }
        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        arrows: false,
                        dots: false,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className="project__page page">

                <Header
                    user={user}
                    className={'header__orange'}
                    showLogin={showLogin}
                    showLoginClick={this.showLoginClick}
                />
                <div className="project">

                    <div className="project__header">
                        <div className="cnt">
                            <div className="project__name" dangerouslySetInnerHTML={{__html: project.ForumProject.name}}/>
                            <div className="project__desc" dangerouslySetInnerHTML={{__html: project.ForumProject.description}}/>

                        </div>
                    </div>
                    <div className="project__wrp">
                        <div className="cnt">
                            <div className="gallery">
                                <div className="gallery__head">
                                    Галерея
                                </div>
                                <Slider {...settings}>
                                { project.ForumProjectPhoto.map((photo, index) => {
                                    return (
                                        <div
                                            key={photo.id + +new Date() + index}
                                            className={'gallery__el'}
                                            // onClick={() => this.galleryImgShow(photo.id)}
                                        >
                                            <img src={'https://bolshayaperemena.team/uploads'+ photo.path} alt=""/>
                                        </div>
                                    )
                                })}
                            </Slider>
                            </div>
                            <div className="project__result">
                                <div className="result__head">
                                    Результаты проекта
                                </div>
                                <div className="result__text" dangerouslySetInnerHTML={{__html: project.ForumProject.text}}/>
                            </div>

                            <div className="contact" dangerouslySetInnerHTML={{__html: project.ForumProject.contact}}/>
                        </div>
                    </div>

                </div>

                <Footer user={user}/>
            </div>
        )
    }

}


