import React from 'react'
import Inputs from '../../pageComponents/Inputs'
import {Link} from "react-router-dom";
import Api from '../../Service/Api'
import {withRouter} from 'react-router-dom';

class Auth extends React.Component {

    state = {
        email: '',
        emailError: false,
        emailErrorText: false,
        emailSend: false,
    }

    Api = new Api()

    updateState = (type, value, index) => {
        // eslint-disable-next-line
        switch (type) {
            case 'email' : {
                this.setState({
                    email: value,
                    emailError: false,
                    emailErrorText: false
                });
                break;
            }
        }
    }

    sendDate = () => {
        const {
            email,
        } = this.state
        if (!email.length && !this.valid_email(email)) {
            this.setState({emailError: true})
        } else {
            this.Api.requestPasswordReset(email)
                .then((res) => {
                    if (res.status === 200) {
                        this.props.showLoginClick('infoForgot')
                    } else {
                        res.error && Object.keys(res.error).map(key => {
                            // eslint-disable-next-line
                            this.state[key + 'Error'] = true;
                            // eslint-disable-next-line
                            this.state[key + 'ErrorText'] = res.error[key];
                            this.setState({errors: new Date()})
                            // if (key === 'password') {
                            //     this.setState({
                            //         passwordError: true,
                            //         passwordErrorText: res.error[key],
                            //     })
                            // }
                            return false
                        })
                    }
                })
        }

    }

    valid_email = (el) => {
        let reg = /^[a-z-._0-9]+@[a-z-_0-9]+\.[a-z0-9]{2,10}$/i;
        return reg.test(el)
    }


    render() {
        const {
            email,
            emailError,
            emailErrorText,
            emailSend,
        } = this.state
        const {showLoginClick} = this.props
        return (
            <div className="Auth">
                <form action="" className="form">
                    <div className="forgot">
                            <div className="login__head">
                                Для восстановления пароля, введите email:
                            </div>
                            <Inputs
                                name={'email'}
                                placeholder={'E-mail'}
                                onChange={this.updateState}
                                type={'email'}
                                className={emailError && ' error'}
                                errorText={emailErrorText}
                                required={'required'}
                            />
                            {emailSend &&
                                <div className="email__send">
                                    Письмо на {email} успешно отправленно.
                                </div>
                            }
                        </div>
                    <div className="reLink">
                        <div className="reLink__el" onClick={()=>showLoginClick('registration')}>
                            Регистрация
                        </div>
                        <div className="reLink__el" onClick={()=>showLoginClick('auth')}>
                            Авторизация
                        </div>
                    </div>
                    <div className="buttons">
                        <button
                            type='button'
                            className="button button__yellow"
                            onClick={() => {
                                this.sendDate()
                            }}
                        >
                            Отправить запрос
                        </button>
                    </div>
                </form>
            </div>
        )
    }

}

export default withRouter(Auth);
