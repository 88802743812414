import React from 'react';
import Api from '../../Service/NewApi'
import PanelTest from "../PanelTest";
import CourseStat from "../CourseStat";
import TrainingMenu from "../../pageComponents/trainingMenu";




class MyTesting extends React.Component {

    Api = new Api();

    state = {
        courses: {}
    }

    componentDidMount() {
        this.Api.getCoursesTests()
            .then(this.getTestsSuccess)
            .catch(res => console.log(res))
    }

    getTestsSuccess = (courses) => {
        this.setState({courses})
    }

    _renderMenu = () => {
        const {courses} = this.state
        if (courses !== undefined && Object.values(courses).length) {
            return (
                <TrainingMenu/>
            )
        }
    }

    _renderTest = () => {
        const {courses} = this.state;
        if (courses !== undefined && Object.values(courses).length) {
            return (
                Object.values(courses.coursesTests).map(course => {
                    // let courseAttr = course
                    // let testsAttr = course.course.tests
                    // let pointTotal = 0
                    return (
                        <div key={course.id}>
                            <div className="content_head">
                                {course.name}
                            </div>
                            <CourseStat courses={course} pointTotal={course.maxPoints}/>
                            <div className="studyPanels">
                                {course.tests !== undefined && Object.values(course.tests).length &&
                                    Object.values(course.tests).map(test => {
                                        let lesson = {};
                                        // lesson.attributes = {};
                                        lesson.id = test.lesson_id;
                                        // lesson.MainHeader_header = test.name;
                                        // lesson.MainHeader_subheader = test.description;
                                        // lesson.plan = test.lessonPlan;
                                        return (
                                            <PanelTest
                                                key={test.id}
                                                many_attempts={test.many_attempts}
                                                // active={test.active} //
                                                name={test.name}
                                                text={test.description}
                                                steps={test.maxQuestions}
                                                userSteps={test.userQuestions}
                                                id={test.id}
                                                balls={test.maxPoints}
                                                userBalls={test.userPoints}
                                                finished={test.progress}
                                                lesson={lesson}
                                                history={this.props.history}
                                            />
                                        )
                                    })
                                }

                            </div>
                        </div>
                    )
                })
            )
        } else {
            return undefined;
        }
    }


    render () {
        return (
            <div>
                {this._renderMenu()}
                {this._renderTest()}
            </div>

        )
    }
}

export default MyTesting;
