import React from 'react'
import './style.sass'

export default class AnswersBlock extends React.Component{
    state = {
        answerActive: []
    }


    render() {
        return (
            <div className={`answers ${this.props.answerClass}`}>
                <textarea
                    className={'answers__textArea'}
                    onChange={e => {
                        this.props.setAnswer(e.target.value)
                    }}
                ></textarea>
            </div>
        )
    }
}