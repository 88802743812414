import React, {useEffect, useState} from "react";

const LessonTypeVideo = ({content}) => {
    const [videoData, setVideoData] = useState(null)
    const youTubeGetId = (url) => {
        if(url.includes('youtu') || url.includes('youtube')){
            let numberSimbol = url.lastIndexOf('/')
            let id = url.substr(numberSimbol + 1,)
            let data1 = [id, 'youtube']
            return data1
        }
        else if(url.includes('rutube')){
            let numberSimbol = url.lastIndexOf('/')
            let id = url.slice(0, numberSimbol)
            let numberSimbol2 = id.lastIndexOf('/')
            let id2 = url.substr(numberSimbol2 + 1,)
            let data2 = [id2, 'rutube']
            return data2
        }
        else if(url.includes('vk')){
            let oid = url.slice(url.lastIndexOf('-') + 1, url.lastIndexOf('_'))
            let id = url.slice(url.lastIndexOf('_') + 1, url.length)
            let link = `https://vk.com/video_ext.php?oid=-${oid}&id=${id}`
            let data3 = [link, 'vk']
            return data3
        }
        else {
            return null
        }
        // try {
        //     let expression = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be[.]?\/|youtube\.com[.]?\/(?:embed\/|v\/|watch\/?\?(?:\S+=\S*&)*v=))([\w-]{11})\S*$/;
        //     return url.match(expression) ? RegExp.$1 : null;
        // } catch (e){
        //     return null
        // }
    }

    useEffect(()=>{
        if(!isNaN(parseInt(content.url, 10))){
            const {url} = content
            const resp = fetch(`${window.location.protocol}//${window.location.host}/ext-filemanager/backend/default/get-file-by-id?id=${url}`,{
                method: 'GET',
                headers: {
                    'Accept': 'application/json, text/javascript, */*; q=0.01',
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
            }).then(r=>r.json()).then(({data})=>{
                if(typeof data !== 'undefined'){
                    setVideoData({
                        type:'local',
                        data,
                        service: 'local',
                    })
                }
            })
        } else if(typeof content.url === 'string'){
            // const ytId = youTubeGetId(content.url)
            // console.log(ytId)
            // if(ytId){
            //     setVideoData({
            //         type: 'youtube',
            //         data: ytId
            //     })
            // }
            let id = youTubeGetId(content.url)
            setVideoData({
                type: 'external',
                data: id[0],
                service: id[1],
            })
        } else {
            //invalid format
        }
    },[content])

    return (
        <div>
            <div className={'step__text'} dangerouslySetInnerHTML={{__html: content.description}}/>
            {videoData !== null &&
                <>
                    {videoData?.type === 'external' ?
                        <iframe
                            className={'step__video'}
                            src={videoData.service === 'youtube' ? ('https://www.youtube.com/embed/' + videoData.data) : (videoData.service === 'rutube' ? ('https://rutube.ru/play/embed/' + videoData.data) : videoData.data)}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                        :
                        <video
                            className={'step__video'}
                            controls="controls"
                            poster={`${window.location.protocol}//${window.location.host}/${videoData.data}`}
                            autoplay={"autoplay"}
                            style={{maxHeight: '1000px'}}
                        >
                            <source src={`${window.location.protocol}//${window.location.host}/${videoData.data}`} type='video/ogg; codecs="theora, vorbis"'/>
                            <source src={`${window.location.protocol}//${window.location.host}/${videoData.data}`} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                            <source src={`${window.location.protocol}//${window.location.host}/${videoData.data}`} type='video/webm; codecs="vp8, vorbis"'/>
                            Тег video не поддерживается вашим браузером.
                            <a href={`${window.location.protocol}//${window.location.host}/${videoData.data}`}>Скачайте видео</a>.
                        </video>
                    }
                </>
            }
        </div>
    )
}

export default LessonTypeVideo