import React from "react";
import { isMobileOnly } from "react-device-detect";
import fbIcon from "../../images/fb.svg";
import odnIcon from "../../images/odn.svg";
import vkIcon from "../../images/vk.svg";
import wtspIcon from "../../images/wtsp.svg";
import Logo from "../logoMain/Logo";

const Footer = ({user}) => {
  const renderSocials = () => (
    <div className="footer__socials">
      <a
        href="https://vk.com/bpcontest"
        target="_blank"
        rel="noopener noreferrer"
        className="footer__social-link"
      >
        <img src={fbIcon} alt="" />
      </a>
      <a
        href="https://vk.com/bpcontest"
        target="_blank"
        rel="noopener noreferrer"
        className="footer__social-link"
      >
        <img src={wtspIcon} alt="" />
      </a>
      <a
        href="https://ok.ru/group/56657148969112"
        target="_blank"
        rel="noopener noreferrer"
        className="footer__social-link"
      >
        <img src={odnIcon} alt="" />
      </a>
      <a
        href="https://vk.com/bpcontest"
        target="_blank"
        rel="noopener noreferrer"
        className="footer__social-link"
      >
        <img src={vkIcon} alt="" />
      </a>
    </div>
  );

  return (
    // <footer className="footer">
    //   <div className="footer__content">
    //     <div className="footer__info-block">
    //       <Logo />
    //       {isMobileOnly && renderSocials()}
    //       <div className="footer__contacts">
    //         <a href="tel:89516185804" className="footer__link">
    //           +7 951 618 58 04
    //         </a>
    //         <a
    //           href="mailto:contact@bolshaya-peremena.academy"
    //           className="footer__link"
    //         >
    //           contact@bolshaya-peremena.academy
    //         </a>
    //       </div>
    //       <div className="footer__phone">
    //         <p className="footer__phone-text">Телефон поддержки</p>
    //         <a href="tel:84951980447" className="footer__link">
    //           8 495 198 04 47
    //         </a>
    //       </div>
    //     </div>
    //     <div className="footer__info-block">
    //       {!isMobileOnly && renderSocials()}
    //       <div className="footer__legal-info">
    //         <a
    //           href="/polojenie.pdf"
    //           target="_blank"
    //           rel="noopener noreferrer"
    //           className="footer__link"
    //         >
    //           Положение о конкурсе
    //         </a>
    //         <a
    //           href="/politika.docx"
    //           target="_blank"
    //           rel="noopener noreferrer"
    //           className="footer__link"
    //         >
    //           Политика в отношении обработки персональных данных
    //         </a>
    //       </div>
    //     </div>
    //   </div>
    // </footer>

      <footer className="footer">
          <div className="footer__container container-1">
              <a href="/" aria-current="page" className="footer__logo router-link-exact-active router-link-active">
                  <div className="v-icon">
                      <svg width="156" height="308" viewBox="0 0 156 308" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M57.199 149H4.99902C3.39902 149 2.79903 147 4.09903 146.1L151.799 49.5C153.099 48.7 154.599 49.9 154.099 51.3L61.699 306.1C61.099 307.7 58.699 307.3 58.699 305.6V150.5C58.699 149.7 57.999 149 57.199 149Z"
                              fill="#E53130"></path>
                          <path d="M18.5 31.7998V5.6998H6.29999V31.7998H0V0.299805H24.8V31.7998H18.5Z"
                                fill="#E53130"></path>
                          <path
                              d="M42.5996 17.8998C42.5996 16.9998 42.3996 16.2998 42.0996 15.6998C41.8996 14.9998 41.4996 14.4998 41.0996 14.0998C40.6996 13.6998 40.1996 13.2998 39.5996 13.1998C38.9996 12.9998 38.4996 12.8998 37.8996 12.8998C36.6996 12.8998 35.6996 13.3998 34.7996 14.2998C33.8996 15.1998 33.3996 16.3998 33.2996 17.9998H42.5996V17.8998ZM33.0996 21.7998C33.1996 23.5998 33.6996 24.9998 34.5996 25.9998C35.4996 26.9998 36.6996 27.5998 38.1996 27.5998C39.1996 27.5998 39.9996 27.3998 40.7996 26.8998C41.5996 26.3998 42.0996 25.7998 42.2996 24.9998H48.4996C47.7996 27.3998 46.5996 29.1998 44.7996 30.4998C42.9996 31.7998 40.9996 32.3998 38.4996 32.3998C30.8996 32.3998 27.0996 28.1998 27.0996 19.6998C27.0996 17.8998 27.2996 16.2998 27.7996 14.7998C28.2996 13.2998 28.9996 12.0998 29.8996 11.0998C30.8996 10.0998 31.9996 9.2998 33.3996 8.6998C34.7996 8.0998 36.2996 7.7998 38.0996 7.7998C41.5996 7.7998 44.2996 8.9998 45.9996 11.2998C47.7996 13.5998 48.6996 17.0998 48.6996 21.6998H33.0996V21.7998Z"
                              fill="#E53130"></path>
                          <path
                              d="M62.0008 27.1994C65.4008 27.1994 67.2008 24.7994 67.2008 19.9994C67.2008 17.8994 66.7008 16.1994 65.8008 14.8994C64.8008 13.5994 63.6008 12.8994 62.1008 12.8994C60.7008 12.8994 59.4008 13.4994 58.5008 14.6994C57.5008 15.8994 57.1008 17.4994 57.1008 19.4994C57.0008 24.6994 58.7008 27.1994 62.0008 27.1994ZM51.3008 42.8994V8.59943H57.1008V11.3994C58.5008 9.09941 60.6008 7.89941 63.4008 7.89941C64.8008 7.89941 66.1008 8.19942 67.3008 8.79942C68.5008 9.39942 69.6008 10.1994 70.4008 11.2994C71.3008 12.3994 71.9008 13.6994 72.4008 15.1994C72.9008 16.6994 73.1008 18.4994 73.1008 20.4994C73.1008 22.2994 72.9008 23.8994 72.5008 25.3994C72.1008 26.8994 71.5008 28.0994 70.7008 29.1994C69.9008 30.2994 68.9008 31.0994 67.7008 31.6994C66.6008 32.2994 65.3008 32.5994 63.8008 32.5994C61.0008 32.5994 58.8008 31.3994 57.1008 28.9994V43.0994H51.3008V42.8994Z"
                              fill="#E53130"></path>
                          <path
                              d="M75.5996 31.8V8.5H91.2996C92.6996 8.5 93.7996 8.7 94.6996 9C95.5996 9.3 96.3996 9.7 96.9996 10.2C97.5996 10.7 97.9996 11.3 98.1996 12C98.3996 12.7 98.5996 13.4 98.5996 14.2C98.5996 15.6 98.1996 16.7 97.4996 17.6C96.7996 18.4 95.8996 19 94.8996 19.3C95.4996 19.4 95.9996 19.6 96.4996 20C96.9996 20.3 97.4996 20.7 97.8996 21.1C98.2996 21.6 98.6996 22.2 98.8996 22.9C99.0996 23.6 99.2996 24.4 99.2996 25.3C99.2996 26.3 99.0996 27.2 98.7996 28C98.4996 28.8 97.8996 29.5 97.1996 30.1C96.4996 30.7 95.5996 31.1 94.3996 31.5C93.2996 31.8 91.8996 32 90.2996 32H75.5996V31.8ZM89.3996 27.5C91.9996 27.5 93.2996 26.4 93.2996 24.3C93.2996 22.2 92.0996 21.2 89.6996 21.2H81.1996V27.5H89.3996ZM89.5996 17.6C90.5996 17.6 91.3996 17.4 91.8996 17C92.4996 16.6 92.6996 16 92.6996 15.3C92.6996 13.5 91.5996 12.6 89.3996 12.6H81.2996V17.5H89.5996V17.6Z"
                              fill="#E53130"></path>
                          <path
                              d="M107.7 8.49942V15.8994H115.3C117.9 15.8994 119.9 16.5994 121.4 17.8994C122.9 19.1994 123.6 21.1994 123.6 23.6994C123.6 26.2994 122.9 28.2994 121.4 29.6994C120 31.0994 117.9 31.6994 115.3 31.6994H101.9V8.39941H107.7V8.49942ZM107.7 20.3994V27.2994H114.9C116 27.2994 116.9 26.9994 117.5 26.3994C118.1 25.7994 118.4 24.8994 118.4 23.7994C118.4 22.6994 118.1 21.7994 117.5 21.1994C116.9 20.5994 116 20.2994 114.9 20.2994H107.7V20.3994ZM125.5 31.7994V8.49942H131.5V31.7994H125.5Z"
                              fill="#E53130"></path>
                          <path
                              d="M149.699 17.8998C149.699 16.9998 149.499 16.2998 149.199 15.6998C148.999 14.9998 148.599 14.4998 148.199 14.0998C147.799 13.6998 147.299 13.2998 146.699 13.1998C146.099 12.9998 145.599 12.8998 144.999 12.8998C143.799 12.8998 142.799 13.3998 141.899 14.2998C140.999 15.1998 140.499 16.3998 140.399 17.9998H149.699V17.8998ZM140.199 21.7998C140.299 23.5998 140.799 24.9998 141.699 25.9998C142.599 26.9998 143.799 27.5998 145.299 27.5998C146.299 27.5998 147.099 27.3998 147.899 26.8998C148.699 26.3998 149.199 25.7998 149.399 24.9998H155.599C154.899 27.3998 153.699 29.1998 151.899 30.4998C150.099 31.7998 148.099 32.3998 145.599 32.3998C137.999 32.3998 134.199 28.1998 134.199 19.6998C134.199 17.8998 134.399 16.2998 134.899 14.7998C135.399 13.2998 136.099 12.0998 136.999 11.0998C137.999 10.0998 139.099 9.2998 140.499 8.6998C141.899 8.0998 143.399 7.7998 145.199 7.7998C148.699 7.7998 151.399 8.9998 153.099 11.2998C154.899 13.5998 155.799 17.0998 155.799 21.6998H140.199V21.7998Z"
                              fill="#E53130"></path>
                      </svg>
                  </div>
              </a>
              <div className="footer__docs">
                  <a
                  href="https://будьвдвижении.рф/files/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%A0%D0%94%D0%94%D0%9C.pdf"
                  target="_blank" className="footer__doc-link">
                  Политика в отношении обработки персональных данных
                  </a>
              </div>
              <div className="footer__user">
                  <div className="footer__social">
                      <a href="https://vk.com/rddm_official" target="_blank" className="footer__social-link">
                          <div className="v-icon">
                              <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_3814_40658)">
                                      <mask id="mask0_3814_40658" maskUnits="userSpaceOnUse"
                                            x="0" y="0" width="40" height="40">
                                          <path d="M40 0H0V40H40V0Z" fill="white"></path>
                                      </mask>
                                      <g mask="url(#mask0_3814_40658)">
                                          <path
                                              d="M19.1792 40H20.8448C29.8864 40 34.408 40 37.216 37.192C40.024 34.384 40 29.864 40 20.8448V19.1552C40 10.1376 40 5.61601 37.216 2.80801C34.432 8.10623e-06 29.8864 0 20.8448 0H19.1792C10.136 0 5.616 8.10623e-06 2.808 2.80801C-1.66893e-06 5.61601 0 10.1344 0 19.1552V20.8448C0 29.864 -1.66893e-06 34.384 2.808 37.192C5.616 40 10.136 40 19.1792 40Z"
                                              fill="#0077FF"></path>
                                          <path
                                              d="M21.4634 28.8396C12.4442 28.8396 6.97061 22.582 6.75781 12.1836H11.3258C11.4682 19.822 14.9434 23.0636 17.6074 23.7244V12.1836H21.9866V18.774C24.5562 18.4892 27.2442 15.4908 28.1498 12.1836H32.4554C31.7658 16.2524 28.8394 19.2508 26.769 20.4876C28.8394 21.4876 32.1706 24.1052 33.4554 28.8396H28.721C27.721 25.6748 25.2698 23.2236 21.9866 22.8908V28.8396H21.4634Z"
                                              fill="white"></path>
                                      </g>
                                  </g>
                                  <defs>
                                      <clipPath id="clip0_3814_40658">
                                          <rect width="40" height="40" fill="white"></rect>
                                      </clipPath>
                                  </defs>
                              </svg>
                          </div>
                      </a>
                      <a href="https://t.me/rddm_official" target="_blank" className="footer__social-link">
                          <div className="v-icon">
                              <svg width="40px" height="40px" viewBox="0 0 256 256" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                  <g>
                                      <path d="M128,0 C57.307,0 0,57.307 0,128 L0,128 C0,198.693 57.307,256 128,256 L128,256 C198.693,256 256,198.693 256,128 L256,128 C256,57.307 198.693,0 128,0 L128,0 Z" fill="#40B3E0">

                                      </path>
                                      <path d="M190.2826,73.6308 L167.4206,188.8978 C167.4206,188.8978 164.2236,196.8918 155.4306,193.0548 L102.6726,152.6068 L83.4886,143.3348 L51.1946,132.4628 C51.1946,132.4628 46.2386,130.7048 45.7586,126.8678 C45.2796,123.0308 51.3546,120.9528 51.3546,120.9528 L179.7306,70.5928 C179.7306,70.5928 190.2826,65.9568 190.2826,73.6308" fill="#FFFFFF">

                                      </path>
                                      <path d="M98.6178,187.6035 C98.6178,187.6035 97.0778,187.4595 95.1588,181.3835 C93.2408,175.3085 83.4888,143.3345 83.4888,143.3345 L161.0258,94.0945 C161.0258,94.0945 165.5028,91.3765 165.3428,94.0945 C165.3428,94.0945 166.1418,94.5735 163.7438,96.8115 C161.3458,99.0505 102.8328,151.6475 102.8328,151.6475" fill="#D2E5F1">

                                      </path>
                                      <path d="M122.9015,168.1154 L102.0335,187.1414 C102.0335,187.1414 100.4025,188.3794 98.6175,187.6034 L102.6135,152.2624" fill="#B5CFE4">

                                      </path>
                                  </g>
                              </svg>
                          </div>
                      </a>
                      <a href="https://ok.ru/group/70000001619999" target="_blank" className="footer__social-link">
                          <div className="v-icon">
                              <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M0.119141 19.296C0.119141 10.2507 0.119141 5.72812 2.93321 2.91405C5.74728 0.0999756 10.2699 0.0999756 19.3151 0.0999756H20.9232C29.9684 0.0999756 34.491 0.0999756 37.3051 2.91405C40.1191 5.72812 40.1191 10.2507 40.1191 19.296V20.904C40.1191 29.9492 40.1191 34.4718 37.3051 37.2859C34.491 40.1 29.9684 40.1 20.9232 40.1H19.3151C10.2699 40.1 5.74728 40.1 2.93321 37.2859C0.119141 34.4718 0.119141 29.9492 0.119141 20.904V19.296Z"
                                  fill="#FF7700"></path>
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M24.8845 17.5472C23.6583 18.7733 21.9699 19.5371 20.1006 19.5371C18.2312 19.5371 16.5428 18.7733 15.3166 17.5472C14.0905 16.321 13.3267 14.6326 13.3267 12.7632C13.3267 10.8939 14.0905 9.20546 15.3166 7.97933C16.5428 6.7532 18.2312 5.98938 20.1006 5.98938C21.9699 5.98938 23.6583 6.7532 24.8845 7.97933C26.1106 9.20546 26.8744 10.8939 26.8744 12.7632C26.8744 14.6326 26.1106 16.321 24.8845 17.5472ZM20.1006 9.66777C19.2563 9.66777 18.4925 10.0095 17.9297 10.5723C17.3669 11.1351 17.0252 11.8989 17.0252 12.7431C17.0252 13.5874 17.3669 14.3512 17.9297 14.914C18.4925 15.4768 19.2563 15.8185 20.1006 15.8185C20.9448 15.8185 21.7086 15.4768 22.2714 14.914C22.8342 14.3512 23.1759 13.5874 23.1759 12.7431C23.1759 11.8989 22.8342 11.1351 22.2714 10.5723C21.7086 10.0296 20.9448 9.66777 20.1006 9.66777ZM27.2161 19.5572L29.2061 22.2708C29.3066 22.4115 29.2865 22.5924 29.1458 22.6929C27.4774 24.0597 25.5277 24.9643 23.4975 25.4467L27.3367 32.8437C27.4372 33.0648 27.2965 33.306 27.0553 33.306H22.9548C22.8342 33.306 22.7136 33.2256 22.6533 33.105L20.1006 26.9944L17.5277 33.0849C17.4875 33.2055 17.3669 33.2859 17.2262 33.2859H13.1257C12.8845 33.2859 12.7438 33.0246 12.8443 32.8236L16.6835 25.4467C14.6533 24.9643 12.7237 24.0597 11.0352 22.6929C10.9146 22.5924 10.8744 22.3914 10.9749 22.2708L12.9649 19.5572C13.0855 19.3964 13.2865 19.3763 13.4272 19.4969C15.2965 21.0849 17.6081 22.1301 20.0805 22.1301C22.5528 22.1301 24.8443 21.105 26.7337 19.4969C26.8945 19.3763 27.1156 19.3964 27.2161 19.5572Z"
                                    fill="white"></path>
                          </svg>
                          </div>
                      </a>
                  </div>
                  {!user &&
                      <a href="https://bolshaya-peremena.academy/auth/login"
                         className="footer__btn v-button v-button--blue v-button--normal v-button--bold">Войти
                      </a>
                  }
              </div>
          </div>
      </footer>
  );
};

export default Footer;
