import React from 'react'
import './style.sass'

export default props => {
    return (
        <div
            className={'TextBlock ' + props.hidden}
            style={
                (props.fontSize && {fontSize: props.fontSize, lineHeight: '1.5'})
            }
            dangerouslySetInnerHTML={{ __html: props.text }}
        />
    )
}
