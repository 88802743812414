import React from 'react'

export default class List extends React.Component {

    state = {
        ListData: this.props.ListData,
        listHead: this.props.listHead
    }

    render() {
        const {ListData, listHead} = this.state
        return (
            <ul className="List">
                {listHead &&
                    <div className="List_head">
                        {listHead}
                    </div>
                }
                {ListData.map((item, index) => {
                    return (
                        <li
                            key={index}
                        >
                            {index % 2 !== 0
                                ?
                                <svg className="List_point" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 6C12 6.80936 11.8415 7.5947 11.528 8.33569C11.2267 9.05082 10.793 9.69202 10.2427 10.2427C9.69231 10.7933 9.04964 11.2257 8.33556 11.5288C7.59536 11.8411 6.80987 12 6 12C5.19013 12 4.40464 11.8411 3.66444 11.5288C2.95036 11.2257 2.30943 10.7933 1.75733 10.2427C1.20697 9.69202 0.773294 9.05082 0.471988 8.33569C0.15849 7.59655 0 6.80936 0 6C0 5.19064 0.15849 4.4053 0.471988 3.66431C0.773294 2.94918 1.20697 2.30798 1.75733 1.75732C2.30769 1.20665 2.95036 0.774253 3.66444 0.471205C4.40464 0.158916 5.19013 0 6 0C6.80987 0 7.59536 0.158916 8.33556 0.471205C9.04964 0.774253 9.69057 1.20665 10.2427 1.75732C10.793 2.30798 11.2267 2.94918 11.528 3.66431C11.8415 4.4053 12 5.19064 12 6ZM0.660087 6C0.660087 6.72067 0.801161 7.421 1.07983 8.07884C1.34804 8.71451 1.73469 9.28734 2.22409 9.77702C2.71524 10.2686 3.2865 10.6529 3.92221 10.9227C4.58055 11.2017 5.2807 11.3422 6.00174 11.3422C6.72279 11.3422 7.42293 11.2017 8.08128 10.9227C8.71698 10.6529 9.28824 10.2686 9.77939 9.77702C10.2705 9.28549 10.6554 8.71451 10.9237 8.07884C11.2023 7.421 11.3434 6.72067 11.3434 6C11.3434 5.27934 11.2023 4.579 10.9237 3.92116C10.6554 3.28549 10.2688 2.71266 9.77939 2.22298C9.28824 1.73145 8.71698 1.34709 8.08128 1.0773C7.42293 0.798276 6.72279 0.657839 6.00174 0.657839C5.2807 0.657839 4.58055 0.798276 3.92221 1.0773C3.2865 1.34709 2.71524 1.73145 2.22409 2.22298C1.73295 2.71451 1.34804 3.28549 1.07983 3.92116C0.801161 4.579 0.660087 5.27934 0.660087 6Z" fill="#00A098"/>
                                    <path d="M10.7478 6C10.7478 6.6412 10.6224 7.26209 10.375 7.84786C10.1364 8.4133 9.79333 8.92146 9.35791 9.35571C8.9225 9.78996 8.41568 10.1337 7.84964 10.372C7.26445 10.6196 6.64267 10.7453 6.00175 10.7453C5.36082 10.7453 4.73905 10.6196 4.15385 10.372C3.58955 10.1337 3.08099 9.78996 2.64558 9.35571C2.21016 8.91962 1.86706 8.4133 1.62845 7.84786C1.38114 7.26209 1.25574 6.6412 1.25574 6C1.25574 5.35879 1.38114 4.73791 1.62845 4.15214C1.86706 3.58669 2.21016 3.07853 2.64558 2.64428C3.08099 2.20819 3.58781 1.86634 4.15385 1.62796C4.73905 1.38035 5.36082 1.25469 6.00175 1.25469C6.64267 1.25469 7.26445 1.38035 7.84964 1.62796C8.41394 1.86634 8.9225 2.21004 9.35791 2.64428C9.79333 3.07853 10.1364 3.58669 10.375 4.15214C10.6206 4.73791 10.7478 5.36064 10.7478 6ZM1.79391 6C1.79391 6.56914 1.90537 7.1198 2.12482 7.63905C2.33731 8.13982 2.64035 8.5907 3.027 8.9769C3.41365 9.3631 3.86299 9.66615 4.36459 9.87865C4.8836 10.0985 5.43397 10.2094 6.00175 10.2094C6.56952 10.2094 7.12163 10.0985 7.6389 9.87865C8.1405 9.66615 8.58984 9.3631 8.97649 8.9769C9.36314 8.5907 9.66619 8.13982 9.87867 7.63905C10.0981 7.1198 10.2096 6.56914 10.2096 6C10.2096 5.43086 10.0981 4.88019 9.87867 4.36095C9.66619 3.86018 9.36314 3.4093 8.97649 3.0231C8.58984 2.63689 8.1405 2.33384 7.6389 2.12134C7.11989 1.90145 6.56952 1.79057 6.00175 1.79057C5.43397 1.79057 4.88186 1.90145 4.36459 2.12134C3.86299 2.33384 3.41365 2.63689 3.027 3.0231C2.64035 3.4093 2.33731 3.86018 2.12482 4.36095C1.90537 4.88019 1.79391 5.4327 1.79391 6Z" fill="#00A098"/>
                                    <path d="M9.68709 6.00001C9.68709 6.49708 9.58956 6.98122 9.39798 7.43579C9.21162 7.87558 8.94689 8.26918 8.609 8.60734C8.27112 8.94549 7.87577 9.21159 7.43687 9.39637C6.9823 9.58855 6.49986 9.68649 6.00175 9.68649C5.50363 9.68649 5.0212 9.58855 4.56662 9.39637C4.12773 9.21159 3.73411 8.94549 3.39449 8.60734C3.05661 8.26918 2.79014 7.87374 2.60552 7.43579C2.41394 6.98122 2.31641 6.49893 2.31641 6.00001C2.31641 5.50108 2.41394 5.01879 2.60552 4.56422C2.79188 4.12443 3.05661 3.73083 3.39449 3.39268C3.73237 3.05452 4.12773 2.78843 4.56662 2.60364C5.0212 2.41146 5.50363 2.31353 6.00175 2.31353C6.49986 2.31353 6.9823 2.41146 7.43687 2.60364C7.87577 2.78843 8.26938 3.05452 8.609 3.39268C8.94689 3.73083 9.21336 4.12628 9.39798 4.56422C9.58956 5.02064 9.68709 5.50293 9.68709 6.00001ZM2.7344 6.00001C2.7344 6.44164 2.82149 6.8685 2.99043 7.27133C3.15414 7.66123 3.39101 8.01048 3.69057 8.30983C3.99014 8.60918 4.34021 8.84571 4.7286 9.01017C5.13092 9.18017 5.55937 9.26702 6.00001 9.26702C6.44064 9.26702 6.86909 9.18017 7.27141 9.01017C7.6598 8.84571 8.00988 8.60918 8.30944 8.30983C8.609 8.01048 8.84587 7.65938 9.00959 7.27133C9.18027 6.8685 9.26561 6.4398 9.26561 6.00001C9.26561 5.56021 9.17853 5.13151 9.00959 4.72868C8.84587 4.33878 8.609 3.98953 8.30944 3.69018C8.00988 3.39083 7.6598 3.1543 7.27141 2.98984C6.86909 2.81984 6.44064 2.73299 6.00001 2.73299C5.55937 2.73299 5.13092 2.81984 4.7286 2.98984C4.34021 3.1543 3.99014 3.39083 3.69057 3.69018C3.39101 3.98953 3.15414 4.34063 2.99043 4.72868C2.81974 5.13151 2.7344 5.55837 2.7344 6.00001Z" fill="#00A098"/>
                                    <path d="M8.73264 6C8.73264 6.36957 8.66123 6.7262 8.51841 7.06252C8.38082 7.38774 8.18402 7.6797 7.93322 7.93101C7.68242 8.18232 7.38982 8.37819 7.06588 8.51678C6.72974 8.65907 6.37095 8.73113 6.00347 8.73113C5.63423 8.73113 5.2772 8.65907 4.94106 8.51678C4.61537 8.38004 4.32451 8.18232 4.07371 7.93101C3.82291 7.6797 3.62611 7.38774 3.48852 7.06252C3.3457 6.7262 3.27429 6.36772 3.27429 6C3.27429 5.63227 3.3457 5.27379 3.48852 4.93748C3.62611 4.61225 3.82291 4.32029 4.07371 4.06898C4.32451 3.81767 4.61711 3.6218 4.94106 3.48321C5.2772 3.34093 5.63598 3.26886 6.00347 3.26886C6.3727 3.26886 6.72974 3.34093 7.06588 3.48321C7.39156 3.61995 7.68242 3.81767 7.93322 4.06898C8.18402 4.32029 8.38082 4.61225 8.51841 4.93748C8.66123 5.27379 8.73264 5.63227 8.73264 6ZM3.57037 6C3.57037 6.32891 3.63481 6.64675 3.76196 6.9461C3.88387 7.23621 4.05978 7.49676 4.28271 7.72035C4.50564 7.94394 4.76689 8.11949 5.056 8.24145C5.35557 8.36895 5.67429 8.43178 6.00347 8.43178C6.33264 8.43178 6.64962 8.3671 6.95093 8.24145C7.24004 8.11949 7.50129 7.94394 7.72422 7.72035C7.94715 7.49676 8.12306 7.23621 8.24497 6.9461C8.37212 6.64675 8.43656 6.32707 8.43656 6C8.43656 5.67292 8.37212 5.35325 8.24497 5.05389C8.12306 4.76378 7.94715 4.50323 7.72422 4.27964C7.50129 4.05605 7.24004 3.8805 6.95093 3.75854C6.65136 3.63104 6.33264 3.56821 6.00347 3.56821C5.67429 3.56821 5.35731 3.63289 5.056 3.75854C4.76689 3.8805 4.50564 4.05605 4.28271 4.27964C4.05978 4.50323 3.88387 4.76378 3.76196 5.05389C3.63307 5.35325 3.57037 5.67108 3.57037 6Z" fill="#00A098"/>
                                    <path d="M7.77819 6.00001C7.77819 6.47491 7.59358 6.92024 7.25744 7.25655C6.9213 7.59286 6.47544 7.77765 6.00171 7.77765C5.52798 7.77765 5.08211 7.59286 4.74597 7.25655C4.40984 6.92024 4.22522 6.47491 4.22522 6.00001C4.22522 5.52511 4.40984 5.07977 4.74597 4.74346C5.08211 4.409 5.52798 4.22422 6.00171 4.22422C6.47544 4.22422 6.9213 4.409 7.25744 4.74531C7.59358 5.07977 7.77819 5.52511 7.77819 6.00001ZM4.40461 6.00001C4.40461 6.42686 4.57007 6.82785 4.87312 7.12905C5.17442 7.4321 5.575 7.59841 6.00171 7.59841C6.42841 7.59841 6.82899 7.4321 7.1303 7.1309C7.43161 6.8297 7.5988 6.42871 7.5988 6.00186C7.5988 5.575 7.43335 5.17402 7.1303 4.87281C6.82899 4.57161 6.42841 4.40531 6.00171 4.40531C5.575 4.40531 5.17442 4.57161 4.87312 4.87281C4.57181 5.17217 4.40461 5.57315 4.40461 6.00001Z" fill="#00A098"/>
                                </svg>
                                :
                                <svg className="List_point" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 6C12 6.80936 11.8415 7.5947 11.528 8.33569C11.2267 9.05082 10.793 9.69202 10.2427 10.2427C9.69231 10.7933 9.04964 11.2257 8.33556 11.5288C7.59536 11.8411 6.80987 12 6 12C5.19013 12 4.40464 11.8411 3.66444 11.5288C2.95036 11.2257 2.30943 10.7933 1.75733 10.2427C1.20697 9.69202 0.773294 9.05082 0.471988 8.33569C0.15849 7.59655 0 6.80936 0 6C0 5.19064 0.15849 4.4053 0.471988 3.66431C0.773294 2.94918 1.20697 2.30798 1.75733 1.75732C2.30769 1.20665 2.95036 0.774253 3.66444 0.471205C4.40464 0.158916 5.19013 0 6 0C6.80987 0 7.59536 0.158916 8.33556 0.471205C9.04964 0.774253 9.69057 1.20665 10.2427 1.75732C10.793 2.30798 11.2267 2.94918 11.528 3.66431C11.8415 4.4053 12 5.19064 12 6ZM0.660087 6C0.660087 6.72067 0.801161 7.421 1.07983 8.07884C1.34804 8.71451 1.73469 9.28734 2.22409 9.77702C2.71524 10.2686 3.2865 10.6529 3.92221 10.9227C4.58055 11.2017 5.2807 11.3422 6.00174 11.3422C6.72279 11.3422 7.42293 11.2017 8.08128 10.9227C8.71698 10.6529 9.28824 10.2686 9.77939 9.77702C10.2705 9.28549 10.6554 8.71451 10.9237 8.07884C11.2023 7.421 11.3434 6.72067 11.3434 6C11.3434 5.27934 11.2023 4.579 10.9237 3.92116C10.6554 3.28549 10.2688 2.71266 9.77939 2.22298C9.28824 1.73145 8.71698 1.34709 8.08128 1.0773C7.42293 0.798276 6.72279 0.657839 6.00174 0.657839C5.2807 0.657839 4.58055 0.798276 3.92221 1.0773C3.2865 1.34709 2.71524 1.73145 2.22409 2.22298C1.73295 2.71451 1.34804 3.28549 1.07983 3.92116C0.801161 4.579 0.660087 5.27934 0.660087 6Z" fill="#FFC823"/>
                                    <path d="M10.7478 6C10.7478 6.6412 10.6224 7.26209 10.375 7.84786C10.1364 8.4133 9.79333 8.92146 9.35791 9.35571C8.9225 9.78996 8.41568 10.1337 7.84964 10.372C7.26445 10.6196 6.64267 10.7453 6.00175 10.7453C5.36082 10.7453 4.73905 10.6196 4.15385 10.372C3.58955 10.1337 3.08099 9.78996 2.64558 9.35571C2.21016 8.91962 1.86706 8.4133 1.62845 7.84786C1.38114 7.26209 1.25574 6.6412 1.25574 6C1.25574 5.35879 1.38114 4.73791 1.62845 4.15214C1.86706 3.58669 2.21016 3.07853 2.64558 2.64428C3.08099 2.20819 3.58781 1.86634 4.15385 1.62796C4.73905 1.38035 5.36082 1.25469 6.00175 1.25469C6.64267 1.25469 7.26445 1.38035 7.84964 1.62796C8.41394 1.86634 8.9225 2.21004 9.35791 2.64428C9.79333 3.07853 10.1364 3.58669 10.375 4.15214C10.6206 4.73791 10.7478 5.36064 10.7478 6ZM1.79391 6C1.79391 6.56914 1.90537 7.1198 2.12482 7.63905C2.33731 8.13982 2.64035 8.5907 3.027 8.9769C3.41365 9.3631 3.86299 9.66615 4.36459 9.87865C4.8836 10.0985 5.43397 10.2094 6.00175 10.2094C6.56952 10.2094 7.12163 10.0985 7.6389 9.87865C8.1405 9.66615 8.58984 9.3631 8.97649 8.9769C9.36314 8.5907 9.66619 8.13982 9.87867 7.63905C10.0981 7.1198 10.2096 6.56914 10.2096 6C10.2096 5.43086 10.0981 4.88019 9.87867 4.36095C9.66619 3.86018 9.36314 3.4093 8.97649 3.0231C8.58984 2.63689 8.1405 2.33384 7.6389 2.12134C7.11989 1.90145 6.56952 1.79057 6.00175 1.79057C5.43397 1.79057 4.88186 1.90145 4.36459 2.12134C3.86299 2.33384 3.41365 2.63689 3.027 3.0231C2.64035 3.4093 2.33731 3.86018 2.12482 4.36095C1.90537 4.88019 1.79391 5.4327 1.79391 6Z" fill="#FFC823"/>
                                    <path d="M9.68709 6.00001C9.68709 6.49709 9.58956 6.98123 9.39798 7.4358C9.21162 7.87559 8.94689 8.26918 8.609 8.60734C8.27112 8.9455 7.87577 9.21159 7.43687 9.39638C6.9823 9.58856 6.49986 9.68649 6.00175 9.68649C5.50363 9.68649 5.0212 9.58856 4.56662 9.39638C4.12773 9.21159 3.73411 8.9455 3.39449 8.60734C3.05661 8.26918 2.79014 7.87374 2.60552 7.4358C2.41394 6.98123 2.31641 6.49894 2.31641 6.00001C2.31641 5.50109 2.41394 5.0188 2.60552 4.56423C2.79188 4.12444 3.05661 3.73084 3.39449 3.39268C3.73237 3.05453 4.12773 2.78843 4.56662 2.60365C5.0212 2.41147 5.50363 2.31353 6.00175 2.31353C6.49986 2.31353 6.9823 2.41147 7.43687 2.60365C7.87577 2.78843 8.26938 3.05453 8.609 3.39268C8.94689 3.73084 9.21336 4.12628 9.39798 4.56423C9.58956 5.02065 9.68709 5.50294 9.68709 6.00001ZM2.7344 6.00001C2.7344 6.44165 2.82149 6.86851 2.99043 7.27134C3.15414 7.66124 3.39101 8.01048 3.69057 8.30984C3.99014 8.60919 4.34021 8.84572 4.7286 9.01018C5.13092 9.18018 5.55937 9.26703 6.00001 9.26703C6.44064 9.26703 6.86909 9.18018 7.27141 9.01018C7.6598 8.84572 8.00988 8.60919 8.30944 8.30984C8.609 8.01048 8.84587 7.65939 9.00959 7.27134C9.18027 6.86851 9.26561 6.4398 9.26561 6.00001C9.26561 5.56022 9.17853 5.13152 9.00959 4.72869C8.84587 4.33879 8.609 3.98954 8.30944 3.69019C8.00988 3.39084 7.6598 3.15431 7.27141 2.98985C6.86909 2.81985 6.44064 2.733 6.00001 2.733C5.55937 2.733 5.13092 2.81985 4.7286 2.98985C4.34021 3.15431 3.99014 3.39084 3.69057 3.69019C3.39101 3.98954 3.15414 4.34064 2.99043 4.72869C2.81974 5.13152 2.7344 5.55837 2.7344 6.00001Z" fill="#FFC823"/>
                                    <path d="M8.73264 6C8.73264 6.36958 8.66123 6.72621 8.51841 7.06252C8.38082 7.38775 8.18402 7.67971 7.93322 7.93102C7.68242 8.18233 7.38982 8.3782 7.06588 8.51679C6.72974 8.65907 6.37095 8.73114 6.00347 8.73114C5.63423 8.73114 5.2772 8.65907 4.94106 8.51679C4.61537 8.38005 4.32451 8.18233 4.07371 7.93102C3.82291 7.67971 3.62611 7.38775 3.48852 7.06252C3.3457 6.72621 3.27429 6.36773 3.27429 6C3.27429 5.63228 3.3457 5.27379 3.48852 4.93748C3.62611 4.61226 3.82291 4.3203 4.07371 4.06899C4.32451 3.81768 4.61711 3.62181 4.94106 3.48322C5.2772 3.34093 5.63598 3.26887 6.00347 3.26887C6.3727 3.26887 6.72974 3.34093 7.06588 3.48322C7.39156 3.61996 7.68242 3.81768 7.93322 4.06899C8.18402 4.3203 8.38082 4.61226 8.51841 4.93748C8.66123 5.27379 8.73264 5.63228 8.73264 6ZM3.57037 6C3.57037 6.32892 3.63481 6.64675 3.76196 6.94611C3.88387 7.23622 4.05978 7.49677 4.28271 7.72036C4.50564 7.94395 4.76689 8.1195 5.056 8.24146C5.35557 8.36896 5.67429 8.43179 6.00347 8.43179C6.33264 8.43179 6.64962 8.36711 6.95093 8.24146C7.24004 8.1195 7.50129 7.94395 7.72422 7.72036C7.94715 7.49677 8.12306 7.23622 8.24497 6.94611C8.37212 6.64675 8.43656 6.32707 8.43656 6C8.43656 5.67293 8.37212 5.35325 8.24497 5.0539C8.12306 4.76379 7.94715 4.50324 7.72422 4.27965C7.50129 4.05606 7.24004 3.88051 6.95093 3.75855C6.65136 3.63105 6.33264 3.56822 6.00347 3.56822C5.67429 3.56822 5.35731 3.63289 5.056 3.75855C4.76689 3.88051 4.50564 4.05606 4.28271 4.27965C4.05978 4.50324 3.88387 4.76379 3.76196 5.0539C3.63307 5.35325 3.57037 5.67108 3.57037 6Z" fill="#FFC823"/>
                                    <path d="M7.77819 6.00001C7.77819 6.47491 7.59358 6.92024 7.25744 7.25655C6.9213 7.59286 6.47544 7.77765 6.00171 7.77765C5.52798 7.77765 5.08211 7.59286 4.74597 7.25655C4.40984 6.92024 4.22522 6.47491 4.22522 6.00001C4.22522 5.52511 4.40984 5.07977 4.74597 4.74346C5.08211 4.409 5.52798 4.22422 6.00171 4.22422C6.47544 4.22422 6.9213 4.409 7.25744 4.74531C7.59358 5.07977 7.77819 5.52511 7.77819 6.00001ZM4.40461 6.00001C4.40461 6.42686 4.57007 6.82785 4.87312 7.12905C5.17442 7.4321 5.575 7.59841 6.00171 7.59841C6.42841 7.59841 6.82899 7.4321 7.1303 7.1309C7.43161 6.8297 7.5988 6.42871 7.5988 6.00186C7.5988 5.575 7.43335 5.17402 7.1303 4.87281C6.82899 4.57161 6.42841 4.40531 6.00171 4.40531C5.575 4.40531 5.17442 4.57161 4.87312 4.87281C4.57181 5.17217 4.40461 5.57315 4.40461 6.00001Z" fill="#FFC823"/>
                                </svg>
                            }
                            <span
                                className="list_item"
                                dangerouslySetInnerHTML={{ __html: item}}
                            />
                        </li>
                    )
                })}
            </ul>
        )
    }
}
