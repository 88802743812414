export default class Api {

    baseUrl = process.env.NODE_ENV === 'development' ? 'https://dplms.rs-test.ru/ajax-react/' : '/ajax-react/';

    getResource = async (url, body) => {
        const res = await fetch(`${this.baseUrl}${url}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/javascript, */*; q=0.01',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            // body: JSON.stringify(body)
            body: body !== undefined ? Object.keys(body).map(key => key + '=' + body[key]).join('&') : ''

        })
        const json = await res.json()
        if(json.status === 500 || json.type === 'Error'){
            window.location.href = '/error?r=500';
        } else
        return json; // Возвращение промиса
    }


// Список курсов с уроками
    getLessonCourses = async () => {
        return await this.getResource('get-lesson-courses')
    }


    // Список курсов с уроками
    getLessonStep = async (lesson_id, order) => {
        return await this.getResource('get-lesson-step', {
            lesson_id, order
        })
    }


    // Подсчет статистики для уроков.
    setLessonStats = async (id, step) => {
        return await this.getResource('set-lesson-stats',{
            id: id,
            step: step,
        })
    }


    // Список курсов с тестами
    getTestCourses = async () => {
        return await this.getResource('get-test-courses')
    }

    startTest = async (lId) => {
        return await this.getResource(`test-step?lId=${lId}`)
    }
    getTestStepForLessonStep = async (lId, lStep) => {
        return await this.getResource(`test-step-for-lesson-step?lId=${lId}&lStep=${lStep}`)
    }
    getTestStepForLessonStepCheck = async (lId, lStep,qId, answers) => {
        return await this.getResource(`test-step-for-lesson-step?lId=${lId}&lStep=${lStep}`, {
            qId: qId,
            answers: [answers]
        })
    }
    checkAnswer = async (lId, qId, answers) => {
        return await this.getResource(`test-step?lId=${lId}`, {
            qId: qId,
            answers: [answers]
        })
    }

    setLessonData = async (id, answer_type, answer, ext) => {
        return await this.getResource(`interactive-step-answer`, {
            id, answer_type, answer, ext
        })
    }

    setSession = async () => {
        return await this.getResource(`set-session`, {
        })
    }


    //////////\\\\\\\\\\\\\
    ///   NEW ACTIONS   \\\
    //////////\\\\\\\\\\\\\


    sendMessage = async (message, chat_id) => {
        return await this.getResource('set-chat-message',{
            message: message,
            chat_id: chat_id
        })
    }
    getMessages = async (chat_id) => {
        return await this.getResource('get-chat-message',{
            chat_id: chat_id
        })
    }
    getChats = async () => {
        return await this.getResource('get-available-chats',{})
    }
    getUsers = async () => {
        return await this.getResource('get-all-users',{})
    }
    createChat = async (users) => {
        return await this.getResource('create-chat',{
            users: users
        })
    }
    getChatBot = async () => {
        return await this.getResource('chat-bot-question',{})
    }

    getQuestionnaires = async (questionnaireId) => {
        return await this.getResource('get-questionnaire',{
            questionnaireId: questionnaireId
        })
    }
    setQuestionnaires = async (questionnaire_id, answers) => {
        return await this.getResource('set-questionnaire',{
            questionnaire_id: questionnaire_id,
            answers: answers
        })
    }

    logout = async () => {
        return await this.getResource('logout')
    }

    getUserData = async () => {
        return  await this.getResource('user-data');
    }
    getUserDataToken = async (token) => {
        return  await this.getResource('user-data-token', {token: token});
    }

    getAllForums = async () => {
        return await this.getResource('get-forums')
    }

    getAllPartners = async (count, forum_id) => {
        return await this.getResource('get-partners',{forum_id: forum_id})
    }
    getAllOrganizer = async (count, forum_id) => {
        return await this.getResource('get-organizers',{forum_id: forum_id})
    }
    getAllProjects = async (forum_id) => {
        forum_id = forum_id === undefined ?  '' : forum_id
        return await this.getResource('get-projects',{
            forum_id
        })
    }

    getForum = async (id) => {
        return await this.getResource('get-forum-day', {id: id})
    }

    saveEvent = async (id) => {
        return await this.getResource('save-event', {id: id})
    }

    getPartner = async (id) => {
        return await this.getResource('get-partner', {id: id})
    }
    getProject = async (id) => {
        return await this.getResource('get-project', {id: id})
    }

    getUserEvents = async () => {
        return await this.getResource('get-user-events')
    }
    getUserPoint = async () => {
        return await this.getResource('get-user-points')
    }
    getSeparatedEventsCount = async () => {
        return await this.getResource('get-separated-events-count')
    }
    registrationUser = async (inputs, user, token) => {
        console.log('user',user)
        let body = {}
        let phone2 = inputs.phone.value;
        phone2 = phone2.replace('(', '')
        phone2 = phone2.replace(')', '')
        phone2 = phone2.replace('+', '')
        phone2 = phone2.replace('-', '')
        Object.values(inputs).map(input => {
            if(
                input.name === 'name'
                || input.name === 'surname'
                || input.name === 'middle_name'
                || input.name === 'password'
                || input.name === 'email'
                || input.name === 'district_id'
            ){
                body['user[' + input.name + ']'] = input.value
            }else{
                if(input.name == 'phone'){
                    console.log(123)
                    body['profile[' + input.name + ']'] = phone2
                }else {
                    body['profile[' + input.name + ']'] = input.value
                }
            }
        })
        console.log(body)
        if(!user) {
            return await this.getResource('registration', body)
        }else {
            body['token'] = token
            return await this.getResource('registration-invite', body)

        }
    }
    login = async (email, password) => {
        return await this.getResource('login', {
            email: email,
            password: password,
        })
    }
    requestPasswordReset = async (email) => {
        return await this.getResource('request-password-reset', {
            email: email
        })
    }
    getKladr = async (region) => {
        return await this.getResource('kladr', {region})
    }
    getKladrCity = async (region, city) => {
        return await this.getResource('kladr-city', {region, city})
    }

    getUserSkills = async () => {
        return await this.getResource('get-user-skills')
    }

    getTotalPointsBySession = async () => {
        return await this.getResource('get-total-points-by-session')
    }

    getUsersRate = async () => {
        return await this.getResource('get-users-rate')
    }

    geTeamsRate = async () => {
        return await this.getResource('get-teams-rate')
    }
}

