import React, {useEffect, useState} from "react";
import Footer from "../../../pageComponents/footerMain/Footer";
import Header from "../../../pageComponents/headerMain/Header";
import Api from '../../../Service/Api'
import {
  BulletScreen,
  ExpertsScreen,
  TitleScreen,
} from "../../../pageComponents/projectScreens/projectScreens";
import creativeThinkingData from "./creativeThinkingData";

const CreativeThinking = () => {
    const [user, setUser] = useState()
    const [showLogin, setShowLogin] = useState(false)

    const getUserData = () => {
        new Api().getUserData()
            .then(user => setUser(user))
            .catch(e => console.log('error', e))
    }

  useEffect(() => {
    window.scrollTo(0, 0);
  });

    useEffect(() => {
        getUserData();
    }, []);

    const showLoginClick = (showLogin) => {
        setShowLogin(showLogin)
    }

  return (
    <main className="project-page">
      <Header
          user={user}
          showLogin={showLogin}
          showLoginClick={showLoginClick}
          getUserData={getUserData}
      />
      <TitleScreen data={creativeThinkingData.titleScreen} user={user} showLoginClick={showLoginClick} />
      <BulletScreen
        data={creativeThinkingData.bulletScreen}
        info_margin="80px 150px 0"
      />
      <BulletScreen
        data={creativeThinkingData.bulletScreenTwo}
        info_margin="80px 150px 0"
      />
      <ExpertsScreen data={creativeThinkingData.expertsScreen} />
      <Footer user={user}/>
    </main>
  );
};

export default CreativeThinking;
