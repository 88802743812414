import React from "react";
import img from 'assets/image/partnerEl.png'
import Api from "../../Service/Api";
import {Link} from "react-router-dom";

export default class Partners extends React.Component {

    Api = new Api()

    state = {
        partners: undefined
    }

    componentDidMount() {
        this.getPartners();
    }

    getPartners = () => {
        this.Api.getAllProjects(this.props.forum_id)
            .then(partners => this.setState({partners}))
            .catch(e => console.log('error', e))
    }

    render() {
        const {partners} = this.state;
        const {showDesc} = this.props;
        return (
            <div className='partners'>
                <div className="cnt">
                    <div className="partners__head">
                        Образовательные треки
                    </div>
                    <div className="partners__flex">
                        {partners && Object.values(partners).map((partner => {
                            return (
                                <div className="partner_el">
                                    {/*<Link to={'/project/' + partner.id} className="partner_el-icon">*/}
                                    <div className="partner_el-icon">
                                        <div className="partner_el-img">
                                            <img src={'https://bolshayaperemena.team/uploads/' + partner.image} alt=""/>
                                        </div>
                                    </div>
                                    {showDesc === 'true' ?
                                        <div className="partner_el-desc">
                                            <div className="name">{partner.name}</div>
                                            <div className="description" dangerouslySetInnerHTML={{__html: partner.description}}/>
                                        </div>
                                        :
                                        undefined
                                    }
                                </div>
                            )
                        }))}
                    </div>
                </div>
            </div>
        )
    }
}
