import React, {useEffect, useState} from "react";
import NewApi from "../../../Service/NewApi";
import Preloader from "../../../Components/Preloader/Preloader";

const LessonInteractive = ({answer, content, matches, stepId, setAnswerProps}) => {

    return (
        <div>
            <div className={'step__text'} dangerouslySetInnerHTML={{__html: content.content}}/>
            {content.answer_type === 'matching' && _renderMatches(matches, setAnswerProps)}
            {content.answer_type === 'free' && _renderFree(setAnswerProps, answer)}
            {content.answer_type === 'upload' && _renderUpload(setAnswerProps, stepId)}
        </div>
    )
}
let matchesOut = [];

const _renderMatches = (matches, setAnswerProps) => {
    console.log(matches)
    let matchesIn = [];
    let matchesOutAll = [];
    matches.map((match, index) => {
        matchesIn.push(match.in.name)
        matchesOut[index] = [];
        match.out.map(out => {
            matchesOut[index].push(out.name)
            matchesOut[index].sort()
            matchesOutAll.push(out.name)
        })
    })
    matchesIn = Array.from(new Set(matchesIn))
    matchesOut = Array.from(new Set(matchesOut))
    matchesOutAll = Array.from(new Set(matchesOutAll))
    const shuffle = (array) => {
        return array.sort(() => Math.random() - 0.5);
    }
    return (
        <div>
            {matchesIn.map(matchIn => {
                return (
                    <div className={'matches'}>
                        <div className="matches__in">
                            {matchIn}
                        </div>
                        <div className="matches__out">
                            {shuffle(matchesOutAll).map(matchOut => {
                            // {(matchesOutAll).map(matchOut => {
                                return (
                                    <label>
                                        <input type='checkbox' className={'checkbox'}
                                               value={matchOut}
                                           onChange={(e) => setAnswer(e, setAnswerProps)}
                                        />
                                        <span className="checkBox"/>
                                        {matchOut}
                                    </label>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const _renderFree = (setAnswerProps, answer) => {
    console.log('_renderFree', answer)
    return (
        <div>
            <textarea 
                className={'step__textArea'}
                value={answer}
                onChange={(e) => {setAnswerProps(e.target.value)}}
            >
            </textarea>
        </div>
    )
}

const _renderUpload = (setAnswerProps, stepId) => {
    const [files, setFiles] = useState([]);
    const [links, setLinks] = useState([]);
    const [preloaderActive, setPreloaderActive] = useState(false)

    const Api = new NewApi();

    //Получаем сохраненный черновик файлов
    const getDraft = () => {
        setPreloaderActive(true)
        Api.getUserUploadDrafts(stepId)
        .then(res => {
            setPreloaderActive(false)
            setFiles([])
            setLinks([])
            if (res.length > 0) {
                setAnswerProps(res[0].filename, res[0].fileExtantion)
            } else {
                setAnswerProps('', '')
            }
            res.map((file) => {
                if(file.type === 'file'){
                    setFiles(prev => [...prev, {
                        fileGuid: file.guid,
                        fileValue: file.filename,
                        fileExtantion: file.filename.split('.').pop(),
                        fileDescription: file.description,
                        fileInBase64: ''
                    }])
                }
                else {
                    setLinks(prev => [...prev, {
                        guid: file.guid,
                       link: file.filename,
                       description: file.description
                    }])
                }
            })
        })
    }

    useEffect(() => {
        getDraft()
    }, [])

    //Сохраняем черновик
    const saveDraft = (file) => {
        console.log(file.type)
        if(file.type === 'file'){
            Api.setUserUploadDraft(
                '',
                stepId,
                file.fileValue,
                file.fileDescription,
                file.fileExtantion,
                file.fileInBase64,
            )
                .then(res => getDraft())
                .catch(e => console.log('error', e))
        }
        else {
            Api.setUserUploadDraft(
                file.linkValue,
                stepId,
                '',
                file.linkDescription,
            )
                .then(res => getDraft())
                .catch(e => console.log('error', e))
        }

        // files.forEach(file => {
        //     Api.setUserUploadDraft(
        //         file.fileValue,
        //         file.fileExtantion,
        //         file.fileDescription,
        //         stepId,
        //         file.fileInBase64,
        //     )
        //     .then(res => console.log(444, res))
        //     .catch(e => console.log('error', e))
        // })
    }

    //Добавляем файл в общий список
    const addFileToList = (file) => {
        setPreloaderActive(true)
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            const fileData = {
                type: 'file',
                fileGuid: '',
                fileValue: file.name,
                fileExtantion: file.name.split('.').pop(),
                fileDescription: '',
                fileInBase64: reader.result
            }
            saveDraft(fileData)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    //Записываем в state описание файла
    const changeFileDescription = (text, fileIndex) => {
        const newFilesList = files.map((file, index) => {
            if (fileIndex === index) {
                return {...file, fileDescription: text}
            } else {
                return file
            }
        })
        setFiles(newFilesList)
    }

    const changeLinkDescription = (text, fileIndex) => {
        const newLinksList = links.map((link, index) => {
            if (fileIndex === index) {
                return {...link, description: text}
            } else {
                return link
            }
        })
        setLinks(newLinksList)
    }

    //Удаляем файл из списка
    const deleteFile = (type, fileIndex) => {
        let guid = ''
        if(type === 'file'){
            guid = files[fileIndex].fileGuid
            // setFiles(files.filter((file, index) => index !== fileIndex))
        }
        else {
            guid = links[fileIndex].guid
        }

        if (guid) {
            setPreloaderActive(true)
            Api.deleteUserUploadDrafts(guid)
            .then(res => {
                getDraft();
            })
            .catch(e => console.log('error', e))
        }

        if(document.querySelector('input[type="file"]')){
            document.querySelector('input[type="file"]').value = ''
        }
    }

    const saveDescription = (type, fileIndex) => {
        let guid = ''
        let description = ''
        if(type === 'file'){
            guid = files[fileIndex].fileGuid
            description = files[fileIndex].fileDescription
            setPreloaderActive(true)
        }
        else {
            guid = links[fileIndex].guid
            description = links[fileIndex].description
            setPreloaderActive(true)
        }
        Api.updateUserUploadDraftDesc(guid, description)
            .then(res => {
                getDraft();
                setPreloaderActive(false)
            })
            .catch(e => setPreloaderActive(false))
    }

    const setLink = (e) => {
        const fileData = {
            type: 'link',
            linkGuid: '',
            linkValue: e.target.value,
            linkDescription: '',
        }
        saveDraft(fileData)
        e.target.value = ''
    }

    return (
        <div>
            <Preloader isActive={preloaderActive} />
            <div className="step__file-buttons">
                <div className={'step__file'}>
                    <input type="file"
                        onChange={e => {
                            addFileToList(e.target.files[0])
                            getBase64(e.target.files[0], setAnswerProps)
                        }}
                    />
                    <span className="button button__orange">Загрузить файл</span>
                </div>
                <div className="step__link">
                    <p>или укажите ссылку на загруженный файл на другом ресурсе</p>
                    <input className="step__file-input" type="text" onBlur={e => {setLink(e)}}/>
                </div>
                {/* {files.length > 0
                    && (
                        <button
                            className="button button__orange"
                            type="button"
                            onClick={saveDraft}
                        >
                            Сохранить черновик
                        </button>
                    )} */}
            </div>
            <div className="step__selected-files">
                {files.length > 0
                    && (
                        <div className="step__files">
                            <div className="step__files-area">
                                Прикрепленные файлы:
                            </div>
                            <div className="step__file-list">
                                {files.map((fileItem, index) => {
                                    return (
                                        <div key={fileItem.fileValue} className="step__file-item">
                                            <div className="step__file-name">
                                                {fileItem.fileValue}
                                            </div>
                                            <div className="step__file-cell">
                                                <input
                                                    type="text"
                                                    className="step__file-input"
                                                    placeholder="Добавьте описание"
                                                    value={files[index].fileDescription}
                                                    onChange={(e) => changeFileDescription(e.target.value, index)}
                                                />
                                            </div>
                                            <div className="step__file-buttons">
                                                <button
                                                    className="step__file-delete step__file-delete--red"
                                                    type="button"
                                                    onClick={() => saveDescription('file', index)}
                                                >
                                                    Сохранить описание
                                                </button>
                                                <button
                                                    className="step__file-delete"
                                                    type="button"
                                                    onClick={() => deleteFile('file', index)}
                                                >
                                                    Удалить
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                {links.length > 0 && (
                    <div className="step__files">
                        <div className="step__files-area">
                            Указанные линки:
                        </div>
                        <div className="step__file-list">
                            {links.map((link, index) => {
                                return (
                                    <div key={index} className="step__file-item">
                                        <div className="step__file-name">
                                            {link.link}
                                        </div>
                                        <div className="step__file-cell">
                                            <input
                                                type="text"
                                                className="step__file-input"
                                                placeholder="Добавьте описание"
                                                value={link.description}
                                                onChange={(e) => changeLinkDescription(e.target.value, index)}
                                            />
                                        </div>
                                        <div className="step__file-buttons">
                                            <button
                                                className="step__file-delete step__file-delete--red"
                                                type="button"
                                                onClick={() => saveDescription('link',index)}
                                            >
                                                Сохранить описание
                                            </button>
                                            <button
                                                className="step__file-delete"
                                                type="button"
                                                onClick={() => deleteFile('link', index)}
                                            >
                                                Удалить
                                            </button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

const getBase64 = (file, setAnswerProps) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        console.log(file.name.split('.')[file.name.split('.').length - 1])
        setAnswerProps(reader.result, file.name.split('.')[file.name.split('.').length - 1])
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

const setAnswer = (e, setAnswerProps) => {
    let matches = document.getElementsByClassName('matches')
    let answer = '';
    let checked = [];
    for(let i=0; i<matches.length; i++){
        answer += matches[i].querySelector('.matches__in').textContent + '-';
        let matches__out = matches[i].getElementsByClassName('matches__out');
        for (let j=0; j<matches__out.length; j++){
            answer += getCheckedCheckBoxes(matches__out[j]).toString() + ';'
            checked[i] = getCheckedCheckBoxes(matches__out[j]).sort()
        }
    }

    console.log('checked', checked)
    setAnswerProps(answer,undefined, checked, matchesOut)
}

const getCheckedCheckBoxes = (el) => {
    let checkboxes = el.getElementsByClassName('checkbox');
    let checkboxesChecked = []; // можно в массиве их хранить, если нужно использовать
    for (let index = 0; index < checkboxes.length; index++) {
        if (checkboxes[index].checked) {
            checkboxesChecked.push(checkboxes[index].value); // положим в массив выбранный
        }
    }
    return checkboxesChecked; // для использования в нужном месте
}

export default LessonInteractive