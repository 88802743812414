import React from "react";
import Api from "../../Service/Api";
import { Link } from "react-router-dom";

export default class Account extends React.Component {
    Api = new Api();


    render() {
        const {user, showLoginClick} = this.props
        return (
            <div className="header__account">
                {user !== undefined &&
                (user === false ?
                        <a href={'https://bolshaya-peremena.academy/auth/login'}
                           className="header__account-link"
                           // onClick={() => showLoginClick('auth')}
                        >
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.0001 29.3333C8.63608 29.3333 2.66675 23.364 2.66675 16C2.66675 8.63599 8.63608 2.66666 16.0001 2.66666C23.3641 2.66666 29.3334 8.63599 29.3334 16C29.3334 23.364 23.3641 29.3333 16.0001 29.3333ZM9.35075 24.3413C11.2381 25.8503 13.5837 26.6705 16.0001 26.6667C18.6267 26.6667 21.0307 25.7173 22.8894 24.144C22.021 23.253 20.9828 22.5452 19.8361 22.0624C18.6894 21.5796 17.4576 21.3317 16.2134 21.3333C14.9235 21.3318 13.6474 21.5985 12.466 22.1163C11.2847 22.6341 10.2238 23.3918 9.35075 24.3413ZM7.48808 22.4267C8.60869 21.2374 9.96097 20.2903 11.4616 19.6436C12.9622 18.997 14.5794 18.6645 16.2134 18.6667C17.7889 18.6646 19.3494 18.9738 20.8051 19.5765C22.2608 20.1792 23.5831 21.0635 24.6961 22.1787C25.8375 20.5722 26.5116 18.6808 26.6435 16.7145C26.7754 14.7482 26.36 12.7838 25.4434 11.0393C24.5268 9.29469 23.1448 7.83815 21.4507 6.83125C19.7566 5.82435 17.8167 5.30644 15.8462 5.33499C13.8757 5.36354 11.9516 5.93744 10.2874 6.993C8.62325 8.04856 7.28403 9.54453 6.41834 11.3149C5.55265 13.0853 5.19434 15.0609 5.38317 17.0226C5.572 18.9842 6.30058 20.8552 7.48808 22.428V22.4267ZM16.0001 17.3333C14.5856 17.3333 13.229 16.7714 12.2288 15.7712C11.2287 14.771 10.6667 13.4145 10.6667 12C10.6667 10.5855 11.2287 9.22895 12.2288 8.22875C13.229 7.22856 14.5856 6.66666 16.0001 6.66666C17.4146 6.66666 18.7711 7.22856 19.7713 8.22875C20.7715 9.22895 21.3334 10.5855 21.3334 12C21.3334 13.4145 20.7715 14.771 19.7713 15.7712C18.7711 16.7714 17.4146 17.3333 16.0001 17.3333ZM16.0001 14.6667C16.7073 14.6667 17.3856 14.3857 17.8857 13.8856C18.3858 13.3855 18.6667 12.7072 18.6667 12C18.6667 11.2927 18.3858 10.6145 17.8857 10.1144C17.3856 9.61427 16.7073 9.33332 16.0001 9.33332C15.2928 9.33332 14.6146 9.61427 14.1145 10.1144C13.6144 10.6145 13.3334 11.2927 13.3334 12C13.3334 12.7072 13.6144 13.3855 14.1145 13.8856C14.6146 14.3857 15.2928 14.6667 16.0001 14.6667Z" fill="#E53130"/>
                            </svg>
                            <span className="header__account-text">Войти</span>
                        </a>
                        :
                        <Link to={'/profile'}
                            className="header__account-link">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.0001 29.3333C8.63608 29.3333 2.66675 23.364 2.66675 16C2.66675 8.63599 8.63608 2.66666 16.0001 2.66666C23.3641 2.66666 29.3334 8.63599 29.3334 16C29.3334 23.364 23.3641 29.3333 16.0001 29.3333ZM9.35075 24.3413C11.2381 25.8503 13.5837 26.6705 16.0001 26.6667C18.6267 26.6667 21.0307 25.7173 22.8894 24.144C22.021 23.253 20.9828 22.5452 19.8361 22.0624C18.6894 21.5796 17.4576 21.3317 16.2134 21.3333C14.9235 21.3318 13.6474 21.5985 12.466 22.1163C11.2847 22.6341 10.2238 23.3918 9.35075 24.3413ZM7.48808 22.4267C8.60869 21.2374 9.96097 20.2903 11.4616 19.6436C12.9622 18.997 14.5794 18.6645 16.2134 18.6667C17.7889 18.6646 19.3494 18.9738 20.8051 19.5765C22.2608 20.1792 23.5831 21.0635 24.6961 22.1787C25.8375 20.5722 26.5116 18.6808 26.6435 16.7145C26.7754 14.7482 26.36 12.7838 25.4434 11.0393C24.5268 9.29469 23.1448 7.83815 21.4507 6.83125C19.7566 5.82435 17.8167 5.30644 15.8462 5.33499C13.8757 5.36354 11.9516 5.93744 10.2874 6.993C8.62325 8.04856 7.28403 9.54453 6.41834 11.3149C5.55265 13.0853 5.19434 15.0609 5.38317 17.0226C5.572 18.9842 6.30058 20.8552 7.48808 22.428V22.4267ZM16.0001 17.3333C14.5856 17.3333 13.229 16.7714 12.2288 15.7712C11.2287 14.771 10.6667 13.4145 10.6667 12C10.6667 10.5855 11.2287 9.22895 12.2288 8.22875C13.229 7.22856 14.5856 6.66666 16.0001 6.66666C17.4146 6.66666 18.7711 7.22856 19.7713 8.22875C20.7715 9.22895 21.3334 10.5855 21.3334 12C21.3334 13.4145 20.7715 14.771 19.7713 15.7712C18.7711 16.7714 17.4146 17.3333 16.0001 17.3333ZM16.0001 14.6667C16.7073 14.6667 17.3856 14.3857 17.8857 13.8856C18.3858 13.3855 18.6667 12.7072 18.6667 12C18.6667 11.2927 18.3858 10.6145 17.8857 10.1144C17.3856 9.61427 16.7073 9.33332 16.0001 9.33332C15.2928 9.33332 14.6146 9.61427 14.1145 10.1144C13.6144 10.6145 13.3334 11.2927 13.3334 12C13.3334 12.7072 13.6144 13.3855 14.1145 13.8856C14.6146 14.3857 15.2928 14.6667 16.0001 14.6667Z" fill="#E53130"/>
                            </svg>
                            <span className="header__account-text">
                                    {user.name} {user.surname} <br/>
                            </span>
                        </Link>
                )
                }
            </div>

        )
    }
}
