import React from "react";
import { NavLink } from "react-router-dom";

const Button = ({ type, content, isLink, isNavLink, path, onClick }) => {
  const renderContent = () => (
    <>
      <span className="button__text">{content}</span>
      <div className="button__bg"></div>
      <div className="button__bg-next"></div>
    </>
  );
  const className = `button button--${type}`;

  return (
    <>
      {isLink && path && (
        <a className={className} href={path}>
          {renderContent()}
        </a>
      )}
      {isNavLink && path && (
        <NavLink className={className} to={path}>
          {renderContent()}
        </NavLink>
      )}
      {!isLink && !isNavLink && (
        <button onClick={onClick} type="button" className={className}>
          {renderContent()}
        </button>
      )}
    </>
  );
};

export default Button;
