import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { ReactComponent as CloseIcon } from "../../images/close.svg";
import successImg from "../../images/success-img.png";
import Button from "../buttonMain/Button";
import axios from "axios";

const Modal = ({ onClose }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    document.body.className = "menu-opened";
    return () => {
      document.body.className = "";
    };
  });

  const fieldsData = [
    {
      name: "name",
      placeholder: "Имя",
    },
    {
      name: "surname",
      placeholder: "Фамилия",
    },
    {
      name: "patronymic",
      placeholder: "Отчество",
    },
    {
      name: "email",
      placeholder: "Почта",
      type: "email",
    },
    {
      name: "media_link",
      placeholder: "Ссылка на социальные сети",
    },
    {
      name: "course",
      placeholder: "Название курса",
    },
  ];

  const onSubmit = (values) => {
    axios
      .post("http://80.87.201.195/api/requests/", values)
      .then(() => setSuccess(true))
      .catch(() => setError(true));
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Обязательное поле"),
    surname: Yup.string().required("Обязательное поле"),
    patronymic: Yup.string().required("Обязательное поле"),
    email: Yup.string()
      .email("Введите валидный e-mail")
      .required("Обязательное поле"),
    media_link: Yup.string().required("Обязательное поле"),
    course: Yup.string().required("Обязательное поле"),
  });

  const renderForm = () => (
    <Formik
      initialValues={{
        name: "",
        surname: "",
        patronymic: "",
        email: "",
        media_link: "",
        course: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form className="form">
          <div className="form__header">
            <h3 className="form__title">Пожалуйста, заполните форму</h3>
            <button type="button" className="form__close" onClick={onClose}>
              <CloseIcon />
            </button>
          </div>
          {fieldsData.map((field) => (
            <Field
              key={field.name}
              // className={`form__input ${
              //   errors[field.name as keyof FormValues] &&
              //   touched[field.name as keyof FormValues]
              //     ? "form__input--error"
              //     : ""
              // }`}
              id={field.name}
              name={field.name}
              type={field.type ? field.type : "text"}
              placeholder={field.placeholder}
            />
          ))}
          <div className="form__footer">
            <button
              className="button button--violet form__button"
              type="submit"
            >
              <span className="button__text">зарегистрироваться</span>
              <div className="button__bg"></div>
              <div className="button__bg-next"></div>
            </button>
            {Object.keys(errors).length > 0 && (
              <p className="form__error">
                Какое-то из полей не заполнено или введено неверно!
              </p>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );

  const renderMessage = (isError= false) => (
    <div className="message">
      <h3 className="message__title">
        {isError ? "Ошибка сервера :(" : "Спасибо, мы скоро с вами свяжемся!"}
      </h3>
      <img src={successImg} alt="" className="message__img" />
      <Button type="violet" content="Вернуться назад" onClick={onClose} />
    </div>
  );

  return (
    <div className="modal">
      <div className="modal__content">
        {success || error ? renderMessage(error) : renderForm()}
      </div>
    </div>
  );
};

export default Modal;
