import React, {useState, useEffect} from 'react';
import PanelStudy from "../../Components/PanelStudy";
import CourseStat from "../../Components/CourseStat";
import './style.sass'
import Api from "../../Service/NewApi";
// import NewApi from "../../Service/NewApi";
import nullImg from '../../assets/image/no-image.png';
import TrainingMenu from "../../pageComponents/trainingMenu";
import {Link} from "react-router-dom";

const MyTraining = () => {
    const api = new Api();
    const [courses, setCourses] = useState({})
    const [testList, setTestList] = useState({})
    const [changesCourses, setChangesCourses] = useState({})
    const [tabs, setTabs] = useState([true, false, false])
    const [showSearch, setShowSearch] = useState(false)
    const [search, setSearch] = useState()
    const getCoursesLesson = () => {
        api.getCoursesLesson()
            .then(res => {
                setCourses(res)
                setChangesCourses(res)
            })
    }

    const getTests = () => {
        api.getCoursesTests()
            .then(res => {
                setTestList(res.coursesTests)
            })
            .catch(res => console.log(res))
    }


    const tabsHandler = (e) => {
        let type = e.target.getAttribute('data-type')
        changeCoursesList(type)
        let tabsCollection = [...document.querySelectorAll('.courseCard__tab')]
        tabsCollection.map((tab, index) => tab.classList.remove('active'))
        e.target.classList.add('active')
    }

    const changeCoursesList = (type) => {
        switch (type) {
            case 'myCourses':
                setTabs([true, false, false])
                setSearch('')
                break;
            // case 'specialCourses':
            //     setTabs([false, true, false])
            //     setSearch('')
            //     break;
            case 'allCourses':
                setTabs([false, false, true])
                setSearch('')
                break;
            default:
                console.log('Data attribute no found!')
        }
    }

    const selectHandler = (e) => {
        let select = e.target.closest('.courseCard__select')
        select.querySelector('.courseCard__select-body').classList.toggle('active')
        select.querySelector('.courseCard__select-arrow').classList.toggle('active')
    }

    const optionHandler = (e) => {
        let select = e.target.closest('.courseCard__select')
        select.querySelector('.courseCard__select-body').classList.toggle('active')
        select.querySelector('.courseCard__select-arrow').classList.toggle('active')
        select.querySelector('.courseCard__select-selected').textContent = e.target.textContent
        let action = e.target.getAttribute('data-action')
        selectSelected(action)
    }

    const selectSelected = (action) => {
        switch (action) {
            case 'dateStart':
                let dateStart = reverseObj(courses.coursesLessons, 'dateStart')
                setChangesCourses({...changesCourses, coursesLessons: dateStart})
                break;
            case 'dateEnd':
                let dateEnd = reverseObj(courses.coursesLessons, 'dateEnd')
                setChangesCourses({...changesCourses, coursesLessons: dateEnd})
                break;
            case 'nameStart':
                let nameStart = alphabetObj(courses.coursesLessons, 'nameStart')
                setChangesCourses({...changesCourses, coursesLessons: nameStart})
                break;
            case 'nameEnd':
                let nameEnd = alphabetObj(courses.coursesLessons, 'nameEnd')
                setChangesCourses({...changesCourses, coursesLessons: nameEnd})
                break;
            default:
                console.log('Select not selected!')
        }
    }

    const reverseObj = (obj, action) => {
        let newObj = {}
        switch (action) {
            case 'dateStart':
                Object.keys(obj)
                    .sort()
                    .forEach((key) => {
                        newObj[key] = obj[key]
                    })
                break
            case 'dateEnd':
                Object.keys(obj)
                    .sort()
                    .reverse()
                    .forEach((key) => {
                        newObj[key] = obj[key]
                    })
                break
            default:
                console.log("Action don't set!")
        }
        return newObj
    }

    const alphabetObj = (obj, action) => {
        let newObj = {}
        let alphabetArray = []
        Object.entries(obj).map(([key, value]) => {
            alphabetArray.push(value.name)
        })
        switch (action) {
            case 'nameStart':
                alphabetArray.sort()
                break
            case 'nameEnd':
                alphabetArray.sort().reverse()
                break
            default:
                console.log("Action don't set!")
        }
        alphabetArray.map(elem => {
            Object.entries(obj).map(([key, value]) => {
                if(elem === value.name){
                    newObj[key] = value
                }
            })
        })
        return newObj
    }

    const createLabel = (number, titles) => {
        const cases = [2, 0, 1, 1, 1, 2];
        return `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
    }

    const lengthTest = (id) => {
        let key = 'c' + id
        let testLength = 0
        if (testList[key] !== undefined && typeof testList[key].tests !== 'undefined') {
            testLength = Object.keys(testList[key].tests).length
        }
        return testLength
    }

    useEffect(() => {
        getCoursesLesson()
        getTests()
    }, [])

    return(
        <>
            <div className="courseCard__tabs">
                <div className="courseCard__tab" onClick={(e) => tabsHandler(e)} data-type="myCourses">Мои курсы</div>
                {/*<div className="courseCard__tab" onClick={(e) => tabsHandler(e)} data-type="specialCourses">Специализированные курсы</div>*/}
                <div className="courseCard__tab" onClick={(e) => tabsHandler(e)} data-type="allCourses">Все курсы</div>
                {!showSearch &&
                    <div className="courseCard__search-icon" onClick={() => setShowSearch(!showSearch)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
                            <path d="M31.85 34.125L21.6125 23.8875C20.8 24.5375 19.8656 25.0521 18.8094 25.4312C17.7531 25.8104 16.6292 26 15.4375 26C12.4854 26 9.98725 24.9773 7.943 22.932C5.89875 20.8867 4.87608 18.3885 4.875 15.4375C4.875 12.4854 5.89767 9.98725 7.943 7.943C9.98833 5.89875 12.4865 4.87608 15.4375 4.875C18.3896 4.875 20.8878 5.89767 22.932 7.943C24.9763 9.98833 25.9989 12.4865 26 15.4375C26 16.6292 25.8104 17.7531 25.4312 18.8094C25.0521 19.8656 24.5375 20.8 23.8875 21.6125L34.125 31.85L31.85 34.125ZM15.4375 22.75C17.4688 22.75 19.1956 22.0388 20.618 20.6164C22.0404 19.194 22.7511 17.4677 22.75 15.4375C22.75 13.4062 22.0388 11.6794 20.6164 10.257C19.194 8.83458 17.4677 8.12392 15.4375 8.125C13.4062 8.125 11.6794 8.83621 10.257 10.2586C8.83458 11.681 8.12392 13.4073 8.125 15.4375C8.125 17.4688 8.83621 19.1956 10.2586 20.618C11.681 22.0404 13.4073 22.7511 15.4375 22.75Z" fill="black"/>
                        </svg>
                    </div>
                }
                {showSearch &&
                    <div className="courseCard__search">
                        <input
                            type="text" id="courseSearch"
                            placeholder="Название курса"
                            onChange={v=>setSearch(v.target.value?.toLowerCase())}
                        />
                    </div>
                }
            </div>
            <h2 className="courseCard__header">Мои активные курсы</h2>
            <div className="courseCard__container">
                {tabs[0] &&
                    <div className="courseCard__tab-container">
                        {Object.keys(courses).length > 0 ?
                            Object.values(courses.coursesLessons).map((course, index) => {
                                if((typeof search === 'undefined' || search.length === 0) || course.name.toLowerCase().includes(search))
                                    return (
                                        <Link to={`/lessons/${course.id}`} className="courseCard" key={index}>
                                            <div className="courseCard__left-side">
                                                <div className="courseCard__image">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M12.761 8.37551V8.26876C12.761 7.67551 12.7802 6.90026 13.0497 6.13026C13.502 4.8477 14.3415 3.73731 15.4522 2.95262C16.563 2.16793 17.89 1.74769 19.25 1.75001H22.75C24.1103 1.74733 25.4377 2.16739 26.5488 2.95211C27.6599 3.73683 28.4997 4.84742 28.952 6.13026C29.2198 6.90026 29.2407 7.67551 29.2407 8.26876V8.37376C31.3802 8.99969 33.2631 10.2939 34.614 12.0671C35.9649 13.8402 36.7128 15.9992 36.7483 18.228L36.75 18.543V22.6083C36.5668 22.6082 36.3856 22.6464 36.218 22.7203C31.4237 24.8354 26.241 25.9278 21.0009 25.9278C15.7607 25.9278 10.5781 24.8354 5.78375 22.7203C5.61549 22.6466 5.43367 22.6091 5.25 22.61V18.2298C5.28597 16.001 6.0343 13.8423 7.38549 12.0695C8.73667 10.2966 10.6198 9.00279 12.7592 8.37726L12.761 8.37551ZM15.5295 6.98601C15.8008 6.21635 16.3045 5.55001 16.9711 5.07912C17.6376 4.60823 18.4339 4.35607 19.25 4.35751H22.75C24.4702 4.35751 25.935 5.45301 26.4705 6.98601C26.551 7.21351 26.5895 7.47601 26.6052 7.80851C22.8953 7.17851 19.1047 7.17851 15.3947 7.80851C15.4105 7.47601 15.449 7.21351 15.5295 6.98601ZM16.1875 22.1743C16.1875 21.4533 16.7755 20.8705 17.5 20.8705H24.5C25.2245 20.8705 25.8125 21.4533 25.8125 22.1743C25.812 22.3461 25.7777 22.5161 25.7115 22.6747C25.6453 22.8332 25.5484 22.9771 25.4265 23.0982C25.3046 23.2193 25.16 23.3152 25.0011 23.3804C24.8421 23.4455 24.6718 23.4787 24.5 23.478H17.5C17.3282 23.4787 17.1579 23.4455 16.9989 23.3804C16.84 23.3152 16.6954 23.2193 16.5735 23.0982C16.4516 22.9771 16.3547 22.8332 16.2885 22.6747C16.2223 22.5161 16.188 22.3461 16.1875 22.1743Z" fill="white"/>
                                                        <path d="M36.75 25.3348C35.175 26.0033 33.5667 26.5668 31.9375 27.0253V29.127C31.937 29.2988 31.9027 29.4689 31.8365 29.6274C31.7703 29.786 31.6734 29.9299 31.5515 30.051C31.4296 30.1721 31.285 30.2679 31.1261 30.3331C30.9671 30.3983 30.7968 30.4315 30.625 30.4308C30.4532 30.4315 30.2829 30.3983 30.1239 30.3331C29.965 30.2679 29.8204 30.1721 29.6985 30.051C29.5766 29.9299 29.4797 29.786 29.4135 29.6274C29.3473 29.4689 29.313 29.2988 29.3125 29.127V27.671C21.2414 29.3701 12.8435 28.5548 5.25 25.3348V28.0403C5.25445 29.8326 5.87083 31.5696 6.99709 32.9638C8.12335 34.358 9.69196 35.3258 11.4433 35.707C17.738 37.0965 24.262 37.0965 30.5585 35.707C32.3092 35.3256 33.8772 34.3579 35.003 32.964C36.1289 31.5702 36.7452 29.8338 36.75 28.042V25.3348Z" fill="white"/>
                                                    </svg>
                                                </div>
                                                <p className="courseCard__progress">{course.progress}%<br/>Пройдено</p>
                                            </div>
                                            <div className="courseCard__right-side">
                                                <h2 className="courseCard__title">{course.name}</h2>
                                                <p className="courseCard__description">{course.description}</p>
                                                <div className="courseCard__statistics">
                                                    <div>{Object. keys(course.lessons). length + " " + createLabel(Object. keys(course.lessons). length, ['урок', 'урока', 'уроков'])}</div>
                                                    <div>{lengthTest(course.id) + " " + createLabel(lengthTest(course.id), ['тест', 'теста', 'тестов'])}</div>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                            }) :
                            <div></div>
                        }
                    </div>
                }
                {/*{tabs[1] &&*/}
                {/*    <div className="courseCard__tab-container">*/}
                {/*        <div className="courseCard__sorted">*/}
                {/*            <div className="courseCard__select" style={{padding:0}}>*/}
                {/*                <input*/}
                {/*                    style={{*/}
                {/*                        height: '45px',*/}
                {/*                        padding: '0 25px 0 25px',*/}
                {/*                        border: 0,*/}
                {/*                        width: '100%',*/}
                {/*                        borderRadius: '30px',*/}
                {/*                        fontFamily: "Montserrat"*/}
                {/*                    }}*/}
                {/*                    type="text" id="courseSearch"*/}
                {/*                    placeholder="Название курса"*/}
                {/*                    onChange={v=>setSearch(v.target.value?.toLowerCase())}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        {Object.keys(courses).length > 0 ?*/}
                {/*            Object.values(courses.coursesLessons).map((course, index) => {*/}
                {/*                return (*/}
                {/*                    <Link to={`/lessons/${course.id}`} className="courseCard" key={index}>*/}
                {/*                        <div className="courseCard__left-side">*/}
                {/*                            <div className="courseCard__image">*/}
                {/*                                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">*/}
                {/*                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.761 8.37551V8.26876C12.761 7.67551 12.7802 6.90026 13.0497 6.13026C13.502 4.8477 14.3415 3.73731 15.4522 2.95262C16.563 2.16793 17.89 1.74769 19.25 1.75001H22.75C24.1103 1.74733 25.4377 2.16739 26.5488 2.95211C27.6599 3.73683 28.4997 4.84742 28.952 6.13026C29.2198 6.90026 29.2407 7.67551 29.2407 8.26876V8.37376C31.3802 8.99969 33.2631 10.2939 34.614 12.0671C35.9649 13.8402 36.7128 15.9992 36.7483 18.228L36.75 18.543V22.6083C36.5668 22.6082 36.3856 22.6464 36.218 22.7203C31.4237 24.8354 26.241 25.9278 21.0009 25.9278C15.7607 25.9278 10.5781 24.8354 5.78375 22.7203C5.61549 22.6466 5.43367 22.6091 5.25 22.61V18.2298C5.28597 16.001 6.0343 13.8423 7.38549 12.0695C8.73667 10.2966 10.6198 9.00279 12.7592 8.37726L12.761 8.37551ZM15.5295 6.98601C15.8008 6.21635 16.3045 5.55001 16.9711 5.07912C17.6376 4.60823 18.4339 4.35607 19.25 4.35751H22.75C24.4702 4.35751 25.935 5.45301 26.4705 6.98601C26.551 7.21351 26.5895 7.47601 26.6052 7.80851C22.8953 7.17851 19.1047 7.17851 15.3947 7.80851C15.4105 7.47601 15.449 7.21351 15.5295 6.98601ZM16.1875 22.1743C16.1875 21.4533 16.7755 20.8705 17.5 20.8705H24.5C25.2245 20.8705 25.8125 21.4533 25.8125 22.1743C25.812 22.3461 25.7777 22.5161 25.7115 22.6747C25.6453 22.8332 25.5484 22.9771 25.4265 23.0982C25.3046 23.2193 25.16 23.3152 25.0011 23.3804C24.8421 23.4455 24.6718 23.4787 24.5 23.478H17.5C17.3282 23.4787 17.1579 23.4455 16.9989 23.3804C16.84 23.3152 16.6954 23.2193 16.5735 23.0982C16.4516 22.9771 16.3547 22.8332 16.2885 22.6747C16.2223 22.5161 16.188 22.3461 16.1875 22.1743Z" fill="white"/>*/}
                {/*                                    <path d="M36.75 25.3348C35.175 26.0033 33.5667 26.5668 31.9375 27.0253V29.127C31.937 29.2988 31.9027 29.4689 31.8365 29.6274C31.7703 29.786 31.6734 29.9299 31.5515 30.051C31.4296 30.1721 31.285 30.2679 31.1261 30.3331C30.9671 30.3983 30.7968 30.4315 30.625 30.4308C30.4532 30.4315 30.2829 30.3983 30.1239 30.3331C29.965 30.2679 29.8204 30.1721 29.6985 30.051C29.5766 29.9299 29.4797 29.786 29.4135 29.6274C29.3473 29.4689 29.313 29.2988 29.3125 29.127V27.671C21.2414 29.3701 12.8435 28.5548 5.25 25.3348V28.0403C5.25445 29.8326 5.87083 31.5696 6.99709 32.9638C8.12335 34.358 9.69196 35.3258 11.4433 35.707C17.738 37.0965 24.262 37.0965 30.5585 35.707C32.3092 35.3256 33.8772 34.3579 35.003 32.964C36.1289 31.5702 36.7452 29.8338 36.75 28.042V25.3348Z" fill="white"/>*/}
                {/*                                </svg>*/}
                {/*                            </div>*/}
                {/*                            <p className="courseCard__progress">{course.progress}%<br/>Пройдено</p>*/}
                {/*                        </div>*/}
                {/*                        <div className="courseCard__right-side">*/}
                {/*                            <h2 className="courseCard__title">{course.name}</h2>*/}
                {/*                            <p className="courseCard__description">{course.description}</p>*/}
                {/*                            <div className="courseCard__statistics">*/}
                {/*                                <div>{Object. keys(course.lessons). length + " " + createLabel(Object. keys(course.lessons). length, ['урок', 'урока', 'уроков'])}</div>*/}
                {/*                                <div>8 тестов</div>*/}
                {/*                                <div>0 сообщений</div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </Link>*/}
                {/*                )*/}
                {/*            }) :*/}
                {/*            <div></div>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*}*/}
                {tabs[2] &&
                    <div className="courseCard__tab-container">
                        <div className="courseCard__sorted">
                            <div className="courseCard__select">
                                <div className="courseCard__select-header" onClick={(e) => selectHandler(e)}>
                                    <div className="courseCard__select-selected">Не выбрано</div>
                                    <div className="courseCard__select-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                                            <path d="M15 3.5L7.8 10.5L1 3.5" stroke="black" strokeWidth="2"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="courseCard__select-body">
                                    <div className="courseCard__select-option" onClick={(e) => optionHandler(e)} data-action="dateStart">Дата проведения: сначала новые</div>
                                    <div className="courseCard__select-option" onClick={(e) => optionHandler(e)} data-action="dateEnd">Дата проведения: сначала старые</div>
                                </div>
                            </div>
                            <div className="courseCard__select">
                                <div className="courseCard__select-header" onClick={(e) => selectHandler(e)}>
                                    <div className="courseCard__select-selected">Не выбрано</div>
                                    <div className="courseCard__select-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                                            <path d="M15 3.5L7.8 10.5L1 3.5" stroke="black" strokeWidth="2"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="courseCard__select-body">
                                    <div className="courseCard__select-option" onClick={(e) => optionHandler(e)} data-action="nameStart">Название: А-Я</div>
                                    <div className="courseCard__select-option" onClick={(e) => optionHandler(e)} data-action="nameEnd">Название: Я-А</div>
                                </div>
                            </div>
                        </div>
                        {Object.keys(changesCourses).length > 0 ?
                            Object.values(changesCourses.coursesLessons).map((course, index) => {
                                if((typeof search === 'undefined' || search.length === 0) || course.name.toLowerCase().includes(search))
                                    return (
                                        <Link to={`/lessons/${course.id}`} className="courseCard" key={index}>
                                            <div className="courseCard__left-side">
                                                <div className="courseCard__image">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M12.761 8.37551V8.26876C12.761 7.67551 12.7802 6.90026 13.0497 6.13026C13.502 4.8477 14.3415 3.73731 15.4522 2.95262C16.563 2.16793 17.89 1.74769 19.25 1.75001H22.75C24.1103 1.74733 25.4377 2.16739 26.5488 2.95211C27.6599 3.73683 28.4997 4.84742 28.952 6.13026C29.2198 6.90026 29.2407 7.67551 29.2407 8.26876V8.37376C31.3802 8.99969 33.2631 10.2939 34.614 12.0671C35.9649 13.8402 36.7128 15.9992 36.7483 18.228L36.75 18.543V22.6083C36.5668 22.6082 36.3856 22.6464 36.218 22.7203C31.4237 24.8354 26.241 25.9278 21.0009 25.9278C15.7607 25.9278 10.5781 24.8354 5.78375 22.7203C5.61549 22.6466 5.43367 22.6091 5.25 22.61V18.2298C5.28597 16.001 6.0343 13.8423 7.38549 12.0695C8.73667 10.2966 10.6198 9.00279 12.7592 8.37726L12.761 8.37551ZM15.5295 6.98601C15.8008 6.21635 16.3045 5.55001 16.9711 5.07912C17.6376 4.60823 18.4339 4.35607 19.25 4.35751H22.75C24.4702 4.35751 25.935 5.45301 26.4705 6.98601C26.551 7.21351 26.5895 7.47601 26.6052 7.80851C22.8953 7.17851 19.1047 7.17851 15.3947 7.80851C15.4105 7.47601 15.449 7.21351 15.5295 6.98601ZM16.1875 22.1743C16.1875 21.4533 16.7755 20.8705 17.5 20.8705H24.5C25.2245 20.8705 25.8125 21.4533 25.8125 22.1743C25.812 22.3461 25.7777 22.5161 25.7115 22.6747C25.6453 22.8332 25.5484 22.9771 25.4265 23.0982C25.3046 23.2193 25.16 23.3152 25.0011 23.3804C24.8421 23.4455 24.6718 23.4787 24.5 23.478H17.5C17.3282 23.4787 17.1579 23.4455 16.9989 23.3804C16.84 23.3152 16.6954 23.2193 16.5735 23.0982C16.4516 22.9771 16.3547 22.8332 16.2885 22.6747C16.2223 22.5161 16.188 22.3461 16.1875 22.1743Z" fill="white"/>
                                                        <path d="M36.75 25.3348C35.175 26.0033 33.5667 26.5668 31.9375 27.0253V29.127C31.937 29.2988 31.9027 29.4689 31.8365 29.6274C31.7703 29.786 31.6734 29.9299 31.5515 30.051C31.4296 30.1721 31.285 30.2679 31.1261 30.3331C30.9671 30.3983 30.7968 30.4315 30.625 30.4308C30.4532 30.4315 30.2829 30.3983 30.1239 30.3331C29.965 30.2679 29.8204 30.1721 29.6985 30.051C29.5766 29.9299 29.4797 29.786 29.4135 29.6274C29.3473 29.4689 29.313 29.2988 29.3125 29.127V27.671C21.2414 29.3701 12.8435 28.5548 5.25 25.3348V28.0403C5.25445 29.8326 5.87083 31.5696 6.99709 32.9638C8.12335 34.358 9.69196 35.3258 11.4433 35.707C17.738 37.0965 24.262 37.0965 30.5585 35.707C32.3092 35.3256 33.8772 34.3579 35.003 32.964C36.1289 31.5702 36.7452 29.8338 36.75 28.042V25.3348Z" fill="white"/>
                                                    </svg>
                                                </div>
                                                <p className="courseCard__progress">{course.progress}%<br/>Пройдено</p>
                                            </div>
                                            <div className="courseCard__right-side">
                                                <h2 className="courseCard__title">{course.name}</h2>
                                                <p className="courseCard__description">{course.description}</p>
                                                <div className="courseCard__statistics">
                                                    <div>{Object. keys(course.lessons). length + " " + createLabel(Object. keys(course.lessons). length, ['урок', 'урока', 'уроков'])}</div>
                                                    <div>8 тестов</div>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                            }) :
                            <div></div>
                        }
                    </div>
                }
            </div>
        </>
    )
};

export default MyTraining
