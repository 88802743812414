import React from "react";
import { isMobileOnly } from "react-device-detect";
import CountBlock from "../../countBlock/CountBlock";
import Dots from "../../dotsMain/Dots";
import ProjectScreenTitle from "../../projectScreenTitleMain/ProjectScreenTitle";

const BulletExtendedScreen = ({ data }) => {
  return (
    <section className="bullet-extended-screen">
      <div className="bullet-extended-screen__content">
        <ProjectScreenTitle color="#FF5252" content={data.title} />
        <div className="bullet-extended-screen__subtitle">
          {!isMobileOnly && <Dots color="#FF5252" />}
          {data.subtitle}
        </div>
        <div className="bullet-extended-screen__info">
          {data.info_items.map((item, index) => (
            <div
              key={`bullet-extended-screen-${index}`}
              className="bullet-extended-screen__info-item"
            >
              <CountBlock count={index + 1} color="#FF5252" />
              <p className="bullet-extended-screen__info-item-text">{item}</p>
            </div>
          ))}
        </div>
        <div className="bullet-extended-screen__description">
          {!isMobileOnly && <Dots color="#FF5252" />}
          {data.description}
        </div>
      </div>
    </section>
  );
};

export default BulletExtendedScreen;
