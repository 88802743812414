import React from "react";

// experts
import cherkesImg from "../../../images/screen-six/Cherkes.png";
import karfidovImg from "../../../images/screen-six/Karfidov.png";

// images
import titleScreenImg from "../../../images/project-screens/creative-thinking/bg/title-screen.png";

const creativeThinkingData = {
  titleScreen: {
    header: "Мысли нестандартно!",
    title: "Как мыслят гении?",
    content: "Что такое креативность? Как ее развивать? Где использовать?",
    bg: titleScreenImg,
  },
  bulletScreen: {
    title: "Чему ты научишься на этом марафоне?",
    info_items: [
      <span>Придумывать новые идеи</span>,
      <span>Искать необычные решения обычных задач</span>,
      <span>
        Создавать прототипы и проверять их работоспособность на практике
      </span>,
    ],
  },
  bulletScreenTwo: {
    title: "В креативном мышлении даже инструменты необычные:",
    info_items: [
      <span>Метод Уолта Диснея</span>,
      <span>Шесть шляп</span>,
      <span>ТРИЗ</span>,
      <span>Дизайн-мышление</span>,
      <span>Латеральное мышление</span>,
    ],
  },
  expertsScreen: {
    title: "Эксперты курса",
    experts: [
      {
        name: "Екатерина Черкес-Заде",
        job: (
          <span>
            ректор Universal
            <br />
            University
          </span>
        ),
        img: cherkesImg,
      },
      {
        name: "Алексей Карфидов",
        job: (
          <span>
            промышленный
            <br />
            российский дизайнер
          </span>
        ),
        img: karfidovImg,
      },
    ],
  },
};

export default creativeThinkingData;
