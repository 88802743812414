import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";

const Header = ({user}) => {
    const [burgerButton, setBurgerButton] = useState(false)
    const [mobileMenu, setMobileMenu] = useState(true)

    useEffect(() => {
        widthWindowListener()
    }, [])

    const widthWindowListener = () => {
        let width = document.body.clientWidth
        window.addEventListener('resize',(e) => {
            width = document.body.clientWidth
            width < 1024 ? setMobileMenu(false) : setMobileMenu(true)
        });
        width < 1024 ? setMobileMenu(false) : setMobileMenu(true)
    }

    const menuClickHeandler = () => {
        let width = document.body.clientWidth
        if(width < 1024){
            setMobileMenu(!mobileMenu)
            setBurgerButton(!burgerButton)
        }
    }

    return(
        <header className="header">
            <div className="cnt">
                <div className="header__left-side">
                    <div className="header__logo" style={{cursor:'pointer'}} onClick={()=>window.location.href='/'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="37" viewBox="0 0 23 37" fill="none">
                            <g clipPath="url(#clip0_71_2487)">
                                <path d="M8.19252 14.347H1.35003C0.936179 14.347 0.775936 13.8031 1.12166 13.5733L21.7955 -0.134402C22.1298 -0.356564 22.5513 -0.0194897 22.415 0.360995L9.41642 36.7216C9.25744 37.1659 8.60764 37.0497 8.60764 36.5786V14.7684C8.6089 14.536 8.42216 14.347 8.19252 14.347Z" fill="#DE3B34"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_71_2487">
                                    <rect width="23" height="37" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    {user !==undefined && mobileMenu &&
                        <ul className="header__menu">
                            <li className="header__menu-item" onClick={menuClickHeandler}>
                                <Link to={'/training'} className="header__menu-link">Курсы</Link>
                            </li>
                            <li className="header__menu-item" onClick={menuClickHeandler}>
                                <Link to={'/profile'} className="header__menu-link">Профиль</Link>
                            </li>
                            <li className="header__menu-item" onClick={menuClickHeandler}>
                                <Link to={'/test'} className="header__menu-link">Тесты</Link>
                            </li>
                            {/*<li className="header__menu-item" onClick={menuClickHeandler}>*/}
                            {/*    <Link to={'/'} className="header__menu-link">Проекты</Link>*/}
                            {/*</li>*/}
                            {/*<li className="header__menu-item" onClick={menuClickHeandler}>*/}
                            {/*    <Link to={'/events'} className="header__menu-link">Мероприятия</Link>*/}
                            {/*</li>*/}
                            {/*<li className="header__menu-item" onClick={menuClickHeandler}>*/}
                            {/*    <Link to={'/my-achievements'} className="header__menu-link">Мои достижения</Link>*/}
                            {/*</li>*/}
                            {/*<li className="header__menu-item" onClick={menuClickHeandler}>*/}
                            {/*    <Link to={'/my-team'} className="header__menu-link">Моя команда</Link>*/}
                            {/*</li>*/}
                        </ul>
                    }
                </div>
                <div className="header__right-side">
                    {
                        user !== undefined ?
                            <Link to={'/profile'} className="header__profile">
                                <div className="header__profile-image">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12ZM15 9C15 9.79565 14.6839 10.5587 14.1213 11.1213C13.5587 11.6839 12.7956 12 12 12C11.2044 12 10.4413 11.6839 9.87868 11.1213C9.31607 10.5587 9 9.79565 9 9C9 8.20435 9.31607 7.44129 9.87868 6.87868C10.4413 6.31607 11.2044 6 12 6C12.7956 6 13.5587 6.31607 14.1213 6.87868C14.6839 7.44129 15 8.20435 15 9ZM12 20.5C13.7163 20.5028 15.3928 19.9834 16.807 19.011C17.411 18.596 17.669 17.806 17.317 17.163C16.59 15.83 15.09 15 12 15C8.91 15 7.41 15.83 6.682 17.163C6.331 17.806 6.589 18.596 7.193 19.011C8.6072 19.9834 10.2837 20.5028 12 20.5Z" fill="black"/>
                                    </svg>
                                </div>
                                <div className="header__profile-text">
                                    {user.name}
                                </div>
                            </Link> :
                            <Link to={'/'} className="header__profile">
                                <div className="header__profile-image">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12ZM15 9C15 9.79565 14.6839 10.5587 14.1213 11.1213C13.5587 11.6839 12.7956 12 12 12C11.2044 12 10.4413 11.6839 9.87868 11.1213C9.31607 10.5587 9 9.79565 9 9C9 8.20435 9.31607 7.44129 9.87868 6.87868C10.4413 6.31607 11.2044 6 12 6C12.7956 6 13.5587 6.31607 14.1213 6.87868C14.6839 7.44129 15 8.20435 15 9ZM12 20.5C13.7163 20.5028 15.3928 19.9834 16.807 19.011C17.411 18.596 17.669 17.806 17.317 17.163C16.59 15.83 15.09 15 12 15C8.91 15 7.41 15.83 6.682 17.163C6.331 17.806 6.589 18.596 7.193 19.011C8.6072 19.9834 10.2837 20.5028 12 20.5Z" fill="black"/>
                                    </svg>
                                </div>
                                <div className="header__profile-text">
                                    Войти
                                </div>
                            </Link>
                    }
                    <div className={"header__burger-button " + (burgerButton === true ? "active" : '')} onClick={() => {setBurgerButton(!burgerButton); setMobileMenu(!mobileMenu)}}>
                        <div className="header__line"></div>
                        <div className="header__line"></div>
                        <div className="header__line"></div>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header