export default class NewApi {

    baseUrl = process.env.NODE_ENV === 'development' ? 'https://dplms.rs-test.ru/ext-course/frontend/ajax/' : '/ext-course/frontend/ajax/';

    getResource = async (url, body) => {
        const res = await fetch(`${this.baseUrl}${url}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/javascript, */*; q=0.01',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            // body: JSON.stringify(body)
            body: body !== undefined ? Object.keys(body).map(key => key + '=' + body[key]).join('&') : ''

        })
        const json = await res.json()
        if(json.status === 500 || json.type === 'Error'){
            window.location.href = '/error?r=500';
        } else
            return json; // Возвращение промиса
    }

    // Получение всех курсов для страницы /training
    getCoursesLesson = async () => {
        return await this.getResource('get-courses-lessons')
    }

    // Получение шага урока
    getNextLessonStep = async (id) => {
        return await this.getResource(`get-next-lesson-step?lessonId=${id}`)
    }

    // Записываем прохождения шага
    setLessonStep = async (lesson_step_id, answer_type, answer, ext, duration) => {
        return await this.getResource(`set-lesson-step`, {
            lesson_step_id, answer_type, answer, ext, video_duration: duration
        })
    }

    // Список курсов с тестами
    getCoursesTests = async () => {
        return await this.getResource(`get-courses-tests`)
    }

    // Список курсов с тестами
    getNextLessonTestQuestion = async (id) => {
        return await this.getResource(`get-next-lesson-test-question?lessonId=${id}`)
    }

    // Записываем ответы шага теста
    setLessonTestAnswer = async (qId, answers) => {
        let body = {}

        body.question_id = qId
        answers.map(function (item, index) {
            body['answers[' + index + ']'] = item
        })
        return await this.getResource(`set-lesson-test-answer`, body)
    }

    // Записываем загруженный файл
    setUserUploadDraft = async (draft_link, lesson_step_id, draft_filename, draft_desc, ext, draft_file) => {
        return await this.getResource(`set-user-upload-draft`, {
            draft_link, lesson_step_id, draft_filename, draft_desc, ext, draft_file
        })
    }

    // Получаем список загруженных файлов в черновик
    getUserUploadDrafts = async (lesson_step_id) => {
        return await this.getResource(`get-user-upload-drafts`, {
            lesson_step_id
        })
    }

    // Удаление файла из черновиков
    deleteUserUploadDrafts = async (guid) => {
        return await this.getResource(`delete-user-upload-draft`, {
            guid
        })
    }

    // Изменение описание файлов в загрузчике
    updateUserUploadDraftDesc = async (guid, draft_desc) => {
        return await this.getResource(`update-user-upload-draft-desc`, {
            guid, draft_desc
        })
    }

    // Получение статистики пользователя по пройденным материалам в профиле
    getMyStatistic = async () => {
        return await this.getResource('get-my-statistic')
    }

    getCertificate = async () => {
        return await this.getResource('get-my-achievements')
    }

    testAjax = async () => {
        return await this.getResource('get-certificate?token=VMFSdm4YMm-KUR8mmICIVnVs36LHCu3HGCsq3p_vbg3L44ksvk_tAHVusJKeOusB')
    }
}

