import React from 'react'
import './style.sass'
import Answer from './dnd/Answer';
import Questions from './dnd/Questions';
import img1 from '../../../assets/image/tests/subtract.png'

export default class AnswersDnd extends React.Component{
    state = {
        answers: [],
        questions: []
    }

    setAnswer = (answer_el, question_el) => {
        const {answers} = this.state
        if (answers.indexOf(question_el) === -1) {
            let answer = [];
            answers[answer_el] = question_el
            answers.push(answer)
        }
        this.setState({answers})
        let questions = []
        // eslint-disable-next-line
        answers.map(answer => {

            if (answer.length) {
                questions.push(answer)
            }

        })
        console.log('questions', questions)
        this.setState({questions})
            this.props.sendAnswer(questions)
    }

    render() {
        const {questions} = this.state;
        const {compareAnswers} = this.props;
        return (
            <div className={`answers ${this.props.answerClass}`}>
                <div className="test_dnd">
                    <div className="answers">
                        {Object.values(this.props.answers).map((answer, item)=>{
                            let answerCorrect = '';
                            if(compareAnswers !== undefined && Object.values(compareAnswers).length){

                                if(questions[item] === answer.id) {
                                    answerCorrect = 'correct'
                                }else {
                                    answerCorrect = 'inCorrect'
                                }
                                // eslint-disable-next-line
                                // compareAnswers.map((compareAnswer, index)=>{
                                //     if(compareAnswer.id === answer.id){
                                //         console.log(compareAnswer.correct, index)
                                //         answerCorrect = compareAnswer.correct === index + 1 ? ' correct' : ' inCorrect'
                                //     }else{
                                //         answerCorrect = 'inCorrect'
                                //     }
                                // })
                            }
                            console.log('answerCorrect',answerCorrect)
                            return (
                                <Answer key={item} targetKey="1" setAnswer={this.setAnswer} answer_el={answer.id} text={answer.id} count={item +1} answerCorrect={answerCorrect}/>
                            )
                        })}
                        <img src={img1} alt="" className="answers_img"/>
                        <p className="arrow_track arrow_track--1">
                            <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 10.3923L-9.08524e-07 20.7846L0 -7.86805e-07L18 10.3923Z" fill="#99BFDE"/>
                            </svg>
                        </p>
                        <p className="arrow_track arrow_track--2">
                            <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 10.3923L-9.08524e-07 20.7846L0 -7.86805e-07L18 10.3923Z" fill="#99BFDE"/>
                            </svg>
                        </p>
                        <p className="arrow_track arrow_track--3">
                            <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 10.3923L-9.08524e-07 20.7846L0 -7.86805e-07L18 10.3923Z" fill="#99BFDE"/>
                            </svg>
                        </p>
                        <p className="arrow_track arrow_track--4">
                            <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 10.3923L-9.08524e-07 20.7846L0 -7.86805e-07L18 10.3923Z" fill="#99BFDE"/>
                            </svg>
                        </p>
                    </div>
                    <div className="questions">
                        {Object.values(this.props.answers).map((answer, item)=>{
                            return (
                                <div key={item} className={'questions_el ' + (questions.indexOf(answer.id) >= 0 && 'hidden')}>
                                    <Questions targetKey="1" label={answer.answer} question_el={answer.id} text={answer.answer}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}
