import React, {useEffect, useState} from "react";
import Footer from "../../../pageComponents/footerMain/Footer";
import Header from "../../../pageComponents/headerMain/Header";
import {
  BulletScreen,
  ExpertsScreen,
  TitleScreen,
} from "../../../pageComponents/projectScreens/projectScreens";
import goalSettingData from "./goalSettingData";
import Api from "../../../Service/Api";

const GoalSetting = () => {

    const [user, setUser] = useState()
    const [showLogin, setShowLogin] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  });

    useEffect(() => {
        new Api().getUserData()
            .then(user => setUser(user))
            .catch(e => console.log('error', e))
    }, []);

    const showLoginClick = (showLogin) => {
        setShowLogin(showLogin)
    }

    const getUserData = () => {
        new Api().getUserData()
            .then(user => setUser(user))
            .catch(e => console.log('error', e))
    }

  return (
    <main className="project-page">
        <Header
            user={user}
            showLogin={showLogin}
            showLoginClick={showLoginClick}
            getUserData={getUserData}
        />
        <TitleScreen data={goalSettingData.titleScreen} user={user} showLoginClick={showLoginClick} />
      <BulletScreen
        data={goalSettingData.bulletScreen}
        info_margin="80px 85px 0"
      />
      <ExpertsScreen data={goalSettingData.expertsScreen} />
      <Footer user={user}/>
    </main>
  );
};

export default GoalSetting;
