import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import SVG403 from "./403";
import SVG404 from "./404";
import SVG429 from "./429";
import SVG5XX from "./5xx";
import Header from "../../pageComponents/headerMain/Header";
import Api from "../../Service/Api";

const getMessage = (code) => {
    switch (code){
        case '403': return {
            message:  'Доступ запрещен',
            description: 'У вас нет разрешения на доступ к запрошенной странице',
            image: <SVG403/>
        }
        case '404': return {
            message:  '404',
            description: 'Упс! Страница, которую вы ищите была удалена, либо вовсе не существовала',
            image: <SVG404/>
        }
        case '429': return {
            message:  'Превышен лимит запросов',
            description: 'На данный момент эта ссылка была автоматически отключена',
            image: <SVG429/>
        }
        default: return {
            message:  'Внутренняя ошибка сервера',
            description: 'Что-то пошло не так... Мы уже работаем над проблемой',
            image: <SVG5XX/>
        }
    }
}

const Error = () => {
    const {code} = useParams()
    const api = new Api()

    const [user, setUser] = useState()

    const getUser = () =>
        api.getUserData().then(setUser.bind(this))
            .catch(e=>console.error(e))

    useEffect(()=>{
        getUser()
    },[])


    const message = getMessage(code)
    return (
        <div className={'main_page'}>
            <Header user={user} getUserData={getUser.bind(this)}/>
            <div className={'error-page-container'}>
                <div className={`error-page-description ${code === '404'?' error-page-description-404':''}`}>
                    <h1>{message.message}</h1>
                    <p>{message.description}</p>
                    <div onClick={()=>window.location.href='/'} className={'error-page-button'}>
                        <p>Открыть главную страницу</p>
                    </div>
                </div>
                <div className={`error-page-svg ${code === '404'?' error-page-description-404':''}`}>
                    {message.image}
                </div>
            </div>
        </div>

    )
}

export default Error