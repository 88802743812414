import React, { useState } from "react";
import { isMobileOnly } from "react-device-detect";
import Button from "../../buttonMain/Button";
import Modal from "../../modalMain/Modal";

const TitleScreen = ({ data, user, showLoginClick }) => {
  const [modal, setModal] = useState(false);

  return (
    <section className="title-screen">
      <div className="title-screen__content">
        <p className="title-screen__header">{data.header}</p>
        <h2 className="title-screen__title">{data.title}</h2>
        <div className="title-screen__line"></div>
        {isMobileOnly && (
          <img src={data.bg} className="title-screen__img" alt="" />
        )}
        <p className="title-screen__info">{data.content}</p>
        {!user &&
        <Button
            isLink
            type="red"
            content="хочу участвовать!"
            path={'https://bolshayaperemena.online/auth/signup'}
            // onClick={() => showLoginClick('registration')}
        />
        }
      </div>
      {!isMobileOnly && (
        <img src={data.bg} className="title-screen__img" alt="" />
      )}
      {modal && <Modal onClose={() => setModal(false)} />}
    </section>
  );
};

export default TitleScreen;
