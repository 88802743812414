import React from "react";
import ListEl from './_list-el'
import Moment from "react-moment";

export default ({user, day, timeZone, index, forumDaysEvent, forumDaysEventSpeakers, serverTime, userEvents, getForumData,forum_id, showPopapChange, showLoginClick}) => {
    let time = -3 + timeZone;
    return (
        <div
            className={'programs__list'}
        >
            {time ?
                <div className="programs__list-tz">Время указано по часовому поясу МСК {time > 0 ? '+' : '-'} {Math.abs(time)} {time === 1 || time === -1 ? 'час' : 'часа' }</div>
                :
                <div className="programs__list-tz">Время указано по часовому поясу МСК </div>
            }
            {/*Видео залил на сервер Большой Перемены*/}
            {/*{index == 2 &&*/}
            {/*    <div className="video">*/}
            {/*        <div className="cnt">*/}
            {/*            <video width="" height="" controls="controls" poster="https://bolshayaperemena.team/video/site/R2_v02_0907.png">*/}
            {/*                <source src="https://bolshayaperemena.team/video/site/R2_v02_0907.ogv" type='video/ogg; codecs="theora, vorbis"'/>*/}
            {/*                <source src="https://bolshayaperemena.team/video/site/R2_v02_0907.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>*/}
            {/*                <source src="https://bolshayaperemena.team/video/site/R2_v02_0907.webm" type='video/webm; codecs="vp8, vorbis"'/>*/}
            {/*                Тег video не поддерживается вашим браузером.*/}
            {/*                <a href="https://bolshayaperemena.team/video/site/R2_v02_0907.mp4">Скачайте видео</a>*/}
            {/*            </video>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}
            {/*{index == 3 &&*/}
            {/*    <div className="video">*/}
            {/*        <div className="cnt">*/}
            {/*            <video width="" height="" controls="controls" poster="https://bolshayaperemena.team/video/site/R3_v03_0907.png">*/}
            {/*                <source src="https://bolshayaperemena.team/video/site/R3_v03_0907.ogv" type='video/ogg; codecs="theora, vorbis"'/>*/}
            {/*                <source src="https://bolshayaperemena.team/video/site/R3_v03_0907.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>*/}
            {/*                <source src="https://bolshayaperemena.team/video/site/R3_v03_0907.webm" type='video/webm; codecs="vp8, vorbis"'/>*/}
            {/*                Тег video не поддерживается вашим браузером.*/}
            {/*                <a href="https://bolshayaperemena.team/video/site/R3_v03_0907.mp4">Скачайте видео</a>*/}
            {/*            </video>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}
            <div className="programs__list-head">
                <div className="programs__list-date">
                    {index} день (<Moment format="dddd">{day.date}</Moment>)
                </div>
                {/*<div className="programs__list-track">*/}
                {/*    <div className="track__el track__el-red">Трек «Мотивационный»</div>*/}
                {/*    <div className="track__el track__el-blue">Трек «Проектный»</div>*/}
                {/*    <div className="track__el track__el-green">Трек «Личностный»</div>*/}
                {/*</div>*/}
            </div>

            {forumDaysEvent.map(event => {
                return (
                    <ListEl
                        key={event.id + +new Date()}
                        forum_id={forum_id}
                        user={user}
                        day={day}
                        serverTime={serverTime}
                        event={event}
                        speakers={forumDaysEventSpeakers[event.id]}
                        userEvents={userEvents}
                        getForumData={getForumData}
                        showPopapChange={showPopapChange}
                        showLoginClick={showLoginClick}
                    />)
            })}

        </div>
    )
}
