import React, {useState, useEffect} from 'react'
import Api from "../../Service/Api";
import Header from "../../pageComponents/headerMain/Header";

const Events = () => {
    const api = new Api()
    const [user, setUser] = useState(undefined)

    const getUserData = () => {
        api.getUserData()
            .then(user => setUser(user))
            .catch(e => console.log('error', e))
    }

    useEffect(() => {
        getUserData()
    }, [])

    return(
        <div className="events page">
            <Header user={user}/>
            <div className="cnt">
                <h2 className="events__header">Мероприятия</h2>
                <p className="events__text">У вас нет запланированных мероприятий</p>
            </div>
        </div>
    )
}

export default Events