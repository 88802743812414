import React from "react";
import Api from "../../Service/Api";
import Header from "../../pageComponents/headerMain/Header";
import MyTesting from "../../Components/myTesting";
import Footer from "../../pageComponents/footerMain/Footer";

export default class Test extends React.Component {
    Api = new Api()

    state = {
        user: undefined,
    }

    componentDidMount() {
        this.getUserData();

    }

    getUserData = () => {
        this.Api.getUserData()
            .then(user => this.setState({user}))
            .catch(e => console.log('error', e))
    }


    render() {
        const {user, forums, showAllPartners, showAllProjects} = this.state
        return (
            <div className="training__page page">
                <Header className={'header__orange'} user={user} getUserData={this.getUserData}/>
                <div className="cnt">
                    <MyTesting history={this.props.history}/>
                </div>
                <Footer user={user}/>

            </div>
        )
    }
}
