import React from "react";
import { isMobileOnly } from "react-device-detect";
import ProjectScreenHeader from "../../projectScreenHeaderMain/ProjectScreenHeader";

const TextBigScreen = ({ data }) => {
  return (
    <section className="text-big-screen">
      <div className="text-big-screen__content">
        <ProjectScreenHeader
          title={data.title}
          img={data.header_img}
          bg={data.bg}
        />
        {isMobileOnly && (
          <img src={data.bg} alt="" className="text-big-screen__mobile-bg" />
        )}
        <p className="text-big-screen__info">{data.info}</p>
      </div>
    </section>
  );
};

export default TextBigScreen;
