import React from 'react'
import './style.sass'
import {Link} from "react-router-dom";
import {instanceOf} from "prop-types";
import {Cookies} from "react-cookie";
import {withCookies} from "react-cookie";

class popapAfterTest extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    componentDidMount() {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
    }

    componentWillUnmount() {
        document.body.style.overflow = "";
    }

    render() {
        return (

            <div className="popapAfterTest">
                <div className="popapAfterTest_wrp">
                    <div
                        className="popapAfterTest_close"
                        onClick={() => {
                            this.props.closeEndPopap !== undefined ? this.props.closeEndPopap() : this.props.history.push('/MyTraining', {showPopapEnd: false})
                        }}
                    />
                    <div className="popapAfterTest_head">
                        Поздравляем! <br/>
                        Вы закончили урок
                    </div>
                    <div className="popapAfterTest_text">
                        Вы можете ознакомится с другими уроками, <br/>
                        просто нажмите кнопку.
                    </div>
                    <div className="popapAfterTest_btn">
                        <Link
                            to={'/training'}
                            className="button button__orange"
                        >продолжить
                        </Link>
                    </div>

                </div>
            </div>

        )
    }
}

export default withCookies(popapAfterTest)
