import React from "react";
import {Link} from "react-router-dom";

export default () => {
    return (
        <div className="training-menu">
            <div className="training-menu__list">
                <Link className="training-menu__link" to={'/training'}>К урокам</Link>
                <Link className="training-menu__link" to={'/test'}>К тестам</Link>
            </div>
        </div>
    )
}