import React from "react";
import List from './_list'
import Popap from '../../Components/popap'


export default class Programs extends React.Component {
    state = {
        showPopap: false
    }

    showPopapChange = () => {
        this.setState({showPopap: !this.state.showPopap})
    }

    render() {
        const {user, forumDays, forumDaysEvent, forumDaysEventSpeakers, serverTime, userEvents, getForumData, timeZone, forum_id, showLoginClick} = this.props
        const {showPopap} = this.state
        return (
            <div className="programs">
                <div className="cnt">
                    <div className="programs__head">
                        Программа
                    </div>

                    {/*<div className="programs__roles">*/}
                    {/*    <div className={"role__el " + (user && user.type === 1 ? 'active' : '')}>Волонтёр/ Координатор</div>*/}
                    {/*    <div className={"role__el " + (user && user.type === 2 ? 'active' : '')}>Организатор проекта</div>*/}
                    {/*    <div className={"role__el " + (user && user.type === 3 ? 'active' : '')}>Руководитель организации/движения</div>*/}
                    {/*    <div className={"role__el " + (user && user.type === 4 ? 'active' : '')}>Полуфиналист конкурса «Доброволец России»</div>*/}
                    {/*</div>*/}
                    {forumDays.map((day, index) => {
                        return (
                            <List
                                forum_id={forum_id}
                                key={day.id + +new Date()}
                                user={user}
                                timeZone={timeZone}
                                day={day}
                                index={index + 1}
                                serverTime={serverTime}
                                forumDaysEvent={forumDaysEvent[day.id]}
                                forumDaysEventSpeakers={forumDaysEventSpeakers[day.id]}
                                userEvents={userEvents}
                                getForumData={getForumData}
                                showPopapChange={this.showPopapChange}
                                showLoginClick={showLoginClick}
                            />
                        )
                    })}

                    {showPopap &&
                        <Popap
                            showPopapChange={this.showPopapChange}
                            text={'Кабинет мероприятия будет доступен за 30 минут до начала'}
                        />
                    }
                </div>
            </div>
        )
    }
}
