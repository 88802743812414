import React from "react";
// import Button from '../../Components/button'
import Moment from "react-moment";
import Registration from '../../pageComponents/registration'
import List from '../../pageComponents/list'

export default ({bg, bgPeople, banner__date_start, banner__date_end, banner__head, banner__text, banner__btn, showLoginClick, ListData, listHead}) => {
    let startMonth = ''
    let endMonth = ''
    if(banner__date_start) {
        startMonth = banner__date_start.split('-')[1];
        endMonth = banner__date_end.split('-')[1];
    }
    return(
        <div className="banner">
            <div className="cnt cnt--banner">
                <div className="banner__flex">
                    <div className="banner__el">
                        {banner__date_start &&
                        <div className="banner__date">
                            {startMonth === endMonth ?
                                <Moment format="DD">{banner__date_start}</Moment>
                                :
                                <Moment format="DD MMMM">{banner__date_start}</Moment>
                            }
                            -
                            <Moment format="DD MMMM">{banner__date_end}</Moment>
                        </div>
                        }
                        {banner__head &&
                        <div className="banner__head" dangerouslySetInnerHTML={{__html: banner__head}}/>
                        }
                        {banner__text &&
                        <div className="banner__text" dangerouslySetInnerHTML={{__html: banner__text}}/>
                        }
                        {ListData &&
                        <List
                            ListData={ListData}
                            listHead={listHead}
                        />
                        }
                        {banner__btn &&
                        <Registration showLoginClick={showLoginClick}/>
                        }
                    </div>
                    <div className="banner__el">
                        {bgPeople &&
                        <img src={bgPeople} alt="" className={'banner__bgPeople'}/>
                        }
                    </div>
                </div>

            </div>
            {bg &&
            <img src={bg} alt="" className={'banner__bg'}/>
            }
        </div>
    )
}
